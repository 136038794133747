/*
Author: XpeedStudio
Author URI: http://themeforest.net/user/XpeedStudio/portfolio
*/
/* ==========================================================================
   Author's custom styles
========================================================================== */
/*****************

------------------------------- Table Of Content  ------------------------------------

1. Nav
2. Modal
3. Modal Flag
4. Modal Search
5. Offset Cart
6. Offset info bar
7. Offset side bar
8. preloader
9. social list

==================================== agency content ============================

10. agency banner
11. agency feature box
12. intro video section
13. creative comunication
14. parallax area
15. portfolio slider
16. testimonial review
17. blog thumb post
18. client slider
19. footer

==================================== inner page start =========================

20. inner page banner
21. counter up timer
22. team content
23. centerd timeline
24. office environment
25. service block
26. contact us block
27. agency cases
28. agency case details banner
29. agency case details about
30. agency project brief
31. agency working progress
32. agency call to action
33. agency pricing
34. agency contact
35. blog list
36. blog sidebar
37. blog single
38. comment area
39. comment form
40. parallax service
41. shop
42. product slider
43. single shop
44. product details
45. info block
46. about summary
47. success summary
48. call to action
49. testimonials
50. get subscribe
51. deserve it
52. we offer
53. more feature
54. bussiness agenda
55. best quote
56. about
57. horizontal timeline
58. get benifits
59. awards
60. growth card
61. creative protfolio
62. video testimonial
63. office info
64. feature step
65. customer satisfiction
66. vertical slider
67. icon menu

=================================== elemnt content ==============================

1. btn element
2. section title element
3. list element
4. video pop up element
5. insta feed element
6. newsletter element
7. pagination
8. select
9. search
10. product filter content
11. ribbon
12. offer promotion banner
13. custom number
14. floating buttons
15. woocommerce star rating
16. help tips
17. woo reviews
18. tabs
19. about slider
20. pie chart
21. scroll to bottom
22. content over image
23. vertical tabs
24. material style input
25. humburger
26. video testimonial
27. shuffle title
28. offcanvas menu
29. inheritance element



=====================================================**************************/
/*=================================================
          body reset file
===============================================*/
:root {
  font-size: 16px;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.625;
  color: #999999;
}

iframe {
  border: none;
}

a,
b,
div,
ul,
li {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -moz-outline-: none;
}

a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  -moz-outline: none;
  outline: none;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  outline: 0;
}

a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0;
}

img {
  border: none;
  max-width: 100%;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: auto;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clear-both:before,
.clear-both:after {
  display: table;
  content: "";
  clear: both;
}

button:focus {
  outline: none;
}

/*=================================================
          1. Nav
===============================================*/
/* top bar */
.nav-sticky {
  -webkit-transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
  -o-transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.nav-sticky.sticky-header {
  position: fixed;
  -webkit-transform: translateY(-150%);
  -ms-transform: translateY(-150%);
  transform: translateY(-150%);
  background-color: #f1f1f1;
  width: 100%;
  z-index: 100;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}
.nav-sticky.sticky-header .xs-logo {
  padding: 23px 0;
}
.nav-sticky.sticky-header .xs-menu-tools {
  padding: 28px 0;
}
.nav-sticky.sticky-header .xs-menu-tools > li > a {
  color: #000000;
}
.nav-sticky.sticky-header .xs-menus .nav-menu > li > a {
  padding: 33px 10px;
  color: #000000;
}
.nav-sticky.sticky-header .xs-menus .nav-menu > li > a .submenu-indicator-chevron {
  border-color: transparent #000000 #000000 transparent;
}
.nav-sticky.sticky-header.sticky {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}
.nav-sticky.sticky-header.nav-light {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.sticky-header .xs-menus:not(.xs-hidden-menus) .megamenu-panel {
  overflow-y: scroll;
  max-height: 500px;
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.header-transparent .xs-top-bar {
  background-color: rgba(0, 0, 0, 0.25);
}
.header-transparent .xs-menus .nav-menu > li > a {
  color: #FFFFFF;
}
.header-transparent .xs-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.header-transparent .xs-menu-tools > li > a {
  color: #FFFFFF;
}
.header-transparent .xs-menus .nav-menu > li > a::before {
  background-color: #fefefe;
}
.header-transparent .xs-menus .single-page-menu > li > a {
  color: #21069b;
}
.header-transparent .xs-menus .single-page-menu > li > a::before {
  background-color: #21069b;
}

.xs-menus .nav-menu > li {
  margin-right: 20px;
}
.xs-menus .nav-menu > li:last-child {
  margin-right: 0px;
}
.xs-menus .nav-menu > li > a {
  color: #333333;
  font-size: 0.875rem;
  padding: 53px 10px;
  font-weight: 700;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.xs-menus .nav-menu > li > a .submenu-indicator-chevron {
  border-color: transparent #FFFFFF #FFFFFF transparent;
}

.nav-menu li.active > a {
  color: #1bd1ea !important;
}
.nav-menu li.active > a .submenu-indicator-chevron {
  border-color: transparent #1bd1ea #1bd1ea transparent !important;
}
.nav-menu li > .nav-dropdown a.active {
  color: #1bd1ea !important;
}

.xs-header {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.xs-header .xs-logo {
  display: block;
  padding: 40px 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.xs-header.header-style2 .xs-menus .nav-menu > li:not(:last-child) {
  margin-right: 25px;
}
.xs-header.header-style2 .xs-menus .nav-menu > li > a {
  color: #606060;
}
.xs-header.header-style2 .xs-menus .nav-menu > li > a .submenu-indicator-chevron {
  border-color: transparent #606060 #606060 transparent;
}
.xs-header.header-style2 .xs-menu-tools > li > a:not(.languageSwitcher-button) {
  color: #b224ef;
  background: -webkit-linear-gradient(331deg, #b224ef 11%, #5055fa 84%);
  background: -o-linear-gradient(331deg, #b224ef 11%, #5055fa 84%);
  background: linear-gradient(119deg, #b224ef 11%, #5055fa 84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.xs-header.header-style2 .xs-menu-tools > li .lang-title {
  color: #606060;
}
.xs-header.header-style3 .xs-logo {
  padding: 27px 0;
}
.xs-header.header-style3 .xs-menus .nav-menu > li > a {
  padding: 42px 10px;
}
.xs-header.header-style4 .xs-menus .nav-menu > li > a {
  padding: 44px 0;
  color: #cdcfe6;
}
.xs-header.header-style4 .xs-logo {
  padding: 32px 0;
}
.xs-header.header-style4 .xs-menu-tools {
  padding: 36px 0;
}
.xs-header.header-style5 {
  background-color: #e8ebf4;
  border-bottom: 0px;
}
.xs-header.header-style5 .xs-menu-tools {
  padding: 30px 0;
}
.xs-header.header-style5 .xs-menu-tools > li > a {
  font-size: 2.25rem;
  color: #21069b;
  line-height: 1;
}
.xs-header .single-page-menu > li {
  position: relative;
}
.xs-header .single-page-menu > li:not(:last-child) {
  padding-right: 40px;
  margin-right: 40px;
}
.xs-header .single-page-menu > li:not(:last-child)::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #babcc3;
  border-radius: 50%;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.xs-header .single-page-menu > li > a {
  padding: 39px 0;
  color: #21069b;
  font-size: 1.125rem;
  font-weight: 700;
}
.xs-header .single-page-menu > li > a::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0%;
  background-color: #21069b;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.xs-header .single-page-menu > li > a:hover::before {
  width: 100%;
}
.xs-header.header-style6 {
  border-bottom: 0px solid #000;
}
.xs-header.header-style6 .xs-logo {
  padding: 25px 0;
}
.xs-header.header-style6 .single-page-menu > li:not(:last-child) {
  padding-right: 0;
  margin-right: 0;
}
.xs-header.header-style6 .single-page-menu > li:not(:last-child)::before {
  display: none;
}
.xs-header.header-style6 .single-page-menu > li > a {
  padding: 15px;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
}
.xs-header.header-style6 .nav-menu > li > a {
  color: #000000;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.xs-header.header-style7, .xs-header.header-style9 {
  border-bottom: 1px solid #f5f5f5;
}
.xs-header.header-style7 .xs-logo, .xs-header.header-style9 .xs-logo {
  padding: 30px 0;
}
.xs-header.header-style7 .burger-menu, .xs-header.header-style9 .burger-menu {
  padding: 44px 0;
}
.xs-header.header-style7 .nav-menu > li > a, .xs-header.header-style9 .nav-menu > li > a {
  color: #000000;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.xs-header.header-style7 .single-page-menu > li::before, .xs-header.header-style9 .single-page-menu > li::before {
  display: none;
}
.xs-header.header-style7 .single-page-menu > li > a, .xs-header.header-style9 .single-page-menu > li > a {
  padding: 15px;
}
.xs-header.header-style7 .xs-menus:not(.xs-hidden-menus) .nav-menu > li:not(:last-child), .xs-header.header-style9 .xs-menus:not(.xs-hidden-menus) .nav-menu > li:not(:last-child) {
  padding-right: 25px;
  margin-right: 25px;
}
.xs-header.header-style7 .xs-menus:not(.xs-hidden-menus) .nav-menu > li:not(:last-child)::before, .xs-header.header-style9 .xs-menus:not(.xs-hidden-menus) .nav-menu > li:not(:last-child)::before {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #e5e5e5;
}
.xs-header.header-style7 .xs-menus:not(.xs-hidden-menus) .nav-menu > li > a, .xs-header.header-style9 .xs-menus:not(.xs-hidden-menus) .nav-menu > li > a {
  padding: 47px 0;
}
.xs-header.header-style7 .xs-menus:not(.xs-hidden-menus) .nav-menu > li > a .submenu-indicator-chevron, .xs-header.header-style9 .xs-menus:not(.xs-hidden-menus) .nav-menu > li > a .submenu-indicator-chevron {
  border-color: transparent #000000 #000000 transparent;
}
.xs-header.header-style8 {
  border-bottom: 0px solid #000;
}
.xs-header.header-style8 .xs-logo {
  padding: 25px 0;
}
.xs-header.header-style8 .single-page-menu > li:not(:last-child) {
  padding-right: 0;
  margin-right: 0;
}
.xs-header.header-style8 .single-page-menu > li:not(:last-child)::before {
  display: none;
}
.xs-header.header-style8 .single-page-menu > li > a {
  padding: 15px;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
}
.xs-header.header-style8 .nav-menu > li > a {
  color: #000000;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.xs-header.header-style8 .burger-menu {
  padding: 20px 0;
}
.xs-header.header-style9 {
  border-bottom: 0px solid #000;
}
.xs-header.header-style9 .xs-menus:not(.xs-hidden-menus) .nav-menu > li > a {
  color: #000000;
}
.xs-header.header-style10 {
  border-bottom: 0px solid #000;
}
.xs-header.header-style10 .xs-logo {
  padding: 25px 0;
}
.xs-header.header-style10 .nav-menu > li > a {
  color: #000000;
  font-size: 0.875rem;
  font-weight: 500;
}
.xs-header.header-style11 .xs-logo {
  padding: 32px 0;
}
.xs-header.header-style11 .nav-menu > li > a {
  padding: 42px 10px;
  color: #d5e1fb;
}
.xs-header.header-style11 .xs-menu-tools {
  padding: 37px 0;
}
.xs-header .burger-menu {
  padding: 39px 0;
}
.xs-header .xs-menus .nav-menu > li > .nav-submenu {
  right: auto !important;
}

.nav-sticky.sticky-header .xs-menus .nav-menu > li > a {
  color: #000000;
}
.nav-sticky.sticky-header .xs-menus .single-page-menu > li > a {
  padding: 29px 0;
  color: #21069b;
}
.nav-sticky.sticky-header .header-style5 .xs-menu-tools {
  padding: 20px 0;
}
.nav-sticky.sticky-header .header-style7 .burger-menu, .nav-sticky.sticky-header .xs-header.header-style9 .burger-menu,
.nav-sticky.sticky-header .header-style9 .burger-menu {
  padding: 29px 0;
}
.nav-sticky.sticky-header .header-style7 .xs-logo, .nav-sticky.sticky-header .xs-header.header-style9 .xs-logo,
.nav-sticky.sticky-header .header-style9 .xs-logo {
  padding: 16px 0;
}
.nav-sticky.sticky-header .header-style9 .xs-menus:not(.xs-hidden-menus) .nav-menu > li > a {
  color: #000000;
  padding: 32px 0;
}
.nav-sticky.sticky-header .humburger.style3 .humburger-icons > span {
  background-color: #000000;
}
.nav-sticky.sticky-header .humburger.style3 .humburger-icons > span::before {
  background-color: #000000;
}

.xs-menu-tools {
  text-align: right;
  padding: 46px 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.xs-menu-tools > li {
  display: inline-block;
  margin-right: 20px;
}
.xs-menu-tools > li:last-child {
  margin-right: 0px;
}
.xs-menu-tools > li > a {
  color: #333333;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  font-weight: 700;
}
.xs-menu-tools > li > a .xs-flag {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 9px;
}
.xs-menu-tools > li > a .lang-title {
  vertical-align: middle;
  font-size: 0.875rem;
}

.home-menu-panel a {
  display: block;
  text-align: center;
}
.home-menu-panel a img {
  margin-bottom: 20px;
}
.home-menu-panel a h4 {
  font-size: 1.0625rem;
  margin-bottom: 0px;
}

/* Sticky nav responsive */
@media (max-width: 991px) {
  .nav-sticky .xs_nav-portrait .nav-menus-wrapper {
    height: 100vh;
  }
  .nav-sticky .nav-overlay-panel {
    height: 100vh;
  }
}
.nav-sticky .xs-hidden-menus .nav-menus-wrapper {
  height: 100vh;
}
.nav-sticky .nav-overlay-panel {
  height: 100vh;
}

.xs-header > .container {
  position: relative;
}
.xs-header > .container > .row > [class^="col-"] {
  position: static;
}
.xs-header .xs-menus .nav-menu > li .nav-submenu {
  border-top: 0px solid #000;
}
.xs-header .xs-menus .nav-menu > li .megamenu-panel.nav-submenu {
  right: 0px !important;
}

.xs-hidden-menus .home-menu-panel > .row > [class*="col-"] {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.home-menu-panel {
  border-top: 0px solid #000;
}
.home-menu-panel .menu-content {
  margin-bottom: 20px;
}
.home-menu-panel .menu-content:hover .menu-image > img {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}
.home-menu-panel .menu-content .menu-image {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(23, 104, 221, 0.1);
  box-shadow: 0px 5px 15px 0px rgba(23, 104, 221, 0.1);
  margin-bottom: 15px;
}
.home-menu-panel .menu-content .menu-image > img {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.home-menu-panel .menu-content h4 {
  font-weight: 700;
  font-size: 1.0625rem;
}
.home-menu-panel .menu-content.active h4 {
  color: #1bd1ea;
}

.xs-header.header-style6 .xs-logo {
  padding: 15px 0;
}
.xs-header.header-style6 .burger-menu {
  padding: 29px 0;
}

.submenu-indicator {
  margin-top: 4px;
}

.nav-menus-wrapper > ul {
  display: inline-block;
}
.nav-menus-wrapper .nav-menu {
  padding-right: 55px;
}

.nav-header {
  max-width: 200px;
  padding: 0 15px;
}
.nav-header > .nav-brand {
  padding: 0px;
  display: inline-block;
  min-height: 123px;
  line-height: 113px;
}

.header-style3 .nav-header > .nav-brand,
.header-style11 .nav-header > .nav-brand {
  min-height: 101px;
  line-height: 101px;
}

.header-style4 .nav-header > .nav-brand {
  min-height: 105px;
  line-height: 105px;
}

.header-style7 .nav-header > .nav-brand, .xs-header.header-style9 .nav-header > .nav-brand {
  min-height: 111px;
  line-height: 111px;
}
.header-style7 .nav-menus-wrapper .nav-menu, .xs-header.header-style9 .nav-menus-wrapper .nav-menu {
  padding-right: 0px;
}

.sticky-header .xs-header.header-style9 .nav-header > .nav-brand {
  min-height: 81px;
  line-height: 81px;
}

.header-style5 .nav-menus-wrapper .nav-menu {
  padding-right: 0px;
}

.sticky-header .nav-header > .nav-brand {
  min-height: 83px;
  line-height: 83px;
}

/*=================================================
          2. Modal
===============================================*/
/* magnific pop up modal */
.mfp-bg.xs-promo-popup {
  background-color: rgba(0, 0, 0, 0.87);
  padding-bottom: 100%;
  border-radius: 100%;
  overflow: hidden;
  -webkit-animation: menu-animation .8s ease-out forwards;
  animation: menu-animation .8s ease-out forwards;
}

/* animation keyframes list */
@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    opacity: 1;
    -webkit-transform: scale(0.04) translateY(0px);
    transform: scale(0.04) translateY(0px);
  }
  99.9% {
    opacity: 1;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(2) translateY(0px);
    transform: scale(2) translateY(0px);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}
@keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    opacity: 1;
    -webkit-transform: scale(0.04) translateY(0px);
    transform: scale(0.04) translateY(0px);
  }
  99.9% {
    opacity: 1;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(2) translateY(0px);
    transform: scale(2) translateY(0px);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}
/* Styles for dialog window */
.xs-promo-popup .modal-content {
  background-color: transparent;
  padding: 0px;
  border: 0px;
}
.xs-promo-popup .mfp-close {
  color: #FFFFFF;
  opacity: 0;
  -webkit-transition: all 1s ease .8s;
  -o-transition: all 1s ease .8s;
  transition: all 1s ease .8s;
  -webkit-transform: translateY(-500px);
  -ms-transform: translateY(-500px);
  transform: translateY(-500px);
}
.xs-promo-popup.mfp-ready .mfp-close {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/* at start */
.xs-promo-popup.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 1s ease .8s;
  -o-transition: all 1s ease .8s;
  transition: all 1s ease .8s;
  -webkit-transform: translateY(-500px);
  -ms-transform: translateY(-500px);
  transform: translateY(-500px);
}

/* animate in */
.xs-promo-popup.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/*=================================================
          3. Modal Flag
===============================================*/
.language-content p {
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.25rem;
}

/* language modal */
.flag-lists {
  text-align: center;
}
.flag-lists li {
  display: inline-block;
  margin-right: 25px;
}
.flag-lists li:last-child {
  margin-right: 0;
}
.flag-lists li a {
  display: block;
  color: #FFFFFF;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flag-lists li a:hover {
  opacity: .7;
}
.flag-lists li a img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/*=================================================
          4. Modal Search
===============================================*/
.modal-searchPanel .xs-search-group {
  position: relative;
}
.modal-searchPanel .xs-search-group input:not([type="submit"]) {
  height: 70px;
  background-color: transparent;
  border-radius: 50px;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  padding: 0 30px;
}
.modal-searchPanel .xs-search-group input:not([type="submit"])::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #FFFFFF;
}
.modal-searchPanel .xs-search-group input:not([type="submit"])::-moz-placeholder {
  /* Firefox 19+ */
  color: #FFFFFF;
}
.modal-searchPanel .xs-search-group input:not([type="submit"]):-ms-input-placeholder {
  /* IE 10+ */
  color: #FFFFFF;
}
.modal-searchPanel .xs-search-group input:not([type="submit"]):-moz-placeholder {
  /* Firefox 18- */
  color: #FFFFFF;
}
.modal-searchPanel .xs-search-group .search-button {
  background-color: transparent;
  border: 0px;
  padding: 0;
  color: #FFFFFF;
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 100%;
  width: 70px;
  border-radius: 0 50px 50px 0;
}

/*=================================================
          5. Offset Cart
===============================================*/
.widget-heading {
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px;
}

.mini_cart_item {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.xs-empty-content {
  text-align: center;
  padding: 120px 30px 30px;
}
.xs-empty-content .widget-title {
  font-size: 1.375rem;
}
.xs-empty-content .xs-title {
  margin-bottom: 20px;
  font-size: 1.125rem;
}
.xs-empty-content .woocommerce-mini-cart__empty-message {
  font-size: 1.25rem;
}
.xs-empty-content .empty-cart-icon {
  font-size: 3.125rem;
}

/*=================================================
          6. Offset info bar
===============================================*/
.sidebar-textwidget .sidebar-logo-wraper {
  margin-bottom: 33px;
}
.sidebar-textwidget .sidebar-logo-wraper a {
  display: inline-block;
}
.sidebar-textwidget > p {
  font-size: 0.9375rem;
  line-height: 1.5;
  margin-bottom: 40px;
}
.sidebar-textwidget .social-list {
  margin-bottom: 30px;
  text-align: center;
}

.sideabr-list-widget {
  margin-bottom: 40px;
}
.sideabr-list-widget li {
  margin-bottom: 20px;
}
.sideabr-list-widget li:last-child {
  margin-bottom: 0px;
}
.sideabr-list-widget img {
  margin-right: 15px;
  margin-top: 3px;
}
.sideabr-list-widget p, .sideabr-list-widget a {
  margin-bottom: 2px;
  color: #000000;
  margin-bottom: 8px;
  display: block;
}
.sideabr-list-widget span {
  display: block;
  font-size: 0.875rem;
  color: #1768dd;
}
.sideabr-list-widget .media-body {
  line-height: 1;
  margin-top: 6px;
}

.subscribe-form-wraper {
  position: relative;
  margin-bottom: 40px;
}
.subscribe-form-wraper p {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1;
  color: #1768dd;
}

/*=================================================
          7. Offset side bar
===============================================*/
/* .xs-sidebar-group .xs-overlay {
  left: 100%;
  top: 0;
  position: fixed;
  z-index: 101;
  height: 100%;
  opacity: 0;
  width: 100%;
  visibility: hidden;
  -webkit-transition: all .4s ease-in .8s;
  -o-transition: all .4s ease-in .8s;
  transition: all .4s ease-in .8s;
  cursor: url(../images/cross-out.png), pointer;
} */

.xs-sidebar-widget {
  position: fixed;
  right: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 420px;
  z-index: 999999;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  -webkit-transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
  -o-transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
  transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
  visibility: hidden;
  opacity: 0;
}

.xs-sidebar-group.isActive .xs-overlay {
  opacity: .9;
  visibility: visible;
  -webkit-transition: all .8s ease-out 0s;
  -o-transition: all .8s ease-out 0s;
  transition: all .8s ease-out 0s;
  left: 0;
}
.xs-sidebar-group.isActive .xs-sidebar-widget {
  opacity: 1;
  visibility: visible;
  right: 0;
  -webkit-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
  -o-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
  transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
}

.sidebar-textwidget {
  padding: 40px;
  padding-top: 120px;
}

.close-side-widget {
  color: #1768dd;
  font-size: 0.9375rem;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid currentColor;
  border-radius: 50%;
  text-align: center;
}

.sidebar-widget-container {
  position: relative;
  top: 150px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease-in .3s;
  -o-transition: all .3s ease-in .3s;
  transition: all .3s ease-in .3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.xs-sidebar-group.isActive .sidebar-widget-container {
  top: 0px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 1s ease-out 1.2s;
  -o-transition: all 1s ease-out 1.2s;
  transition: all 1s ease-out 1.2s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*=================================================
          8. preloader
===============================================*/
#preloader {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 9999999999;
  background-color: #1868dd;
  -webkit-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
}
#preloader .preloader-cancel-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 40px;
  -webkit-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
}
#preloader.loaded {
  top: -150%;
}
#preloader.loaded .preloader-cancel-btn {
  bottom: 150%;
}

.prelaoder-btn {
  padding: 10px 30px;
}

.preloader-wrapper {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
/*=================================================
          9. social list
===============================================*/
.social-list li {
  display: inline-block;
  margin-right: 10px;
}
.social-list li:last-child {
  margin-right: 0px;
}
.social-list li a {
  display: block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 100%;
  border: 1px solid #6b7499;
  text-align: center;
  font-size: 0.875rem;
  color: #333333;
}
.social-list li a.facebook {
  color: #4969a8;
}
.social-list li a.facebook:hover {
  background-color: #4969a8;
  border-color: #4969a8;
}
.social-list li a.twitter {
  color: #2aa3ef;
}
.social-list li a.twitter:hover {
  background-color: #2aa3ef;
  border-color: #2aa3ef;
}
.social-list li a.linkedin {
  color: #1178b3;
}
.social-list li a.linkedin:hover {
  background-color: #1178b3;
  border-color: #1178b3;
}
.social-list li a.instagram {
  color: #e8715c;
}
.social-list li a.instagram:hover {
  background-color: #e8715c;
  border-color: #e8715c;
}
.social-list li a.googlePlus {
  color: #dc4a38;
}
.social-list li a.googlePlus:hover {
  background-color: #dc4a38;
  border-color: #dc4a38;
}
.social-list li a.vimeo {
  color: #1ab7ea;
}
.social-list li a.vimeo:hover {
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}
.social-list li a.pinterest {
  color: #bd081c;
}
.social-list li a.pinterest:hover {
  background-color: #bd081c;
  border-color: #bd081c;
}
.social-list li a:hover {
  color: #FFFFFF;
}
.social-list.version-2 li a {
  color: #FFFFFF;
  font-size: 0.745625rem;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 0px;
  border: 1px solid transparent;
}
.social-list.version-2 li a.facebook {
  background-color: #4969a8;
}
.social-list.version-2 li a.facebook:hover {
  color: #4969a8;
  border-color: currentColor;
}
.social-list.version-2 li a.twitter {
  background-color: #2aa3ef;
}
.social-list.version-2 li a.twitter:hover {
  color: #2aa3ef;
  border-color: currentColor;
}
.social-list.version-2 li a.linkedin {
  background-color: #1178b3;
}
.social-list.version-2 li a.linkedin:hover {
  color: #1178b3;
  border-color: currentColor;
}
.social-list.version-2 li a.instagram {
  background-color: #e8715c;
}
.social-list.version-2 li a.instagram:hover {
  color: #e8715c;
  border-color: currentColor;
}
.social-list.version-2 li a.googlePlus {
  background-color: #dc4a38;
}
.social-list.version-2 li a.googlePlus:hover {
  color: #dc4a38;
  border-color: currentColor;
}
.social-list.version-2 li a.vimeo {
  background-color: #1ab7ea;
}
.social-list.version-2 li a.vimeo:hover {
  color: #1ab7ea;
  border-color: currentColor;
}
.social-list.version-2 li a:hover {
  background-color: transparent;
}
.social-list.version-3 li a {
  height: 37px;
  line-height: 37px;
  width: 100%;
  border-radius: 18.5px;
  text-align: left;
  padding: 0 23px;
  font-size: 0.9375rem;
  color: #FFFFFF !important;
  border: 0px;
}
.social-list.version-3 li a i {
  padding-right: 10px;
}
.social-list.version-3 li a.facebook {
  background-color: #4969a8;
}
.social-list.version-3 li a.facebook:hover {
  background-color: #324873;
}
.social-list.version-3 li a.twitter {
  background-color: #2aa3ef;
}
.social-list.version-3 li a.twitter:hover {
  background-color: #0e7abe;
}
.social-list.version-3 li a.linkedin {
  background-color: #1178b3;
}
.social-list.version-3 li a.linkedin:hover {
  background-color: #0a496d;
}
.social-list.version-3 li a.googlePlus {
  background-color: #dc4a38;
}
.social-list.version-3 li a.googlePlus:hover {
  background-color: #aa2d1e;
}
.social-list.social-block li {
  display: block;
  margin-right: 0px;
}
.social-list.social-block li:not(:last-child) {
  margin-bottom: 5px;
}
.social-list.version-4 li a {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid transparent;
  font-size: 0.8125rem;
}
.social-list.version-5 li a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid transparent;
  font-size: 0.75rem;
  background-color: #FFFFFF;
}
.social-list.version-5 li a:hover {
  background-color: #e6e6e6;
}
.social-list.version-5 li a > i {
  background: -webkit-linear-gradient(331deg, #b224ef 19%, #5055fa 84%);
  background: -o-linear-gradient(331deg, #b224ef 19%, #5055fa 84%);
  background: linear-gradient(119deg, #b224ef 19%, #5055fa 84%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: #b224ef;
}
.social-list.square > li > a {
  border-radius: 0%;
}

.simple-social-list li {
  display: inline-block;
  margin-right: 32px;
}
.simple-social-list li:last-child {
  margin-right: 0px;
}
.simple-social-list li a {
  color: #FFFFFF;
  display: block;
  font-size: 1.125rem;
}
.simple-social-list li a.facebook {
  color: #3e5c9a;
}
.simple-social-list li a.facebook:hover {
  color: #283b63;
}
.simple-social-list li a.twitter {
  color: #1da1f2;
}
.simple-social-list li a.twitter:hover {
  color: #0b76b8;
}
.simple-social-list li a.googlePlus {
  color: #fb2f14;
}
.simple-social-list li a.googlePlus:hover {
  color: #bf1903;
}
.simple-social-list li a.linkedin {
  color: #0077b5;
}
.simple-social-list li a.linkedin:hover {
  color: #004569;
}
.simple-social-list li a.instagram {
  color: #e8715c;
}
.simple-social-list li a.instagram:hover {
  color: #d93b1f;
}
.simple-social-list li a.behance {
  color: #1769ff;
}
.simple-social-list li a.behance:hover {
  color: #0047ca;
}
.simple-social-list li a.dribbble {
  color: #ea4c89;
}
.simple-social-list li a.dribbble:hover {
  color: #d11960;
}
.simple-social-list.version-2 > li {
  vertical-align: middle;
}
.simple-social-list.version-2 > li:not(:last-child) {
  margin-right: 0px;
}
.simple-social-list.version-2 > li .title {
  color: #21069b;
  font-size: 1.75rem;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  padding-right: 30px;
}
.simple-social-list.version-2 > li > a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  font-size: 1.125rem;
  color: #000000;
  border-radius: 50%;
  text-align: center;
}
.simple-social-list.version-2 > li > a:hover {
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
}
.simple-social-list.vertical > li {
  display: block;
  margin-right: 0px;
}
.simple-social-list.vertical > li:not(:last-child) {
  margin-bottom: 18px;
}
.simple-social-list.vertical > li > a {
  color: #000000;
  position: relative;
}
.simple-social-list.vertical > li > a::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
  height: 3px;
  width: 10px;
  display: inline-block;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  -o-transition: transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  position: relative;
  top: -5px;
  right: -7px;
}
.simple-social-list.vertical > li > a:hover::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

/*=================================================
          10. agency banner
===============================================*/
.xs-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.case-details-banner .agency-banner-content p {
  color: #FFFFFF;
  font-size: 1rem;
}

/* banner icon image */
/* .banner-icon-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url(../images/welcome/welcome-icon-bg-2.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
} */

/* agency banner start */
.agency-banner {
  min-height: 850px;
  overflow: hidden;
  background: #0369d1;
  background: -webkit-linear-gradient(-3deg, #0369d1 0%, #00ecbc 100%);
  background: -webkit-linear-gradient(357deg, #0369d1 0%, #00ecbc 100%);
  background: -o-linear-gradient(357deg, #0369d1 0%, #00ecbc 100%);
  background: linear-gradient(93deg, #0369d1 0%, #00ecbc 100%);
}

/* agency banner content */
.agency-banner-content {
  color: #FFFFFF;
}
.agency-banner-content .watermark-title {
  font-size: 21.875rem;
  opacity: .06;
  top: -106px;
  left: -7px;
}

/* banner slider */
.single-banner-slider {
  min-height: 800px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-banner-slider {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.agency-banner-content.banner-style2 {
  position: relative;
  z-index: 9;
}
.agency-banner-content.banner-style2 .banner-title {
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 35px;
}
.agency-banner-content p {
  color: #878787;
  font-size: 0.9375rem;
  margin-bottom: 45px;
}

/* banner slider nav */
.banner-slider {
  position: relative;
}
.banner-slider .owl-nav .owl-next,
.banner-slider .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 15;
}
.banner-slider .owl-nav .owl-next > i,
.banner-slider .owl-nav .owl-prev > i {
  font-size: 1.375rem;
  color: #000000;
}
.banner-slider .owl-nav .owl-prev {
  left: 30px;
}
.banner-slider .owl-nav .owl-next {
  right: 30px;
}

.banner-area-wraper {
  position: relative;
}
.banner-area-wraper .banner-overlay-bg > img {
  position: absolute;
  z-index: 2;
  pointer-events: none;
}
.banner-area-wraper .banner-overlay-bg > img:not(.banner-dots) {
  opacity: .3;
}
.banner-area-wraper .banner-overlay-bg .banner-image1 {
  top: 0;
  right: 0;
}
.banner-area-wraper .banner-overlay-bg .banner-image2 {
  bottom: -161px;
  left: 0;
}
.banner-area-wraper .banner-overlay-bg .banner-dots1 {
  bottom: 0;
  right: 0;
}
.banner-area-wraper .banner-overlay-bg .banner-dots2 {
  left: 20px;
  top: 120px;
}

/* banner animation */
.owl-item.active .agency-banner-content .banner-title,
.owl-item.active .agency-banner-content p,
.owl-item.active .agency-banner-content .btn {
  -webkit-animation: inLeft 2s ease 0s both;
  animation: inLeft 2s ease 0s both;
}
.owl-item.active .agency-banner-content p {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}
.owl-item.active .agency-banner-content .btn-wraper .btn {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}
.owl-item.active .agency-banner-content .btn-wraper .btn + .btn {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
  -webkit-animation-name: inRight;
  animation-name: inRight;
}

@-webkit-keyframes inLeft {
  0% {
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes inLeft {
  0% {
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
@-webkit-keyframes inRight {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes inRight {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
/* curve banner */
.header-btn {
  text-align: right;
  padding-top: 26px;
}
.header-btn .btn {
  height: 45px;
  line-height: 45px;
  padding: 0 40px;
  letter-spacing: 0;
}
.header-btn .btn:not([class*=btn-outline-]) {
  background-color: transparent;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

.agency-banner3 {
  min-height: 870px;
  background: #0b53ff;
  background: -webkit-gradient(linear, left top, right top, from(#0b53ff), color-stop(50%, #0b53ff), to(#4ae5e9));
  background: -webkit-linear-gradient(left, #0b53ff 0%, #0b53ff 50%, #4ae5e9 100%);
  background: -o-linear-gradient(left, #0b53ff 0%, #0b53ff 50%, #4ae5e9 100%);
  background: linear-gradient(90deg, #0b53ff 0%, #0b53ff 50%, #4ae5e9 100%);
  position: relative;
  z-index: 1;
}
.agency-banner3 .banner-overlay-bg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  opacity: .03;
}

.agency-banner-content-group {
  width: 100%;
  position: relative;
  z-index: 9;
}
.agency-banner-content-group .banner-client-slider {
  margin-top: 78px;
}

.agency-banner3 .agency-banner-content-group {
  margin-top: 85px;
}

.agency-banner-content.banner-style3 .banner-title {
  font-size: 3rem;
}
.agency-banner-content.banner-style3 p {
  color: #FFFFFF;
}

.curve-shape > svg {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: calc(101% + 190px);
  -webkit-transform: rotateX(180deg);
  transform: translateX(-50%) rotateX(180deg);
  height: 91px;
}
.curve-shape .shape-svg {
  fill: #FFFFFF;
}

.xs-banner .scrollto-button-wraper {
  position: absolute;
  bottom: -67px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* banner version 4 */
.xs-banner.agency-banner4 {
  min-height: 1025px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.agency-banner-content.banner-style4 {
  margin-top: 239px;
}
.agency-banner-content.banner-style4 .banner-sub-title {
  color: #e36e91;
  font-size: 1.375rem;
  margin-bottom: 12px;
  font-family: "Roboto", sans-serif;
}
.agency-banner-content.banner-style4 .banner-title {
  color: #FFFFFF;
  font-size: 4rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 35px;
}
.agency-banner-content.banner-style4 .banner-title span {
  font-family: inherit;
  color: #e36e91;
}

/* banner style 5 */
[class*="transparent"] + .main-content-wraper .agency-banner5, .sticky-header + .main-content-wraper .agency-banner5 {
  top: 100px;
  margin-bottom: 100px;
}
[class*="transparent"] + .agency-banner5, .sticky-header + .agency-banner5 {
  top: 100px;
  margin-bottom: 100px;
}

.agency-banner5 {
  min-height: 570px;
}

.agency-banner-content.banner-style5 .banner-sub-title {
  font-size: 1.75rem;
  color: #21069b;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
.agency-banner-content.banner-style5 .separete-border {
  display: inline-block;
  height: 2px;
  width: 20px;
  background-color: #d8dae2;
  position: relative;
  top: -6px;
  margin-left: 15px;
  margin-right: 15px;
}
.agency-banner-content.banner-style5 .sub-content {
  color: #8c8ba8;
  font-size: 1.125rem;
  display: inline-block;
  font-weight: 400;
  line-height: 1.66666;
  position: relative;
  top: 20px;
}

.typing-effect > h2 {
  color: #000000;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  font-size: 5.625rem;
  line-height: 1;
}
.typing-effect > h2 span {
  font-family: inherit;
}
.typing-effect > h2 .color-char,
.typing-effect > h2 .typed-cursor {
  color: #21069b;
}

/* banner style 6 */
.agency-banner6 {
  min-height: 850px;
}
.agency-banner6 .banner-icon-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.agency-banner6 .agency-banner-img {
  margin-right: -175px;
  margin-left: -130px;
}

.agency-banner-content .banner-video-popups .xs-video-popup {
  -webkit-box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  color: #1a0b46;
  font-size: 1.75rem;
}
.agency-banner-content .banner-video-popups .xs-video-popup > i {
  margin-right: 0px;
}
.agency-banner-content .banner-video-popups .xs-video-popup:hover {
  color: #FFFFFF;
  background-color: #1a0b46;
}
.agency-banner-content .banner-video-popups > span {
  color: #1a0b46;
  font-size: 1.25rem;
  padding-left: 20px;
}
.agency-banner-content.banner-style6 .banner-title {
  font-size: 5.625rem;
  font-family: "Roboto", sans-serif;
  color: #21069b;
  font-weight: 900;
  margin-bottom: 40px;
  line-height: 1.06;
  padding-left: 18px;
}
.agency-banner-content.banner-style6 .banner-title > span {
  font-family: inherit;
}
.agency-banner-content.banner-style6 .banner-title .title-underline::before {
  left: -18px;
}
.agency-banner-content.banner-style6 p {
  color: #1a0b46;
  padding-right: 100px;
}

/* banner style 7 */
.agency-banner7 {
  min-height: 800px;
  background-color: #FFFFFF;
}
.agency-banner7 > .container {
  position: relative;
  z-index: 2;
}
.agency-banner7 [class^="agency-banner-img-"] {
  position: absolute;
}
.agency-banner7 .agency-banner-img-2 {
  top: 0;
  left: 0;
}
.agency-banner7 .agency-banner-img-1 {
  right: 0;
  bottom: -176px;
}

.agency-banner-content:hover .border-animation > .first {
  -webkit-animation: move-1 1.8s infinite linear;
  animation: move-1 1.8s infinite linear;
  width: 80px;
}
.agency-banner-content:hover .border-animation > .second {
  width: 0px;
  -webkit-animation: move-2 1.8s infinite linear;
  animation: move-2 1.8s infinite linear;
}
.agency-banner-content.banner-style7 {
  margin-top: 110px;
}
.agency-banner-content.banner-style7 .banner-sub-title {
  font-size: 1.375rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  margin-bottom: 18px;
}
.agency-banner-content.banner-style7 .banner-title {
  font-size: 5.625rem;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 30px;
}
.agency-banner-content.banner-style7 .banner-title > span {
  font-family: inherit;
  color: #1edee4;
  display: block;
}
.agency-banner-content.banner-style7 p {
  font-size: 1.125rem;
  color: #000000;
  margin-bottom: 30px;
}

.rev_slider li.tp-revslider-slidesli,
.rev_slider > ul,
.tp-simpleresponsive > ul {
  overflow: visible !important;
}

/* banner style 11 */
.agency-banner8 {
  min-height: 800px;
  background-repeat: no-repeat;
  background-position: top right;
}
.agency-banner8 .agency-banner-img {
  margin-right: -183px;
  margin-left: -303px;
}

.agency-banner-content.banner-style8 .banner-title {
  font-size: 3.625rem;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  color: #522cea;
  margin-bottom: 20px;
}
.agency-banner-content.banner-style8 p {
  font-size: 1.125rem;
}
.agency-banner-content.banner-style8 .xs-video-popup {
  -webkit-box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);
  color: #522cea;
  font-size: 1.125rem;
}

/* banner style 12 */
.agency-banner9 {
  min-height: 820px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 193px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.agency-banner9 > .container {
  position: relative;
  z-index: 2;
}
.agency-banner9 .banner-bottom-mockup-outer .mockup-content > img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  -o-transition: transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
}
.agency-banner9 .animated.banner-bottom-mockup-outer .mockup-content > img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.agency-banner9 .agency-banner-content {
  margin-top: 200px;
}

.agency-banner-content.banner-style9 .banner-video-popups {
  margin-bottom: 10px;
}
.agency-banner-content.banner-style9 .banner-video-popups .xs-video-popup {
  background-color: transparent;
  -webkit-box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.15);
  font-size: 2.625rem;
  color: #FFFFFF;
  line-height: 85px;
}
.agency-banner-content.banner-style9 .banner-title {
  font-size: 2.625rem;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #FFFFFF;
}

.banner-bottom-mockup-outer {
  position: absolute;
  bottom: -193px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 1110px;
  z-index: 2;
}
/* .banner-bottom-mockup-outer::before {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  background-image: url(../images/banner/shadow.png);
  height: 424px;
  top: calc(100% - 20px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
} */
.banner-bottom-mockup-outer .mockup-image {
  position: relative;
  z-index: 2;
}
.banner-bottom-mockup-outer .mockup-content {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  width: 777px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  overflow: hidden;
  height: 100%;
}
.banner-bottom-mockup-outer .mockup-content > img {
  max-width: 110%;
  margin-left: -5%;
}

/* svg shape banner */
.svg-shape {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.svg-shape .svg-gr-color-1 {
  stop-color: #000073;
}
.svg-shape .svg-gr-color-2 {
  stop-color: #3754CA;
}

.tp-parallax-wrap .tp-caption .agency-banner-content {
  margin-top: 60px !important;
}

/*=================================================
          11. agency feature box
===============================================*/
.agency-feature-group {
  padding-bottom: 120px;
  border-bottom: 1px solid #dadada;
}

.agency-feature-box {
  width: calc(100% - 100px);
  margin: 0 auto;
}
.agency-feature-box .xs-title {
  margin-bottom: 20px;
}
.agency-feature-box .xs-title a:hover {
  color: #1bd1ea;
}
.agency-feature-box .xs-svg {
  margin-bottom: 40px;
}
.agency-feature-box .xs-svg svg {
  max-width: 142px;
}
.agency-feature-box p {
  margin-bottom: 20px;
}
.agency-feature-box .simple-btn:hover {
  color: #1bd1ea;
}
.agency-feature-box .svg-image > img {
  width: 140px;
  height: 120px;
}

/*=================================================
          12. intro video section
===============================================*/
.agency-intro {
  padding-top: 15px;
}
.agency-intro .agency-section-title .watermark-title {
  top: -68px;
  left: -16px;
}
.agency-intro p {
  padding-right: 95px;
  margin-bottom: 40px;
}
.agency-intro .xs-list {
  margin-bottom: 50px;
}
.agency-intro .xs-list > li:not(:last-child) {
  margin-right: 60px;
}

.agency-intro-video {
  border-radius: 10px;
  -webkit-box-shadow: 0px 15px 70px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 15px 70px 0px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
}
.agency-intro-video::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.412;
}

/*=================================================
          13. creative comunication
===============================================*/
.agency-creative-comunication {
  background: #b224ef;
  background: -webkit-linear-gradient(325deg, #b224ef 0%, #5055fa 100%);
  background: -o-linear-gradient(325deg, #b224ef 0%, #5055fa 100%);
  background: linear-gradient(125deg, #b224ef 0%, #5055fa 100%);
}

.creative-comunication-content {
  color: #FFFFFF;
  padding-top: 50px;
}
.creative-comunication-content .agency-section-title .section-title {
  color: #FFFFFF;
}
.creative-comunication-content p {
  margin-bottom: 30px;
}

.round-media:not(:last-child) {
  margin-bottom: 20px;
}
.round-media i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ebf8e4;
  text-align: center;
  color: #7bce4d;
  font-size: 1.125rem;
}
.round-media .media-body {
  margin-top: 16px;
  padding-left: 20px;
}
.round-media .media-body p {
  margin-bottom: 0px;
}
.round-media.color-1 i {
  background-color: #fbe1e2;
  color: #e53935;
}
.round-media.color-2 i {
  background-color: #faf1b3;
  color: #ff8400;
}
.round-media.color-3 i {
  background-color: #efe7fe;
  color: #8a5bf5;
}

/*=================================================
          14. parallax area
===============================================*/
.agency-parallax-area {
  position: relative;
}
.agency-parallax-area .watermark-title {
  top: 210px;
}
.agency-parallax-area .parallax-slide {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  height: 772px;
}

.parallax-container-content {
  padding-top: 180px;
}
.parallax-container-content img {
  margin-bottom: 10px;
}
.parallax-container-content p {
  margin-bottom: 35px;
}

/*=================================================
          15. portfolio slider
===============================================*/
.single-portfolio {
  position: relative;
  overflow: hidden;
}
.single-portfolio::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: -webkit-linear-gradient(330deg, #0369d1 0%, #00ecbc 100%);
  background-image: -o-linear-gradient(330deg, #0369d1 0%, #00ecbc 100%);
  background-image: linear-gradient(-240deg, #0369d1 0%, #00ecbc 100%);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-portfolio .hover-area {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  color: #FFFFFF;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-portfolio hr {
  width: 35px;
  margin-top: 0px;
  margin-bottom: 32px;
  border-top: 2px solid #FFFFFF;
}
.single-portfolio .xs-title {
  margin-bottom: 15px;
}
.single-portfolio .xs-title a {
  color: #FFFFFF;
}
.single-portfolio:hover::before {
  opacity: 0.902;
}
.single-portfolio:hover .hover-area {
  top: 50%;
  opacity: 1;
}
.single-portfolio .vertical-border {
  display: block;
  width: 2px;
  height: 20px;
  background-color: #ff4f64;
  margin-bottom: 10px;
}

/* portfolio style 2 */
.portfolio-style2::before {
  display: none;
}
.portfolio-style2 .hover-area {
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
}
.portfolio-style2 .hover-area::before {
  top: 0;
  left: 0;
}
.portfolio-style2 .hover-area::after {
  right: 0;
  bottom: 0;
}
.portfolio-style2 .hover-area::before, .portfolio-style2 .hover-area::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 5px;
  background: #b224ef;
  background: -webkit-gradient(linear, left top, right top, color-stop(19%, #b224ef), to(#5055fa));
  background: -webkit-linear-gradient(left, #b224ef 19%, #5055fa 100%);
  background: -o-linear-gradient(left, #b224ef 19%, #5055fa 100%);
  background: linear-gradient(90deg, #b224ef 19%, #5055fa 100%);
}
.portfolio-style2 .hover-content {
  width: 100%;
}
.portfolio-style2 .xs-title {
  font-size: 1.125rem;
  margin-bottom: 3px;
}
.portfolio-style2 .xs-title a {
  color: #1e1e1e;
}
.portfolio-style2 .xs-title a:hover {
  color: #1868dd;
}
.portfolio-style2 .portfolio-tag {
  font-size: 0.75rem;
  background: -webkit-linear-gradient(329deg, #b224ef 0%, #5055fa 100%);
  background: -o-linear-gradient(329deg, #b224ef 0%, #5055fa 100%);
  background: linear-gradient(121deg, #b224ef 0%, #5055fa 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: #b224ef;
  position: relative;
}
.portfolio-style2 .portfolio-tag::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: -webkit-linear-gradient(329deg, #b224ef 0%, #5055fa 100%);
  background: -o-linear-gradient(329deg, #b224ef 0%, #5055fa 100%);
  background: linear-gradient(121deg, #b224ef 0%, #5055fa 100%);
}

/* portfolio style 3 */
.portfolio-style3 {
  margin: 0 10px;
}
.portfolio-style3::before {
  display: none;
}
.portfolio-style3:hover .hover-area {
  top: 30px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.portfolio-style3:hover .hover-area .hover-content {
  bottom: 0;
  opacity: 1;
}
.portfolio-style3 .hover-area {
  height: auto;
  top: 30px;
  left: 30px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  right: 30px;
  bottom: 30px;
  width: auto;
  opacity: 0;
  overflow: hidden;
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  -webkit-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}
.portfolio-style3 .hover-area::before, .portfolio-style3 .hover-area::after {
  position: absolute;
  content: "";
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background-color: rgba(255, 255, 255, 0.7);
}
.portfolio-style3 .hover-area::after {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.portfolio-style3 .hover-area .hover-content {
  position: absolute;
  bottom: -50px;
  opacity: 0;
  left: 0;
  z-index: 2;
  padding: 40px;
  text-align: left;
  -webkit-transition: all .7s ease-in-out;
  -o-transition: all .7s ease-in-out;
  transition: all .7s ease-in-out;
}
.portfolio-style3 .hover-area .hover-content .xs-title {
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
}
.portfolio-style3 .hover-area .hover-content .xs-title a {
  color: #103dbe;
}
.portfolio-style3 .hover-area .hover-content .portfolio-tag {
  color: #a79f9f;
}

.porefolio-area .agency-section-title.text-center {
  margin-bottom: 60px;
}

/*=================================================
          16. testimonial review
===============================================*/
.agency_review_section {
  background-repeat: no-repeat;
  background-position: top 260px left;
}

.review-content {
  position: relative;
}
.review-content > p {
  color: #000000;
  margin-bottom: 30px;
  font-size: 1.5rem;
  font-style: italic;
}
.review-content .watermark-icon {
  color: #000000;
  opacity: .05;
  top: -20px;
  left: 60px;
  font-size: 75px;
}
.review-content .review-image {
  width: 116px;
  height: 116px;
  margin: 0 auto;
  margin-bottom: 20px;
}
/* .review-content .review-image img {
  -webkit-mask: url(../images/team/shape-mask.png);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;
} */
.review-content .reviewer-bio h5 {
  font-family: "Playfair Display", serif;
  color: #1bd1ea;
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 0px;
}
.review-content .reviewer-bio p {
  color: #7a7a7a;
  font-size: 0.875rem;
  margin-bottom: 0px;
}

.review-slider {
  position: relative;
}
.review-slider .owl-stage-outer {
  padding: 30px 0;
}
.review-slider .owl-nav .owl-prev,
.review-slider .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.review-slider .owl-nav .owl-prev:hover,
.review-slider .owl-nav .owl-next:hover {
  color: #0c5adb;
}
.review-slider .owl-nav .owl-prev:focus,
.review-slider .owl-nav .owl-next:focus {
  outline: none;
}
.review-slider .owl-nav .owl-prev {
  left: -161px;
}
.review-slider .owl-nav .owl-next {
  right: -161px;
}

/* owl dots */
.owl-dots {
  text-align: center;
}
.owl-dots .owl-dot {
  display: inline-block;
  outline: none;
}
.owl-dots .owl-dot:not(:last-child) {
  margin-right: 15px;
}
.owl-dots .owl-dot span {
  display: block;
  border-radius: 50%;
  background-color: rgba(192, 192, 192, 0.502);
  width: 8px;
  height: 8px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.owl-dots .owl-dot span::before {
  position: absolute;
  content: "";
  top: -6px;
  left: -6px;
  border: 1px solid rgba(27, 209, 234, 0.502);
  border-radius: 50%;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.owl-dots .owl-dot.active span {
  background-color: #1bd1ea;
}
.owl-dots .owl-dot.active span::before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/*=================================================
          17. blog thumb post
===============================================*/
.single-blog {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: white;
  /* blog style 2 */
}
.single-blog:hover {
  -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
}
.single-blog:hover .post-image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.single-blog .post-image {
  position: relative;
  overflow: hidden;
}
.single-blog .post-image img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-blog .post-footer {
  padding: 10px 30px;
  margin-bottom: 0px;
}
.single-blog .post-footer .media-body img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 9px;
}
.single-blog .post-footer .media-body a {
  color: #b6b6b6;
  font-size: 0.875rem;
}
.single-blog .post-footer .xs-list li:not(:last-child) {
  margin-right: 25px;
}
.single-blog .post-footer .xs-list li a {
  color: #b6b6b6;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-blog .post-footer .xs-list li a:hover {
  color: #1bd1ea;
}
.single-blog .entry-header {
  border-bottom: 1px solid #ececec;
  padding: 35px;
}
.single-blog .entry-header .entry-title {
  margin-bottom: 20px;
}
.single-blog .entry-header .entry-meta span {
  color: #7a7a7a;
}
.single-blog.blog-style2 {
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
}
.single-blog.blog-style2:hover {
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
}
.single-blog.blog-style2 .entry-header {
  padding: 40px 0;
  width: calc(100% - (40px * 2));
  margin-left: auto;
  margin-right: auto;
}
.single-blog.blog-style2 .entry-header .entry-title {
  margin-bottom: 0px;
}
.single-blog.blog-style2 .entry-header .entry-title a:hover {
  color: #1868dd;
}
.single-blog.blog-style2 .entry-meta {
  margin-bottom: 8px;
}
.single-blog.blog-style2 .entry-meta > a {
  padding-left: 2px;
  font-size: 0.8125rem;
}
.single-blog.blog-style2 .post-footer {
  padding: 20px 40px 40px;
}
.single-blog.blog-style2 .post-footer .xs-list li a {
  color: #6d6d6d;
}
.single-blog.blog-style2 .post-footer .media-body a {
  color: #6d6d6d;
}

.agency_media_section {
  position: relative;
}
.agency_media_section .doodle-parallax .single-doodle.one {
  position: absolute;
  bottom: 270px;
  right: -120px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 137px;
  width: 204px;
  opacity: .3;
}
.agency_media_section .btn-wraper {
  margin-top: 30px;
}

.blog-post-list {
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
}
.blog-post-list .single-blog:last-child .entry-header {
  border-bottom: 1px solid transparent;
}
.blog-post-list .single-blog.blog-style2 {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.blog-post-list .single-blog.blog-style2 .entry-header {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 36px 0;
}

/*=================================================
          18. client slider
===============================================*/
.client-slider .item {
  min-height: 195px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: all-scroll;
}
.client-slider .item > img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.client-slider .item:hover > img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.client-slider-style2 .item > img {
  opacity: .1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.client-slider-style2 .item:hover > img {
  opacity: .5;
}

.banner-client-slider .item {
  min-height: auto;
}
.banner-client-slider .item > img {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: .2;
}
.banner-client-slider .item:hover > img {
  opacity: 1;
}

/*=================================================
          19. footer
===============================================*/
.xs-footer-section {
  color: #7a7a7a;
  position: relative;
}
.xs-footer-section .footer-top-area {
  padding: 120px 0;
  background-color: #222222;
}
.xs-footer-section .footer-bottom-area {
  padding: 27px 0;
  background-color: #2e2e2e;
}
.xs-footer-section .footer-vector-shape {
  position: absolute;
  bottom: 0;
  right: 0;
}
.xs-footer-section .copyright-content {
  position: relative;
  z-index: 2;
}
.xs-footer-section.delighter {
  overflow: hidden;
}
.xs-footer-section.delighter .wave-shape {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  height: 0%;
}
.xs-footer-section.delighter.started .wave-shape {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  height: auto;
}
.xs-footer-section .wave-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all .8s ease;
  -o-transition: all .8s ease;
  transition: all .8s ease;
}
.xs-footer-section .wave-shape > img {
  width: 100%;
}
.xs-footer-section .dot-images {
  position: absolute;
  top: -110px;
  right: -107px;
  opacity: .2;
}
.xs-footer-section.footer-style11 {
  background-color: #333333;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 120px;
  padding-bottom: 120px;
}
.xs-footer-section.footer-style11 .copyright-content p {
  margin-bottom: 0px;
  color: #FFFFFF;
}
.xs-footer-section.footer-style11 .copyright-content p > a {
  color: inherit;
}
.xs-footer-section.footer-style11 .copyright-content p > a:hover {
  color: #cccccc;
}
.xs-footer-section.footer-style11 .copyright-content .simple-social-list {
  margin-bottom: 30px;
}
.xs-footer-section.footer-style11 .copyright-content .simple-social-list > li:not(:last-child) {
  margin-right: 15px;
}
.xs-footer-section.footer-style11 .copyright-content .simple-social-list > li > a {
  font-size: 0.875rem;
}
.xs-footer-section.footer-style11 .copyright-content .simple-social-list > li > a:hover {
  color: #cccccc;
}

.footer-widget {
  position: relative;
  z-index: 2;
}
.footer-widget .footer-logo-wraper {
  margin-bottom: 40px;
}
.footer-widget .footer-logo-wraper .footer-logo {
  display: inline-block;
}
.footer-widget p a {
  color: #7a7a7a;
}
.footer-widget p a:hover {
  color: #FFFFFF;
}
.footer-widget p:not(:last-child) {
  margin-bottom: 25px;
}
.footer-widget .widget-title {
  color: #FFFFFF;
  margin-bottom: 40px;
}
.footer-widget .xs-list li:not(:last-child) {
  margin-bottom: 15px;
  margin-right: 0;
}
.footer-widget .xs-list li a {
  color: #7a7a7a;
}
.footer-widget .xs-list li a:hover {
  color: #FFFFFF;
}
.footer-widget .newsletter-form {
  margin-bottom: 40px;
}
.footer-widget .social-list li:not(:last-child) {
  margin-right: 7px;
}
.footer-widget .social-list li a {
  border: 1px solid #434343;
  color: #7e7e7e;
}
.footer-widget .social-list li a:hover {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.copyright-section p {
  margin-bottom: 0px;
  color: #7a7a7a;
}
.copyright-section p a {
  color: #FFFFFF;
}

.footer-bottom-area [class^="col-"] + [class^="col-"] {
  text-align: right;
}

/* footer style 2 */
.xs-footer-section.footer-style2 {
  background-color: #283149;
}
.xs-footer-section.footer-style2 .footer-top-area,
.xs-footer-section.footer-style2 .footer-bottom-area {
  background-color: transparent;
}
.xs-footer-section.footer-style2 .footer-logo-wraper {
  margin-bottom: 0px;
}
.xs-footer-section.footer-style2 .footer-top-area {
  padding: 44px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

.footer-bottom-area.style2 {
  padding: 20px 0;
}
.footer-bottom-area.style2 .copyright-section p {
  color: #686d7a;
  font-size: 0.875rem;
}

.footer-bottom-content {
  text-align: right;
}
.footer-bottom-content > ul {
  display: inline-block;
}
.footer-bottom-content .xs-list {
  margin-right: 30px;
}
.footer-bottom-content .xs-list > li:not(:last-child) {
  margin-right: 30px;
}
.footer-bottom-content .xs-list > li > a {
  color: #686d7a;
  font-size: 0.875rem;
}
.footer-bottom-content .simple-social-list > li:not(:last-child) {
  margin-right: 15px;
}
.footer-bottom-content .simple-social-list > li > a {
  font-size: 0.875rem;
}

/* footer style 3 */
.xs-footer-section.footer-style3 .footer-top-area {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 100px 0;
}
.xs-footer-section.footer-style3 .footer-bottom-area {
  background-color: #222222;
}
.xs-footer-section.footer-style3 .footer-widget .widget-title {
  color: #22c8e5;
  margin-bottom: 12px;
  font-size: 0.9375rem;
}
.xs-footer-section.footer-style3 .footer-widget > p {
  font-weight: 500;
  color: #22c8e5;
  font-size: 0.9375rem;
}
.xs-footer-section.footer-style3 .footer-widget > p a {
  font-weight: 400;
  display: block;
  font-size: 0.875rem;
}
.xs-footer-section.footer-style3 .footer-widget .xs-list li:not(:last-child) {
  margin-bottom: 5px;
}
.xs-footer-section.footer-style3 .footer-widget .xs-list li a {
  font-size: 0.8125rem;
}
.xs-footer-section.footer-style3 .copyright-section p {
  color: #FFFFFF;
  font-size: 0.9375rem;
}
.xs-footer-section.footer-style3 .copyright-section p a {
  color: #22c8e5;
}

/* footer style 4 */
.footer-style4 .footer-top-area, .footer-style5 .footer-top-area {
  background-color: #FFFFFF;
  padding-top: 100px;
  padding-bottom: 50px;
}
.footer-style4 .footer-widget > a, .footer-style5 .footer-widget > a {
  display: block;
  color: #8d8d8d;
}
.footer-style4 .footer-widget > a:hover, .footer-style5 .footer-widget > a:hover {
  color: #1868dd;
}
.footer-style4 .footer-widget > a:not(:last-child), .footer-style5 .footer-widget > a:not(:last-child) {
  margin-bottom: 15px;
}
.footer-style4 .footer-widget .simple-social-list, .footer-style5 .footer-widget .simple-social-list {
  text-align: right;
}
.footer-style4 .footer-widget .simple-social-list > li:not(:last-child), .footer-style5 .footer-widget .simple-social-list > li:not(:last-child) {
  margin-right: 15px;
}
.footer-style4 .footer-widget .simple-social-list > li > a, .footer-style5 .footer-widget .simple-social-list > li > a {
  font-size: 0.875rem;
}
.footer-style4 .footer-widget > p, .footer-style5 .footer-widget > p {
  color: #a8a8a8;
}
.footer-style4 .footer-widget > p:not(:last-child), .footer-style5 .footer-widget > p:not(:last-child) {
  margin-bottom: 15px;
}
.footer-style4 .footer-widget .subscribe-form, .footer-style5 .footer-widget .subscribe-form {
  width: calc(100% - 155px);
}
.footer-style4 .footer-widget .widget-title, .footer-style5 .footer-widget .widget-title {
  color: #000000;
  font-size: 1.125rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 18px;
}
.footer-style4 .footer-widget .xs-list > li:not(:last-child), .footer-style5 .footer-widget .xs-list > li:not(:last-child) {
  margin-bottom: 3px;
}
.footer-style4 .footer-widget .xs-list > li > a, .footer-style5 .footer-widget .xs-list > li > a {
  font-size: 0.875rem;
  color: #8d8d8d;
}
.footer-style4 .footer-widget .xs-list > li > a:hover, .footer-style5 .footer-widget .xs-list > li > a:hover {
  color: #000000;
}
.footer-style4 .footer-bottom-area, .footer-style5 .footer-bottom-area {
  background-color: #f5f5f5;
}
.footer-style4 .footer-bottom-area .copyright-section p, .footer-style5 .footer-bottom-area .copyright-section p {
  color: #000000;
}
.footer-style4 .footer-bottom-area .copyright-section p a, .footer-style5 .footer-bottom-area .copyright-section p a {
  color: #1868dd;
}
.footer-style4 .footer-bottom-area .copyright-content .xs-list > li:not(:last-child), .footer-style5 .footer-bottom-area .copyright-content .xs-list > li:not(:last-child) {
  margin-right: 30px;
}
.footer-style4 .footer-bottom-area .copyright-content .xs-list > li > a, .footer-style5 .footer-bottom-area .copyright-content .xs-list > li > a {
  font-size: 0.9375rem;
}

/* footer style 5 */
.footer-style5 .footer-top-area {
  background-color: #f7f7f7;
  padding-bottom: 90px;
}
.footer-style5 .footer-widget .subscribe-form {
  margin-bottom: 50px;
  width: 100%;
}

.copyright-text > p {
  font-size: 0.875rem;
  color: #000000;
}
.copyright-text > p > a {
  color: #78d0e8;
}
.copyright-text > p > a:hover {
  color: #37b9dd;
}

.calltoaction-area-2 + .footer-style5 .footer-top-area {
  padding-top: 150px;
}

/* footer style 6 */
.footer-style6 {
  padding-top: 49px;
}
.footer-style6 .footer-top-area {
  padding: 0px;
  background-color: transparent;
  position: relative;
  z-index: 1;
}
.footer-style6 .footer-left-content-group {
  padding-right: 95px;
  border-bottom: 1px solid #d8dae2;
  padding-bottom: 59px;
}
.footer-style6 .footer-widget .widget-title {
  color: #21069b;
  font-size: 1.75rem;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  margin-bottom: 17px;
}
.footer-style6 .footer-widget .xs-list > li:not(:last-child) {
  margin-bottom: 12px;
}
.footer-style6 .footer-widget .xs-list > li > a {
  color: #898b90;
}
.footer-style6 .footer-widget .xs-list > li > a:hover {
  color: #21069b;
}
.footer-style6 .copyright-content {
  padding: 45px 0;
}
.footer-style6 .copyright-content .copyright-text {
  color: #000000;
  font-weight: 500;
}
.footer-style6 .copyright-content .copyright-text a {
  color: #21069b;
}
.footer-style6 .copyright-content .copyright-text a:hover {
  color: #000000;
}
.footer-style6 .contact-box-wraper {
  margin-top: -50px;
}

/* footer style 7 */
.footer-style7 {
  background-repeat: no-repeat;
  background-position: right center;
}
.footer-style7 .footer-top-area {
  background-color: transparent;
  padding: 80px 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.footer-style7 .footer-top-area .footer-widget.text-widget {
  padding-right: 75px;
}
.footer-style7 .footer-top-area .footer-widget .footer-logo-wraper {
  margin-bottom: 25px;
}
.footer-style7 .footer-top-area .footer-widget > p {
  color: #949494;
}
.footer-style7 .footer-top-area .footer-widget > p:not(:last-child) {
  margin-bottom: 20px;
}
.footer-style7 .footer-top-area .footer-widget > p > a {
  color: #949494;
}
.footer-style7 .footer-top-area .footer-widget > p > a:hover {
  color: #000000;
}
.footer-style7 .footer-top-area .footer-widget > p:nth-child(2) > a {
  color: #000000;
}
.footer-style7 .footer-top-area .footer-widget > p:nth-child(2) > a:hover {
  color: #949494;
}
.footer-style7 .footer-top-area .footer-widget .xs-list > li > a {
  font-size: 1rem;
  font-weight: 500;
  color: #000a7a;
}
.footer-style7 .footer-top-area .footer-widget .xs-list > li > a:hover {
  color: #1868dd;
}
.footer-style7 .footer-top-area .footer-widget .xs-list > li:not(:last-child) {
  margin-bottom: 12px;
}
.footer-style7 .footer-top-area .footer-widget .xs-list.round > li::before {
  margin-top: 10px;
  background-color: #cccccc;
}
.footer-style7 .footer-bottom-area {
  background-color: transparent;
}
.footer-style7 .footer-bottom-area .copyright-section > p {
  color: #ababab;
}
.footer-style7 .footer-bottom-area .copyright-section > p > a {
  color: #103dbe;
}
.footer-style7 .footer-bottom-area .copyright-section > p > a:hover {
  color: #1868dd;
}
.footer-style7 .footer-bottom-area .simple-social-list > li:not(:last-child) {
  margin-right: 25px;
}
.footer-style7 .footer-bottom-area .simple-social-list > li > a {
  color: #000a7a;
  font-size: 0.875rem;
}
.footer-style7 .footer-bottom-area .simple-social-list > li > a:hover {
  color: #000000;
}

/* footer style 8 */
.footer-style8 {
  border-top: 1px solid #f5f5f5;
}
.footer-style8 .footer-top-area {
  background-color: transparent;
}
.footer-style8 .footer-top-area .footer-widget .footer-logo-wraper {
  margin-bottom: 25px;
}
.footer-style8 .footer-top-area .footer-widget .copyright-section > p, .footer-style8 .footer-top-area .footer-widget .copyright-section a {
  color: #a3a3a3;
}
.footer-style8 .footer-top-area .footer-widget .copyright-section a:hover {
  color: #1868dd;
}
.footer-style8 .footer-top-area .footer-widget .address-info {
  margin-bottom: 40px;
}
.footer-style8 .footer-top-area .footer-widget .address-info > p {
  font-size: 1.125rem;
  margin-bottom: 0px;
}
.footer-style8 .footer-top-area .footer-widget .address-info > p > a {
  color: #a3a3a3;
}
.footer-style8 .footer-top-area .footer-widget .address-info > p > a:hover {
  color: #1868dd;
}
.footer-style8 .footer-top-area .footer-widget .address-info > p:first-child {
  margin-bottom: 40px;
  letter-spacing: 1px;
}
.footer-style8 .footer-top-area .footer-widget .address-info > p:first-child > a {
  color: #000000;
}
.footer-style8 .footer-top-area .footer-widget .address-info > p:first-child > a:hover {
  color: #1868dd;
}
.footer-style8 .footer-top-area .footer-widget .simple-social-list > li:not(:last-child) {
  margin-right: 15px;
}
.footer-style8 .footer-top-area .footer-widget .simple-social-list > li > a {
  color: #000000;
}
.footer-style8 .footer-top-area .footer-widget .simple-social-list > li > a:hover {
  color: #1868dd;
}
.footer-style8 .footer-top-area .footer-widget.address-widget {
  padding-right: 95px;
}
.footer-style8 .footer-top-area .footer-widget .widget-title {
  color: #000000;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  letter-spacing: 1.5px;
  margin-bottom: 30px;
}

/* footer style 9 */
.footer-style9 {
  padding: 25px 0;
}
.footer-style9 .footer-widget .footer-logo-wraper {
  margin-bottom: 0px;
}
.footer-style9 .footer-widget .footer-logo {
  position: relative;
  margin-right: 30px;
}
.footer-style9 .footer-widget .footer-logo::before {
  position: absolute;
  content: "";
  right: -30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 40px;
  background-color: #e7e7e7;
}
.footer-style9 .footer-widget .copyright-title {
  padding-left: 30px;
  color: #aaaaaa;
}
.footer-style9 .footer-widget .copyright-title > a {
  color: #1868dd;
}
.footer-style9 .footer-widget .copyright-title > a:hover {
  color: #000000;
}
.footer-style9 .xs-list {
  margin-top: 10px;
}
.footer-style9 .xs-list > li:not(:last-child) {
  margin-right: 30px;
}
.footer-style9 .xs-list > li > a {
  font-size: 0.875rem;
}
.footer-style9 [class^="col-"] + [class^="col-"] {
  text-align: right;
}

/* footer style 10 */
.footer-style10 .footer-top-area {
  background-color: #FFFFFF;
  padding-top: 90px;
  padding-bottom: 50px;
}
.footer-style10 .footer-top-area .footer-widget .widget-title {
  font-size: 1.375rem;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  color: #000000;
  margin-bottom: 17px;
}
.footer-style10 .footer-top-area .footer-widget .xs-list > li:not(:last-child) {
  margin-bottom: 8px;
}
.footer-style10 .footer-top-area .footer-widget .xs-list > li > a {
  color: #a3a3a3;
}
.footer-style10 .footer-top-area .footer-widget .xs-list > li > a:hover {
  color: #1868dd;
}
.footer-style10 .footer-top-area .footer-widget .xs-list.address > li:not(:last-child) {
  margin-bottom: 18px;
}
.footer-style10 .footer-bottom-area {
  background-color: #7447ff;
  padding: 25px 0;
}
.footer-style10 .footer-bottom-area .copyright-section {
  padding: 13px 0;
}
.footer-style10 .footer-bottom-area .copyright-section > p {
  color: #FFFFFF;
  font-size: 0.9375rem;
}
.footer-style10 .footer-bottom-area .copyright-content {
  padding: 12px 0;
}
.footer-style10 .footer-bottom-area .copyright-content .simple-social-list > li:not(:last-child) {
  margin-right: 15px;
}
.footer-style10 .footer-bottom-area .copyright-content .simple-social-list > li > a {
  font-size: 0.9375rem;
}

.portfolio-testimonial-area + .footer-style11 {
  margin-top: -255px;
  padding-top: 400px;
}

/*=================================================
          20. inner page banner
===============================================*/
.xs-inner-banner {
  min-height: 531px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.xs-inner-banner::before, .xs-inner-banner::after {
  position: absolute;
  content: "";
  top: 0;
  height: 100%;
  width: 100%;
}
.xs-inner-banner::before {
  left: 0;
  background: #0369d1;
  background: -webkit-linear-gradient(329deg, #0369d1 0%, #0369d1 25%, #00ecbc 60%);
  background: -o-linear-gradient(329deg, #0369d1 0%, #0369d1 25%, #00ecbc 60%);
  background: linear-gradient(121deg, #0369d1 0%, #0369d1 25%, #00ecbc 60%);
  opacity: .85;
}
/* .xs-inner-banner::after {
  right: 0;
  background-image: url(../images/banner/inner-banner-overlay.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
} */
.xs-inner-banner .inner-banner {
  text-align: center;
  position: relative;
  z-index: 1;
  color: #FFFFFF;
}
.xs-inner-banner .inner-banner .inner-banner-title {
  font-size: 3.75rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: #FFFFFF;
}
.xs-inner-banner .inner-banner p {
  margin-bottom: 0px;
}
.xs-inner-banner.inner-banner2 {
  min-height: 452px;
}

/*=================================================
          21. counter up timer
===============================================*/
.agency-countdown-area {
  background-color: white;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.04);
}

.about-countdown-content {
  letter-spacing: 1px;
}
.about-countdown-content:not(.featured) {
  text-align: center;
  padding: 21px 0;
}
.about-countdown-content:not(.featured) h3 {
  color: #999999;
  font-weight: 400;
}
.about-countdown-content:not(.featured) span {
  color: #000000;
  font-weight: 700;
  font-size: 1rem;
}
.about-countdown-content.featured {
  text-align: right;
  background-color: #1ddfb2;
  padding-top: 34.5px;
  padding-bottom: 34.5px;
  padding-right: 58px;
}
.about-countdown-content.featured h3 {
  color: #FFFFFF;
  font-weight: 700;
}
.about-countdown-content h3 {
  margin-bottom: 0px;
  font-size: 0.875rem;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
}

/* funfact style2 */
.funfact-area .section-title-style2 .content-title {
  margin-bottom: 25px;
}

.single-funfact {
  text-align: center;
}
.single-funfact .funfact-header {
  min-height: 50px;
  margin-bottom: 10px;
}
.single-funfact > span {
  color: #000000;
  font-size: 1.75rem;
  font-weight: 700;
  display: inline-block;
}

/* funfact style 3 */
.funfact-img-wraper {
  margin-left: -45px;
}

.funfact-content {
  padding-right: 60px;
}
.funfact-content .funfact-title {
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}
.funfact-content p {
  margin-bottom: 30px;
}

.single-funfact-item .funfact-icon i {
  font-size: 3.75rem;
}
.single-funfact-item .color-1 {
  color: #ff5382;
}
.single-funfact-item .color-2 {
  color: #4951bf;
}
.single-funfact-item .color-3 {
  color: #668fe8;
}
.single-funfact-item > span {
  color: #000000;
  font-size: 2.25rem;
  font-weight: 700;
}

.funfact-lists .single-funfact-item:not(:last-child) {
  margin-right: 70px;
}

.funfact-2-section {
  position: relative;
  z-index: 9;
}

/*=================================================
          22. team content
===============================================*/
.agency-single-team {
  position: relative;
}
.agency-single-team .image {
  position: relative;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.agency-single-team .image > img {
  width: 100%;
}
.agency-single-team .image::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #0369d1;
  background: -webkit-linear-gradient(64deg, #0369d1 10%, #00ecbc 60%);
  background: -o-linear-gradient(64deg, #0369d1 10%, #00ecbc 60%);
  background: linear-gradient(26deg, #0369d1 10%, #00ecbc 60%);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.agency-single-team .hover-area {
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  color: #FFFFFF;
  position: absolute;
  top: 70%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  padding: 0 10px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.agency-single-team .hover-area .xs-title {
  font-size: 1.875rem;
  margin-bottom: 10px;
}
.agency-single-team .hover-area .xs-title a {
  color: #FFFFFF;
}
.agency-single-team .hover-area span {
  font-size: 0.875rem;
  font-weight: 700;
  display: block;
  margin-bottom: 30px;
  letter-spacing: 1px;
}
.agency-single-team .hover-area p {
  margin-bottom: 40px;
}
.agency-single-team:hover .image {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
  z-index: 1;
}
.agency-single-team:hover .image::before {
  opacity: .95;
}
.agency-single-team:hover .hover-area {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.agency-team-slider .owl-stage-outer {
  padding: 30px 0;
}
.agency-team-slider .owl-item.hover {
  z-index: 2;
}
.agency-team-slider .owl-item .agency-single-team .image > img {
  width: 100%;
}

/* agency team style 2 */
.single-team-style2, .single-team-style3 {
  position: relative;
  overflow: hidden;
}
.single-team-style2:hover .hover-area, .single-team-style3:hover .hover-area {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.single-team-style2:hover .image::before, .single-team-style3:hover .image::before {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.single-team-style2 .image, .single-team-style3 .image {
  position: relative;
  overflow: hidden;
}
.single-team-style2 .image::before, .single-team-style3 .image::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #5055fa;
  background: -webkit-gradient(linear, left bottom, left top, from(#5055fa), to(rgba(80, 85, 250, 0)));
  background: -webkit-linear-gradient(bottom, #5055fa 0%, rgba(80, 85, 250, 0) 100%);
  background: -o-linear-gradient(bottom, #5055fa 0%, rgba(80, 85, 250, 0) 100%);
  background: linear-gradient(0deg, #5055fa 0%, rgba(80, 85, 250, 0) 100%);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
}
.single-team-style2 .hover-area, .single-team-style3 .hover-area {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: #FFFFFF;
  padding: 30px;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-team-style2 .hover-area .team-content, .single-team-style3 .hover-area .team-content {
  margin-bottom: 18px;
}
.single-team-style2 .hover-area .team-content .xs-title, .single-team-style3 .hover-area .team-content .xs-title {
  color: #FFFFFF;
}
.single-team-style2 .team-content .xs-title, .single-team-style3 .team-content .xs-title {
  font-size: 1.125rem;
}
.single-team-style2 .team-content span, .single-team-style3 .team-content span {
  font-size: 0.8125rem;
  display: block;
}

.team-bottom-info .content-title {
  margin-bottom: 21px;
}

/* team style 3 */
.team-section-area {
  position: relative;
}
.team-section-area .dot-images {
  position: absolute;
  bottom: 0;
  left: -50px;
  opacity: .2;
}

.single-team-style3 .image::before {
  background: black;
  background: -webkit-gradient(linear, left bottom, left top, from(black), color-stop(52%, rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(bottom, black 0%, rgba(0, 0, 0, 0) 52%);
  background: -o-linear-gradient(bottom, black 0%, rgba(0, 0, 0, 0) 52%);
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 52%);
}
.single-team-style3 .social-list > li > a {
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.single-team-style3 .team-content {
  padding: 25px;
}
.single-team-style3 .team-content .xs-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: #21069b;
  font-family: "Roboto", sans-serif;
  margin-bottom: 2px;
}
.single-team-style3 .team-content span {
  font-size: 0.9375rem;
}

.join-us-content {
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 63px 0;
}
.join-us-content .join-us-title {
  font-size: 2.25rem;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  margin-bottom: 19px;
}
.join-us-content .btn {
  height: 65px;
  line-height: 65px;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0 31px;
}
.join-us-content .btn.icon-right > i, .join-us-content .btn.icon-left > i {
  line-height: 65px;
}

/*=================================================
          23. centerd timeline
===============================================*/
.vertical-timeline {
  padding: 0 65px;
  position: relative;
}

.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  width: 100%;
}
.timeline::before {
  position: absolute;
  content: "";
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
  background: #b224ef;
  background: -webkit-gradient(linear, left top, left bottom, from(#b224ef), to(#5055fa));
  background: -webkit-linear-gradient(top, #b224ef 0%, #5055fa 100%);
  background: -o-linear-gradient(top, #b224ef 0%, #5055fa 100%);
  background: linear-gradient(-180deg, #b224ef 0%, #5055fa 100%);
  height: 100%;
  width: 1px;
}

.timeline-item {
  padding-left: 40px;
  position: relative;
}
.timeline-item:last-child {
  padding-bottom: 0;
}

.timeline-info {
  color: #000000;
  font-size: 1.125rem;
  font-family: "Work Sans", sans-serif;
  white-space: nowrap;
  letter-spacing: -0.1px;
}

.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 17px;
  height: 17px;
}
.timeline-marker:before, .timeline-marker:after {
  border-radius: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.timeline-marker:before {
  background: #b224ef;
  background: -webkit-linear-gradient(54deg, #b224ef 0%, #5055fa 100%);
  background: -o-linear-gradient(54deg, #b224ef 0%, #5055fa 100%);
  background: linear-gradient(36deg, #b224ef 0%, #5055fa 100%);
}
.timeline-marker::after {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #b224ef;
  background: -webkit-linear-gradient(54deg, #b224ef 0%, #5055fa 100%);
  background: -o-linear-gradient(54deg, #b224ef 0%, #5055fa 100%);
  background: linear-gradient(36deg, #b224ef 0%, #5055fa 100%);
  border: 3px solid #FFFFFF;
  top: 2px;
  left: 2px;
}

.timeline-content {
  padding-bottom: 40px;
}
.timeline-content .timeline-title {
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #000000;
}
.timeline-content p:last-child {
  margin-bottom: 0;
}

.period {
  padding: 0;
}
.period .timeline-info {
  display: none;
}
.period .timeline-marker:before {
  background: transparent;
  content: "";
  width: 15px;
  height: auto;
  border: none;
  border-radius: 0;
  top: 0;
  bottom: 30px;
  position: absolute;
  border-top: 3px solid #CCD5DB;
  border-bottom: 3px solid #CCD5DB;
}
.period .timeline-marker:after {
  content: "";
  height: 32px;
  top: auto;
}
.period .timeline-content {
  padding: 40px 0 70px;
}
.period .timeline-title {
  margin: 0;
}

@media (min-width: 768px) {
  .timeline-split .timeline, .timeline-centered .timeline {
    display: table;
  }
  .timeline-split .timeline-item, .timeline-centered .timeline-item {
    display: table-row;
    padding: 0;
  }
  .timeline-split .timeline-info, .timeline-centered .timeline-info,
  .timeline-split .timeline-marker,
  .timeline-centered .timeline-marker,
  .timeline-split .timeline-content,
  .timeline-centered .timeline-content,
  .timeline-split .period .timeline-info,
  .timeline-centered .period .timeline-info {
    display: table-cell;
    vertical-align: top;
  }
  .timeline-split .timeline-marker, .timeline-centered .timeline-marker {
    position: relative;
  }
  .timeline-split .timeline-content, .timeline-centered .timeline-content {
    padding-left: 30px;
  }
  .timeline-split .timeline-info, .timeline-centered .timeline-info {
    padding-right: 30px;
  }
  .timeline-split .period .timeline-title, .timeline-centered .period .timeline-title {
    position: relative;
    left: -45px;
  }
}

@media (min-width: 992px) {
  .timeline-centered,
  .timeline-centered .timeline-item,
  .timeline-centered .timeline-info,
  .timeline-centered .timeline-marker,
  .timeline-centered .timeline-content {
    display: block;
    margin: 0;
    padding: 0;
  }
  .timeline-centered .timeline-item {
    padding-bottom: 50px;
    overflow: hidden;
  }
  .timeline-centered .timeline-marker {
    position: absolute;
    left: 50%;
    margin-left: -7.5px;
  }
  .timeline-centered .timeline-info,
  .timeline-centered .timeline-content {
    width: 50%;
  }
  .timeline-centered > .timeline-item:nth-child(odd) .timeline-info {
    float: left;
    text-align: right;
    padding-right: 43px;
  }
  .timeline-centered > .timeline-item:nth-child(odd) .timeline-content {
    float: right;
    text-align: left;
    padding-left: 43px;
  }
  .timeline-centered > .timeline-item:nth-child(even) .timeline-info {
    float: right;
    text-align: left;
    padding-left: 43px;
  }
  .timeline-centered > .timeline-item:nth-child(even) .timeline-content {
    float: left;
    text-align: right;
    padding-right: 43px;
  }
  .timeline-centered > .timeline-item.period .timeline-content {
    float: none;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  .timeline-centered .timeline-item.period {
    padding: 50px 0 90px;
  }
  .timeline-centered .period .timeline-marker:after {
    height: 30px;
    bottom: 0;
    top: auto;
  }
  .timeline-centered .period .timeline-title {
    left: auto;
  }
}

/*=================================================
          24. office environment
===============================================*/
.agency-office-area .agency-section-title.text-center {
  margin-bottom: 30px;
}
.agency-office-area.xs-section-padding {
  padding-bottom: 70px;
}

.agency-office-slider .owl-stage-outer {
  padding: 50px 0;
}
.agency-office-slider .owl-nav button.owl-next, .agency-office-slider .owl-nav button.owl-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 1.875rem;
  color: #000000;
  display: block;
  width: 60px;
  height: 60px;
  line-height: 63px;
  border-radius: 100%;
  background-color: #FFFFFF;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.agency-office-slider .owl-nav button.owl-next:hover, .agency-office-slider .owl-nav button.owl-prev:hover {
  color: #FFFFFF;
  background-color: #000000;
}
.agency-office-slider .owl-nav button.owl-next:focus, .agency-office-slider .owl-nav button.owl-prev:focus {
  outline: none;
}
.agency-office-slider .owl-nav button.owl-next {
  right: 30px;
}
.agency-office-slider .owl-nav button.owl-prev {
  left: 30px;
}

.agency-office-single {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.2);
}
.agency-office-single:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.agency-office-single img {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/*=================================================
          25. service block
===============================================*/
.agency-service-area {
  position: relative;
}
.agency-service-area.xs-section-padding {
  padding-bottom: 20px;
}
.agency-service-area .doodle-parallax .single-doodle {
  position: absolute;
}
.agency-service-area .doodle-parallax .single-doodle.one {
  left: -50px;
  top: 400px;
}
.agency-service-area .doodle-parallax .single-doodle.two, .agency-service-area .doodle-parallax .single-doodle.three {
  right: 0;
  top: 0;
}
.agency-service-area .doodle-parallax .single-doodle.two {
  top: 135px;
}
.agency-service-area .doodle-parallax .single-doodle.three {
  top: 700px;
}

.agency-service-wraper {
  margin-bottom: 100px;
}
.agency-service-wraper:nth-child(even) [class*=col-]:nth-child(even) .agency-service-block i, .agency-service-wraper:nth-child(even) [class*=col-]:nth-child(even) .agency-service-block .simple-btn {
  color: #14dcaf;
}
.agency-service-wraper:nth-child(odd) [class*=col-]:nth-child(odd) .agency-service-block i, .agency-service-wraper:nth-child(odd) [class*=col-]:nth-child(odd) .agency-service-block .simple-btn {
  color: #14dcaf;
}

.agency-service-block {
  padding-right: 65px;
}
.agency-service-block > i {
  font-size: 3rem;
  display: inline-block;
  margin-bottom: 20px;
}
.agency-service-block .xs-title {
  margin-bottom: 20px;
}
.agency-service-block p {
  margin-bottom: 8px;
  color: #777777;
  font-size: 0.9375rem;
}
.agency-service-block i, .agency-service-block .simple-btn {
  color: #fc6161;
}
.agency-service-block .simple-btn {
  display: inline-block;
  font-size: 1.875rem;
}

/*=================================================
          26. contact us block
===============================================*/
.agency_contactus_block {
  padding: 140px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
}
.agency_contactus_block .doodle-parallax .single-doodle {
  position: absolute;
}
.agency_contactus_block .doodle-parallax .single-doodle.one {
  left: 0;
  bottom: 0;
}

.contact_us_element h3 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 3px;
}
.contact_us_element h4 {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 30px;
}

/*=================================================
          27. agency cases
===============================================*/
.agency-filter-wraper {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 30px;
}
.agency-filter-wraper .filter-button-wraper {
  margin-top: 12px;
}
.agency-filter-wraper .agency-section-title {
  margin-bottom: 0px;
}

.main-filter {
  text-align: right;
}
.main-filter li {
  display: inline-block;
}
.main-filter li:not(:last-child) {
  margin-right: 50px;
}
.main-filter li a {
  display: block;
  font-size: 0.875rem;
  color: #999999;
  font-weight: 700;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding-bottom: 20px;
}
.main-filter li a::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1px;
  width: 100%;
  background-color: #000000;
  height: 1px;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.main-filter li a:hover, .main-filter li a.selected {
  color: #000000;
}
.main-filter li a:hover::before, .main-filter li a.selected::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}

.xs-3d-project-card img {
  -webkit-transform: translateZ(40px);
  transform: translateZ(40px);
}

.cases-grid {
  margin-left: -15px;
  margin-right: -15px;
}

.single-cases-card {
  text-align: center;
  margin: 15px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-cases-card .xs-title {
  margin-bottom: 5px;
}
.single-cases-card:hover .card-image {
  -webkit-box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.1);
}
.single-cases-card:hover .card-image::before {
  background-color: #b3d7f3;
}
.single-cases-card .card-image {
  position: relative;
  padding: 30px;
  margin-bottom: 25px;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-cases-card .card-image img {
  -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
}
.single-cases-card .card-image::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f2f9fe;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: -1;
}
.single-cases-card .hover-content {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 0;
}
.single-cases-card .hover-content a {
  display: block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  text-align: center;
  color: #f9fafe;
  font-size: 1.375rem;
}

/* cases grid style 2 */
.case-card-style2 {
  margin-bottom: 65px;
}
.case-card-style2 .single-cases-card {
  text-align: left;
}
.case-card-style2 .single-cases-card:hover .card-image {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.case-card-style2 .single-cases-card:hover .card-image::after {
  opacity: 0.902;
}
.case-card-style2 .single-cases-card:hover .card-image .hover-content {
  top: 50%;
  opacity: 1;
}
.case-card-style2 .single-cases-card .card-image {
  padding: 0px;
}
.case-card-style2 .single-cases-card .card-image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background: #00ecbc;
  background: -webkit-linear-gradient(121deg, #00ecbc 10%, #0369d1 70%);
  background: -o-linear-gradient(121deg, #00ecbc 10%, #0369d1 70%);
  background: linear-gradient(-31deg, #00ecbc 10%, #0369d1 70%);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.case-card-style2 .single-cases-card .card-image img {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
}
.case-card-style2 .single-cases-card .card-image::before {
  display: none;
}
.case-card-style2.style2 .single-cases-card {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.case-card-style2.style2 .single-cases-card:hover {
  -webkit-box-shadow: 29.698px 29.698px 60px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 29.698px 29.698px 60px 0px rgba(0, 0, 0, 0.2);
}
.case-card-style2.style2 .single-cases-card .card-image {
  margin-bottom: 0px;
}
.case-card-style2.style2 .single-cases-card .card-image::after {
  border-radius: 0px;
}
.case-card-style2.style2 .single-cases-card .card-image img {
  border-radius: 0px;
}

/* case grid style 3 */
.case-card-style3 .single-cases-card {
  margin: 0px;
  text-align: left;
  position: relative;
  overflow: hidden;
  display: block;
}
.case-card-style3 .single-cases-card:hover .card-image {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.case-card-style3 .single-cases-card:hover .card-image > img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.case-card-style3 .single-cases-card:hover .cases-content {
  -webkit-transform: translateY(105%);
  -ms-transform: translateY(105%);
  transform: translateY(105%);
}
.case-card-style3 .single-cases-card.style2 .cases-content {
  background-color: rgba(54, 54, 54, 0.95);
}
.case-card-style3 .single-cases-card.style3 .cases-content {
  background-color: rgba(241, 75, 89, 0.95);
}
.case-card-style3 .single-cases-card.style3 .cases-content > .tag {
  color: #FFFFFF;
}
.case-card-style3 .single-cases-card .cases-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  padding: 32px 50px;
  background-color: #21069b;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.case-card-style3 .single-cases-card .cases-content > .tag {
  color: #f14b59;
  font-weight: 500;
  font-size: 0.9375rem;
  display: block;
}
.case-card-style3 .single-cases-card .cases-content > .xs-title {
  font-size: 1.75rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
  color: #FFFFFF;
}
.case-card-style3 .single-cases-card .card-image {
  padding: 0px;
  margin-bottom: 0px;
}
.case-card-style3 .single-cases-card .card-image > img {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.case-card-style3 .single-cases-card .card-image::before {
  display: none;
}

/*=================================================
          28. agency case details banner
===============================================*/
.case-details-banner {
  background-color: #00a39a;
  min-height: 800px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.case-details-banner-content .agency-banner-content {
  margin-bottom: 30px;
}
.case-details-banner-content .agency-banner-content .watermark-title {
  color: #FFFFFF;
  opacity: .2;
  top: -126px;
}
.case-details-banner-content .banner-title {
  margin-bottom: 2px;
}
.case-details-banner-content p {
  margin-bottom: 0px;
}
.case-details-banner-content .case-info-list {
  margin-bottom: 30px;
}

.case-details-image {
  position: relative;
}
.case-details-image > img {
  -webkit-box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.1);
}
.case-details-image .watermark-title {
  color: #000000;
  opacity: .2;
  font-size: 9.375rem;
  font-weight: 500;
  left: -24px;
  top: 10px;
}

.banner-video-popups {
  position: relative;
  z-index: 2;
}

/*=================================================
          29. agency case details about
===============================================*/
.about-info {
  padding-left: 95px;
}
.about-info .section-title {
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 25px;
  color: #000000;
}

.list-group .simple-social-list li:not(:last-child) {
  margin-right: 14px;
}
.list-group .simple-social-list li a {
  font-size: 0.875rem;
}

/*=================================================
          30. agency project brief
===============================================*/
.project-brief-img {
  padding-right: 65px;
}
.project-brief-img img {
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.08);
}

.project-brief-content {
  padding-top: 70px;
}
.project-brief-content p {
  margin-bottom: 40px;
}

/*=================================================
          31. agency working progress
===============================================*/
.working-progress-area {
  position: relative;
}
.working-progress-area .doodle-parallax .single-doodle {
  position: absolute;
}
.working-progress-area .doodle-parallax .single-doodle.one {
  right: -120px;
  top: 482px;
}
.working-progress-area .doodle-parallax .single-doodle.two {
  bottom: 320px;
  left: -250px;
}

/* count number */
.single-working-progress {
  counter-increment: step;
}

.count-number {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 63px;
  text-align: center;
  z-index: 1;
  line-height: 63px;
  margin-bottom: 20px;
}
.count-number::before {
  content: counter(step);
  color: #ffffff;
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: -2px;
  display: inline-block;
}
/* .count-number::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/progress/spin.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -1;
} */

/* count number spin effect */
.spin.count-number {
  visibility: hidden;
}
.spin.count-number::after {
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  -o-transition: transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
}
.spin.count-number.animated::after {
  -webkit-transform: rotate(720deg);
  -ms-transform: rotate(720deg);
  transform: rotate(720deg);
}

.working-progress-images img {
  border-radius: 5px;
  -webkit-box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.06);
}

.working-progress-content {
  padding-top: 25px;
}
.working-progress-content .section-title {
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 25px;
}

.single-working-progress:not(:last-child) {
  position: relative;
  padding-bottom: 300px;
}
/* .single-working-progress:not(:last-child)::before {
  position: absolute;
  content: "";
  bottom: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 555px;
  height: 200px;
  background-image: url(../images/progress/progress-line.png);
} */
.single-working-progress:nth-child(even) > .row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.single-working-progress:nth-child(even) .working-progress-images {
  padding-right: 28px;
}
.single-working-progress:nth-child(odd) .working-progress-content {
  padding-right: 30px;
}

/*=================================================
          32. agency call to action
===============================================*/
.agency_callto_action {
  background-color: #00a39a;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  color: #FFFFFF;
}
.agency_callto_action .section-title {
  color: #FFFFFF;
}

.call-to-action-content .section-title {
  margin-bottom: 20px;
  font-weight: 400;
}
.call-to-action-content p {
  margin-bottom: 40px;
}

/* agency call to action 2 */
.agency_callto_action2 .agency-section-title .main-title {
  margin-bottom: 20px;
}
.agency_callto_action2 .agency-section-title p {
  padding: 0 150px;
  margin-bottom: 37px;
}
.agency_callto_action2 .agency-section-title.text-center {
  margin-bottom: 0px;
}

/* call to action version 3 */
.calltoaction-section-3 {
  position: relative;
  background-color: #000a7a;
  padding: 120px 0;
  overflow: hidden;
}
.calltoaction-section-3 > .container {
  position: relative;
  z-index: 2;
}

.call-to-action-content-3 .call-to-action-title {
  color: #FFFFFF;
  font-size: 3rem;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  letter-spacing: -.2px;
  margin-bottom: 35px;
}
.call-to-action-content-3 .btn {
  height: 70px;
  line-height: 70px;
  font-weight: 400;
}
.call-to-action-content-3 .btn.icon-right > i, .call-to-action-content-3 .btn.icon-left > i {
  line-height: 70px;
}

.cal_to_action_animation_wraper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

/*=================================================
          33. agency pricing
===============================================*/
.pricing-table {
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  padding-top: 60px;
  padding-bottom: 40px;
}
.pricing-table:hover .btn {
  background-color: #1bd1ea;
  border-color: #1bd1ea;
  color: #FFFFFF;
}

.pricing-header {
  margin-bottom: 50px;
}
.pricing-header .xs-svg svg {
  width: 133px;
  height: 100px;
}
.pricing-header h3 {
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 50px;
}
.pricing-header .pricing-price h2 {
  color: #000000;
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 2px;
}
.pricing-header .pricing-price span {
  font-size: 0.8125rem;
  letter-spacing: 3px;
}

.pricing-body {
  margin-bottom: 50px;
}

.pricing-footer .btn {
  padding: 0 71px;
}

.pricing-list li {
  font-size: 0.9375rem;
}
.pricing-list li:not(:last-child) {
  margin-bottom: 10px;
}

/*=================================================
          34. agency contact
===============================================*/
.xs-map > div {
  height: 450px;
}

.agency-contactus {
  padding: 100px 0;
}
.agency-contactus.gray-bg {
  background-color: #f7f7f7;
}

.contact-info-wraper {
  padding-right: 100px;
}
.contact-info-wraper .contact-info-list {
  margin-bottom: 40px;
}

.contact-info-list li i {
  font-size: 1.125rem;
  background: -webkit-linear-gradient(328deg, #0369d1 4%, #0369d1 25%, #00ecbc 97%);
  background: -o-linear-gradient(328deg, #0369d1 4%, #0369d1 25%, #00ecbc 97%);
  background: linear-gradient(122deg, #0369d1 4%, #0369d1 25%, #00ecbc 97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0369d1;
  display: block;
  margin-bottom: 10px;
}
.contact-info-list li:not(:last-child) {
  margin-bottom: 35px;
}
.contact-info-list li strong {
  color: #000000;
  font-size: 1.125rem;
  font-family: "Work Sans", sans-serif;
  display: inline-block;
  margin-bottom: 2px;
}
.contact-info-list li .list-content > a {
  display: block;
  color: #999999;
}

.contact-form input:not([type="submit"]),
.contact-form textarea {
  border-radius: 5px;
  background-color: #FFFFFF;
  height: 54px;
  border: 1px solid #FFFFFF;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 30px;
  padding-left: 30px;
}
.contact-form input:not([type="submit"]):focus,
.contact-form textarea:focus {
  border-color: #1bd1ea;
}
.contact-form input:not([type="submit"]).invaild,
.contact-form textarea.invaild {
  border-color: #f44336;
}
.contact-form input:not([type="submit"])::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
  font-size: 0.9375rem;
}
.contact-form input:not([type="submit"])::-moz-placeholder,
.contact-form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #999999;
  font-size: 0.9375rem;
}
.contact-form input:not([type="submit"]):-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #999999;
  font-size: 0.9375rem;
}
.contact-form input:not([type="submit"]):-moz-placeholder,
.contact-form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #999999;
  font-size: 0.9375rem;
}
.contact-form input[type=number]::-webkit-inner-spin-button,
.contact-form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contact-form input[type=number] {
  -moz-appearance: textfield;
}
.contact-form textarea {
  height: 154px;
  resize: none;
  padding-top: 20px;
}
.contact-form .submit-btn {
  border-radius: 5px;
  background-color: #1bd1ea;
  height: 54px;
  border: 0px;
  color: #FFFFFF;
  font-size: 0.9375rem;
  letter-spacing: -.25px;
  padding: 0 91px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.contact-form .submit-btn:hover {
  background-color: #11a3b7;
}
.contact-form.style2 input:not([type="submit"]),
.contact-form.style2 textarea {
  border: 1px solid #e7e7e7;
}
.contact-form.style2 input:not([type="submit"]):focus,
.contact-form.style2 textarea:focus {
  border-color: #e7e7e7;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
}
.contact-form.style2 input:not([type="submit"]).invaild,
.contact-form.style2 textarea.invaild {
  border-color: #f44336;
}
.contact-form.style2 .btn-wraper {
  text-align: center;
}
.contact-form.style2 .btn {
  padding: 0 92px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 0.9375rem;
  font-weight: 400;
}

/* contact info 2 */
.contact-info-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.contact-info-area .doodle-parallax .single-doodle {
  position: absolute;
  z-index: -1;
}
.contact-info-area .doodle-parallax .single-doodle.one {
  left: -50px;
  top: 230px;
}
.contact-info-area .doodle-parallax .single-doodle.two {
  right: 0;
  top: 140px;
}

.contact-info2 {
  padding-right: 115px;
}
.contact-info2 .contact-info-list {
  padding-right: 190px;
  margin-bottom: 40px;
}
.contact-info2 .contact-info-list li:not(:last-child) {
  margin-bottom: 30px;
}

.contact-info-image-wraper {
  position: relative;
  z-index: 1;
}
.contact-info-image-wraper::before {
  position: absolute;
  content: "";
  top: -30px;
  right: 0;
  height: calc(100% + 60px);
  width: calc(100% - 330px);
  background-image: -webkit-linear-gradient(121deg, #0369d1 0%, #00ecbc 100%);
  background-image: -o-linear-gradient(121deg, #0369d1 0%, #00ecbc 100%);
  background-image: linear-gradient(-31deg, #0369d1 0%, #00ecbc 100%);
  border-radius: 5px;
  z-index: -1;
}
.contact-info-image-wraper .contact-info-image {
  padding-right: 30px;
}
.contact-info-image-wraper .contact-info-image img {
  border-radius: 5px;
  width: 100%;
}

.contact-box-wraper {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 60px;
}
.contact-box-wraper .contact-title {
  font-size: 2.25rem;
  color: #21069b;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  margin-bottom: 40px;
}

.dark-bg .agency-section-title.style7 .sub-title {
  color: rgba(255, 255, 255, 0.5);
}
.dark-bg .agency-section-title.style7 .main-title {
  color: #FFFFFF;
}
.dark-bg .agency-section-title.style7 .shuufle-letter-title {
  color: rgba(255, 255, 255, 0.2);
}

/*=================================================
          35. blog list
===============================================*/
.xs-blog-list {
  margin-bottom: 80px;
}
.xs-blog-list .post-list:not(:last-child) {
  margin-bottom: 30px;
}

.post-list {
  /* post list style 2 */
}
.post-list:hover .post-media > img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.post-list .post-body {
  padding: 0 38px;
  padding-bottom: 30px;
  background-color: #FFFFFF;
  border: 1px solid #f5f5f5;
}
.post-list .post-body .entry-header {
  padding-left: 30px;
  padding-right: 30px;
}
.post-list .post-body .entry-header .post-footer {
  margin-bottom: 0px;
}
.post-list .post-body .post-meta-top {
  z-index: 1;
  position: relative;
  margin-top: -30px;
  margin-bottom: 40px;
}
.post-list .post-body .entry-title {
  font-weight: 500;
  margin-bottom: 22px;
}
.post-list .post-body .entry-title a {
  color: #000000;
  display: inline-block;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.post-list .post-body .entry-title a:hover {
  color: #1868dd;
}
.post-list .post-body .post-meta {
  margin-bottom: 12px;
}
.post-list .post-body .post-meta .post-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.post-list .post-body .post-meta .post-author img + a {
  padding-left: 10px;
}
.post-list .post-body .post-meta .post-author a {
  color: #999999;
  font-size: 0.9375rem;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.post-list .post-body .post-meta .post-author a:hover {
  color: #1868dd;
}
.post-list .post-body .post-meta > span,
.post-list .post-body .post-meta a {
  color: #1868dd;
}
.post-list .post-body .entry-content {
  margin-bottom: 20px;
}
.post-list .post-body .entry-content p {
  margin-bottom: 0px;
}
.post-list .post-body .post-footer .simple-btn i {
  font-size: 2.25rem;
  padding-left: 0px;
  top: 0;
}
.post-list .post-body .post-footer .simple-btn i:hover {
  color: #1868dd;
}
.post-list .post-media {
  position: relative;
  overflow: hidden;
}
.post-list .post-media iframe {
  width: 100%;
  display: block;
}
.post-list .post-media .xs-video-popup {
  background-color: #1868dd;
}
.post-list .post-media .xs-video-popup:hover {
  color: #1868dd;
  background-color: #FFFFFF;
}
.post-list .post-media .entry-meta {
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 2;
}
.post-list .post-media > img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.post-list .post-meta-date {
  background-color: #1bd1ea;
  color: #FFFFFF;
  text-align: center;
  padding: 24px 17px;
  min-width: 80px;
}
.post-list .post-meta-date .day {
  font-size: 1.75rem;
  font-weight: 700;
  font-family: "Work Sans", sans-serif;
  line-height: 1;
}
.post-list .post-meta-date .month {
  font-size: 0.9375rem;
}
.post-list .post-meta-date > span {
  display: block;
}
.post-list.format-text {
  margin-top: 60px;
}
.post-list.style2 {
  margin-bottom: 30px;
}
.post-list.style2 .post-meta-date {
  background-color: #1868dd;
}
.post-list.style2 .post-body {
  padding: 0 30px 35px;
}
.post-list.style2 .post-body .post-meta-top {
  padding: 17px 30px;
}
.post-list.style2 .post-body .entry-header {
  padding: 0px;
}
.post-list.style2 .post-body .entry-title {
  font-size: 1.375rem;
  margin-bottom: 14px;
}
.post-list.style2 .post-body .post-meta {
  margin-bottom: 23px;
}
.post-list.style2 .post-body .entry-content {
  margin-bottom: 0px;
}
.post-list.style2 .post-media img {
  width: 100%;
}
.post-list.style2 .post-meta-top {
  margin-bottom: 30px;
}
.post-list.style2 .post-meta-top .entry-meta > span i {
  color: #1868dd;
}
.post-list.style2 .post-meta-top .entry-meta > span:not(:last-child) {
  padding-right: 23px;
}

.blog-grid,
.blog-grid-2 {
  margin: 0 -15px;
}
.blog-grid .grid-item .post-list,
.blog-grid-2 .grid-item .post-list {
  padding: 0 15px;
}
.blog-grid + .pagination,
.blog-grid-2 + .pagination {
  margin-top: 30px;
}

.post-meta-top {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 17px 39px;
}
.post-meta-top .entry-meta > span {
  display: inline-block;
  font-size: 0.9375rem;
}
.post-meta-top .entry-meta > span a {
  color: #999999;
}
.post-meta-top .entry-meta > span a:hover {
  color: #1868dd;
}
.post-meta-top .entry-meta > span:not(:last-child) {
  padding-right: 36px;
}
.post-meta-top .entry-meta > span i {
  color: #1bd1ea;
  padding-right: 6px;
}

.post-gallery-slider {
  position: relative;
}
.post-gallery-slider .owl-nav .owl-prev,
.post-gallery-slider .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.post-gallery-slider .owl-nav .owl-prev {
  left: 15px;
}
.post-gallery-slider .owl-nav .owl-next {
  right: 15px;
}
.post-gallery-slider .owl-nav i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  color: #1868dd;
  background-color: #FFFFFF;
  font-size: 15px;
}

.sidebar-widget.sidebar-right {
  margin-left: 10px;
}
.sidebar-widget.sidebar-left {
  margin-right: 10px;
}

/*=================================================
          36. blog sidebar
===============================================*/
.widget {
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  background-color: #FFFFFF;
  padding-left: 30px;
  padding-right: 30px;
}
.widget:not(:last-child) {
  margin-bottom: 30px;
}
.widget .xs-title {
  margin-bottom: 24px;
  font-size: 1.5rem;
  font-weight: 500;
}
.widget .xs-title span {
  color: #1bd1ea;
}
.widget:not(.widget-search) {
  padding-top: 40px;
  padding-bottom: 40px;
}
.widget.widget-search {
  padding-top: 20px;
  padding-bottom: 20px;
}
.widget .list-group li {
  font-size: 0.9375rem;
  position: relative;
}
.widget .list-group li a, .widget .list-group li span {
  color: #999999;
}
.widget .list-group li span {
  position: absolute;
  right: 0px;
}
.widget .list-group li a {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.widget .list-group li a:hover {
  color: #1868dd;
}
.widget .list-group li:not(:last-child) {
  margin-bottom: 10px;
}
.widget.widget-subscribe p {
  margin-bottom: 26px;
}

.widget-posts .widget-post:not(:last-child) {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e7e7e7;
}
.widget-posts .widget-post img {
  padding-right: 20px;
}
.widget-posts .widget-post .post-meta-date {
  color: #d2d2d2;
}
.widget-posts .widget-post .post-meta-date,
.widget-posts .widget-post .post-author {
  font-size: 0.9375rem;
}
.widget-posts .widget-post .media-body {
  margin-top: -3px;
}
.widget-posts .widget-post .entry-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0px;
  font-family: "Work Sans", sans-serif;
  letter-spacing: -.25px;
}
.widget-posts .widget-post .entry-title a {
  color: #000000;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.widget-posts .widget-post .entry-title a:hover {
  color: #1868dd;
}

.tag-lists a {
  display: inline-block;
  padding: 6px 17px;
  color: #999999;
  border: 1px solid #e7e7e7;
  border-radius: 18.5px;
  font-size: 0.9375rem;
  margin-right: 9px;
  margin-bottom: 9px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tag-lists a:hover {
  color: #FFFFFF;
  background-color: #1868dd;
  border-color: #1868dd;
}
.tag-lists .title {
  color: #000000;
  padding-right: 15px;
  font-weight: 700;
}

.widgetsub-form.subscribe-form input:not([type="submit"]) {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  background-color: white;
  height: 48px;
  padding-left: 30px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.widgetsub-form.subscribe-form input:not([type="submit"]):focus {
  border-color: #1868dd;
}
.widgetsub-form.subscribe-form input:not([type="submit"])::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cacaca;
  font-size: 0.9375rem;
}
.widgetsub-form.subscribe-form input:not([type="submit"])::-moz-placeholder {
  /* Firefox 19+ */
  color: #cacaca;
  font-size: 0.9375rem;
}
.widgetsub-form.subscribe-form input:not([type="submit"]):-ms-input-placeholder {
  /* IE 10+ */
  color: #cacaca;
  font-size: 0.9375rem;
}
.widgetsub-form.subscribe-form input:not([type="submit"]):-moz-placeholder {
  /* Firefox 18- */
  color: #cacaca;
  font-size: 0.9375rem;
}
.widgetsub-form.subscribe-form .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #1868dd;
  height: 50px;
  font-size: 0.9375rem;
  padding: 0 30px;
}
.widgetsub-form.subscribe-form .btn:hover {
  background-color: #1352af;
}

.widget-banner a {
  display: block;
}
.widget-banner a img {
  width: 100%;
}

.insta-feed2 li {
  float: left;
  display: inline-block;
  width: calc(25% - 2px);
  margin-right: 2px;
  margin-bottom: 2px;
}
.insta-feed2 li a {
  display: block;
  position: relative;
  overflow: hidden;
}
.insta-feed2 li a img {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.insta-feed2 li a:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/*=================================================
          37. blog single
===============================================*/
.blog-single-post .post-media {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.blog-single-post .post-media img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-single-post .post-media:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.blog-single-post .post-meta-list {
  margin-bottom: 20px;
}
.blog-single-post .post-body .entry-title {
  color: #000000;
  font-weight: 500;
  margin-bottom: 22px;
}
.blog-single-post .post-body .entry-conent {
  margin-bottom: 60px;
}
.blog-single-post .post-body .entry-conent p {
  position: relative;
  overflow: hidden;
  font-size: 1.0625rem;
  color: #777777;
}
.blog-single-post .post-body .entry-conent p:not(:last-child) {
  margin-bottom: 30px;
}
.blog-single-post .post-body .entry-conent p em {
  font-weight: 500;
}
.blog-single-post .post-body .entry-conent p img {
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.blog-single-post .post-body .entry-conent p img:hover {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}
.blog-single-post .post-body .entry-conent p img + em {
  text-align: center;
  display: block;
  font-weight: 400;
  color: #999999;
}
.blog-single-post .post-body .entry-conent a {
  color: #1868dd;
  text-decoration: underline;
}
.blog-single-post .post-body .entry-conent a:hover {
  color: #103dbe;
}
.blog-single-post .post-body .entry-conent h3 {
  margin-bottom: 25px;
  color: #000000;
  font-weight: 600;
  font-size: 1.375rem;
}
.blog-single-post .post-body .entry-conent blockquote {
  margin-bottom: 40px;
}
.blog-single-post .post-body .entry-conent .xs-list {
  margin-bottom: 50px;
  padding-left: 20px;
}
.blog-single-post .post-body .entry-conent .xs-list li:not(:last-child) {
  margin-bottom: 15px;
}
.blog-single-post .post-body .entry-conent .order-list {
  padding-left: 20px;
  margin-bottom: 40px;
}
.blog-single-post .post-body .entry-conent .order-list li {
  color: #777777;
}
.blog-single-post .post-body .entry-conent .order-list li::before {
  content: counter(step-counter) ".";
  width: auto;
  height: auto;
  line-height: normal;
  margin-right: 14px;
  border: 0px solid #000;
  color: #999999;
}

blockquote {
  background-color: #1868dd;
  padding: 60px;
  font-size: 1.375rem;
  font-family: "Playfair Display", serif;
  color: #FFFFFF;
  font-style: italic;
  font-weight: 700;
  position: relative;
}
blockquote::before {
  content: '\e93b';
  font-family: "iconfont";
  display: block;
  font-size: 3rem;
  font-weight: 400;
  font-style: normal;
  float: left;
  padding-right: 50px;
  margin-top: 11px;
}

.post-footer {
  margin-bottom: 60px;
}
.post-footer .tag-lists {
  margin-bottom: 25px;
}
.post-footer .tag-lists .title {
  font-weight: 400;
}
.post-footer .social-list {
  margin-bottom: 60px;
}
.post-footer .social-list li a {
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  padding: 0 42px;
}

.realted-article h4 {
  margin-bottom: 25px;
}

/* author card */
.author-card {
  padding: 60px 40px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 100px;
}
.author-card h4 {
  margin-bottom: 25px;
}
.author-card .author-info .autho-img {
  float: left;
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 30px;
}
.author-card .author-info .autho-img + .author-details {
  width: calc(100% - 110px);
}
.author-card .author-info .author-details {
  float: right;
}
.author-card .author-info .author-details h5 {
  color: #000000;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.author-card .author-info .author-details h5 a {
  padding-left: 15px;
  position: relative;
  display: inline-block;
  top: -2px;
}
.author-card .author-info .author-details h5 a .badge {
  font-size: 0.8125rem;
  font-weight: 500;
  background-color: #1868dd;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.author-card .author-info .author-details h5 a .badge:hover {
  background-color: #103dbe;
}
.author-card .author-info .author-details p {
  margin-bottom: 20px;
}

/* post navigation */
.post-navigation {
  margin-bottom: 100px;
}

.post-previous {
  text-align: right;
}

.post-previous,
.post-next {
  padding: 60px 80px;
  border: 1px solid #e7e7e7;
  position: relative;
}
.post-previous a,
.post-next a {
  display: block;
}
.post-previous a:hover h4,
.post-next a:hover h4 {
  color: #1868dd;
}
.post-previous a:hover .indicator-icon,
.post-next a:hover .indicator-icon {
  color: #1868dd;
}
.post-previous span,
.post-next span {
  display: block;
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 1.5px;
  margin-bottom: 22px;
}
.post-previous h4,
.post-next h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-bottom: 4px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.post-previous small,
.post-next small {
  font-size: 0.8125rem;
}
.post-previous .indicator-icon,
.post-next .indicator-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 2.25rem;
  color: #000000;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.post-previous {
  border-left: 0px;
}
.post-previous .indicator-icon {
  left: 40px;
}

.post-next {
  border-left: 0px;
  border-right: 0px;
}
.post-next .indicator-icon {
  right: 40px;
}

.relatednews-wraper {
  margin-bottom: 70px;
}
.relatednews-wraper h4 {
  margin-bottom: 35px;
}

/*========== Post meta list ==================*/
.post-meta-list > span {
  display: inline-block;
}
.post-meta-list > span:not(:last-child) {
  margin-right: 50px;
}
.post-meta-list > span i {
  color: #1868dd;
  padding-right: 5px;
}
.post-meta-list > span > a {
  color: #777777;
}
.post-meta-list > span > a:hover {
  color: #1868dd;
}
.post-meta-list .post-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

/*=================================================
          38. comment area
===============================================*/
.xs-comments-area {
  margin-bottom: 100px;
  padding-bottom: 100px;
  border-bottom: 1px solid #e7e7e7;
}
.xs-comments-area .comments-title {
  margin-bottom: 45px;
}
.xs-comments-area .comment-body:before {
  display: table;
  content: "";
  clear: both;
}
.xs-comments-area .comment-body img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  float: left;
}
.xs-comments-area .meta-data {
  margin-left: 100px;
}
.xs-comments-area .reply {
  float: right;
}
.xs-comments-area .reply .comment-reply-link {
  color: #626c84;
  font-size: 0.9375rem;
}
.xs-comments-area .reply .comment-reply-link i {
  color: #252a37;
  font-size: 1rem;
  padding-right: 10px;
}
.xs-comments-area .comment-author a {
  font-weight: 500;
}
.xs-comments-area .comment-author a::after {
  content: "/";
  padding: 0 5px;
  color: #999999;
}
.xs-comments-area .comment-date {
  color: #999999;
  margin-bottom: 0px;
  font-weight: 500;
  display: inline-block;
}
.xs-comments-area .comment-content {
  margin-top: 10px;
  padding-right: 50px;
}
.xs-comments-area .comment-content p {
  margin-bottom: 0px;
}
.xs-comments-area .comment:not(:last-child) .children {
  margin-left: 70px;
}

.xs-comments-area .comment:not(:last-child) .meta-data {
  margin-bottom: 50px;
}

/*=================================================
          39. comment form
===============================================*/
.comment-respond .comment-reply-title {
  margin-bottom: 20px;
}
.comment-respond .xs-form .form-control {
  margin-bottom: 30px;
}
.comment-respond .form-submit {
  margin-bottom: 0px;
}
.comment-respond .comments-info {
  font-size: 0.9375rem;
  margin-bottom: 35px;
}

.xs-form .form-control {
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding-left: 30px;
  background-color: #FFFFFF;
}
.xs-form .form-control:not(textarea) {
  height: 54px;
}
.xs-form .form-control:focus {
  border-color: #1868dd;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
}
.xs-form .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
  font-size: 0.9375rem;
}
.xs-form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #999999;
  font-size: 0.9375rem;
}
.xs-form .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #999999;
  font-size: 0.9375rem;
}
.xs-form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #999999;
  font-size: 0.9375rem;
}
.xs-form textarea.form-control {
  padding-top: 20px;
  height: 154px;
  resize: none;
}

/*=================================================
          40. parallax service
===============================================*/
.parallax-service {
  max-height: 100vh;
}

.single-service {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single-service.bg-1 {
  background-color: #1d2786;
}
.single-service.bg-2 {
  background-color: #0f51aa;
}
.single-service.bg-3 {
  background-color: #283149;
}

.parallax-service-content {
  position: relative;
  color: #FFFFFF;
  z-index: 1;
}
.parallax-service-content .watermark-icon {
  font-size: 9.375rem;
  color: rgba(255, 255, 255, 0.03);
  top: -70px;
  z-index: -1;
}
.parallax-service-content .section-title {
  margin-bottom: 20px;
  font-weight: 500;
  color: #FFFFFF;
}
.parallax-service-content p {
  font-size: 0.938125rem;
  color: #a8abc8;
  margin-bottom: 40px;
}
.parallax-service-content .simple-btn {
  color: #FFFFFF;
  letter-spacing: .5px;
  font-weight: 500;
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 14px 0;
}

.swiper-pagination-bullet {
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.swiper-pagination-bullet-active {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
}

.swiper-slide:nth-child(even) > .single-service .row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.swiper-slide:nth-child(even) > .single-service .parallax-service-img {
  margin-left: -70px;
}

/*=================================================
          41. shop
===============================================*/
/* single product */
.xs-single-product {
  margin-bottom: 20px;
}
.xs-single-product:hover .product-header > img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.xs-single-product:hover .product-header::before {
  opacity: 1;
}
.xs-single-product:hover .product-header .hover-area {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
}
.xs-single-product .product-footer {
  text-align: center;
  padding: 30px 15px;
}
.xs-single-product .product-footer .product-title {
  margin-bottom: 5px;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.xs-single-product .product-footer .product-title a {
  color: #777777;
}
.xs-single-product .product-footer .product-title a:hover {
  color: #1868dd;
}
.xs-single-product .product-footer .product-bdage {
  margin-bottom: 15px;
}

.product-header {
  position: relative;
  border-radius: 5px;
  min-height: 390px;
  background-color: #f7f8f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px;
  overflow: hidden;
}
.product-header::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 0;
  z-index: 1;
}
.product-header > img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.product-header .hover-area {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 0;
}
.product-header .image-content {
  position: absolute;
  right: 20px;
  top: 0;
  z-index: 2;
}

.price {
  font-size: 1.125rem;
  font-weight: 700;
}
.price del {
  color: #d7d7d7;
  padding-right: 10px;
}
.price ins {
  text-decoration: none;
  color: #000000;
}

.product-style2 .xs-single-product {
  background-color: #FFFFFF;
  -webkit-box-shadow: 19.799px 19.799px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 19.799px 19.799px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}
.product-style2 .xs-single-product .product-header {
  border-radius: 0px;
}
.product-style2 .xs-single-product .product-header::before {
  background-color: rgba(24, 104, 221, 0.9);
}
.product-style2 .xs-single-product .product-header .btn-gradient2 {
  background: #FFFFFF;
  color: #1868dd;
  padding: 0 46px;
  height: 54px;
  line-height: 54px;
  border-radius: 27px;
}
.product-style2 .xs-single-product .product-header .btn-gradient2:hover {
  background: #e6e6e6;
}

.product-bdage {
  color: #FFFFFF;
  font-size: 0.8125rem;
  display: inline-block;
  background-color: #ff5657;
  border-radius: 5px;
  padding: 0 5px;
}
.product-bdage.color-1 {
  background-color: #1868dd;
}
.product-bdage.color-2 {
  background-color: #75a339;
}

/*=================================================
          42. product slider
===============================================*/
.single-product-slider .product-header {
  min-height: 600px;
  border-radius: 0px;
}
.single-product-slider .product-header:before {
  display: none;
}

.product-slider {
  position: relative;
}
.product-slider .owl-dots {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
.product-slider .owl-dots .owl-dot span {
  background-color: rgba(0, 0, 0, 0.149);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.product-slider .owl-dots .owl-dot span::before {
  display: none;
}
.product-slider .owl-dots .owl-dot.active span {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.9);
  -ms-transform: scale(1.9);
  transform: scale(1.9);
}

/*=================================================
          43. single shop
===============================================*/
.product-summary {
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
}
.product-summary .product_title {
  font-size: 1.125rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}
.product-summary .product_meta {
  font-size: 0.9375rem;
}
.product-summary .product_meta:not(:last-child) {
  margin-bottom: 10px;
}
.product-summary .product_meta strong {
  color: #000000;
  font-weight: 500;
  padding-right: 10px;
}
.product-summary .product_meta a {
  color: #aaaaaa;
}
.product-summary .product_meta a:hover {
  color: #1868dd;
}
.product-summary .product_meta a:not(:last-child) {
  padding-right: 5px;
}
.product-summary .product_meta a:not(:last-child):after {
  content: ",";
  padding-left: 2px;
}
.product-summary .woocommerce-product-details__short-description p {
  font-size: 0.9375rem;
}
.product-summary .product-meta-group,
.product-summary .woocommerce-product-details__short-description,
.product-summary .price {
  margin-bottom: 30px;
}
.product-summary .price {
  font-size: 1.75rem;
  display: block;
}
.product-summary .custom-number-wraper {
  margin-bottom: 40px;
}

.product-cart-info-list > li {
  position: relative;
}
.product-cart-info-list > li .btn {
  padding: 0 30px;
  background-size: 160% auto;
}

.product-cart-info-list.list-inline > li:not(:last-child) {
  margin-right: 15px;
}

.related-products-area.xs-section-padding {
  padding-bottom: 40px;
}
.related-products-area .content-holder {
  margin-bottom: 60px;
}
.related-products-area .content-holder .section-title {
  font-size: 1.75rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}

/*=================================================
          44. product details
===============================================*/
.product-info-details-wraper p {
  font-size: 1.0625rem;
  color: #777777;
}

/*=================================================
          45. info block
===============================================*/
.single-info-block {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 70px 30px;
  min-height: 366px;
  background-color: #FFFFFF;
}
.single-info-block:hover, .single-info-block.active {
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
}
.single-info-block .info-header {
  margin-bottom: 25px;
}
.single-info-block .small {
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 1.125rem;
}

/* bussiness info area */
.business-info-area {
  padding-top: 70px;
  padding-bottom: 115px;
}

.business-info-and-history {
  background-repeat: no-repeat;
  background-position: top 127px center;
}
.business-info-and-history .success-history2-area {
  padding-bottom: 30px;
}

.info-block-style2 .single-info-block {
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 75px 40px;
}
.info-block-style2 .single-info-block .small {
  font-size: 1.375rem;
  margin-bottom: 22px;
}
.info-block-style2 .single-info-block p {
  margin-bottom: 30px;
}
.info-block-style2 .single-info-block .simple-btn,
.info-block-style2 .single-info-block .simple-btn i {
  background: -webkit-gradient(linear, left top, right top, from(#ff8f1e), color-stop(50%, #ff8f1e), to(#fe690b));
  background: -webkit-linear-gradient(left, #ff8f1e 0%, #ff8f1e 50%, #fe690b 100%);
  background: -o-linear-gradient(left, #ff8f1e 0%, #ff8f1e 50%, #fe690b 100%);
  background: linear-gradient(90deg, #ff8f1e 0%, #ff8f1e 50%, #fe690b 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: #ff8f1e;
}
.info-block-style2 .single-info-block .simple-btn {
  font-weight: 400;
}
.info-block-style2 .single-info-block .leaf {
  position: absolute;
  -webkit-filter: blur(24.7px);
  filter: blur(24.7px);
  z-index: -1;
}
.info-block-style2 .single-info-block .leaf.leaf-1 {
  top: 0;
  left: 0;
}
.info-block-style2 .single-info-block .leaf.leaf-2 {
  bottom: 0;
  left: 0;
}
.info-block-style2 .single-info-block .leaf.leaf-3 {
  top: 50px;
  right: 0;
}

/* business info area doodle parallax */
.business-info-area {
  position: relative;
}
.business-info-area .doodle-parallax .single-doodle {
  position: absolute;
  pointer-events: none;
}
.business-info-area .doodle-parallax .single-doodle.one {
  top: 0;
  left: 0;
}
.business-info-area .doodle-parallax .single-doodle.two {
  right: 0;
  bottom: 0;
}

.business-info-area2 {
  margin-bottom: 0px;
}

/*=================================================
          46. about summary
===============================================*/
.about-summary-content {
  padding-left: 40px;
}
.about-summary-content i {
  display: inline-block;
  margin-bottom: 20px;
}
.about-summary-content .section-title {
  margin-bottom: 30px;
}
.about-summary-content p {
  margin-bottom: 35px;
  color: #878787;
  font-size: 0.9375rem;
}

/*=================================================
          47. success summary
===============================================*/
.success-summary-content {
  margin-bottom: 60px;
}
.success-summary-content i {
  margin-bottom: 20px;
  display: inline-block;
}
.success-summary-content .content-title {
  margin-bottom: 25px;
}

/*=================================================
          48. call to action
===============================================*/
.calltoaction-area {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  padding: 102px 0;
}
.calltoaction-area .xs-overlay {
  opacity: .9;
}

.call-to-action {
  position: relative;
  z-index: 2;
}
.call-to-action .content-title {
  color: #FFFFFF;
  margin-bottom: 35px;
}
.call-to-action .btn-outline-secondary {
  line-height: 52px;
}
.call-to-action.action-style2 .content-title {
  font-size: 2.25rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 40px;
}
.call-to-action.action-style2 .btn {
  height: 60px;
  line-height: 60px;
  border-radius: 30px;
  padding: 0 33px;
}
.call-to-action.action-style2 .btn.icon-right > i, .call-to-action.action-style2 .btn.icon-left > i {
  line-height: 60px;
}

/* call to action style 2 */
.calltoaction-area-2 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.calltoaction-area-2 .xs-overlay {
  background-color: #4243e7;
  opacity: .9;
}
.calltoaction-area-2.version-gradient .xs-overlay {
  background: #3154fb;
  background: -webkit-gradient(linear, left top, right top, color-stop(18%, #3154fb), to(#8de4e8));
  background: -webkit-linear-gradient(left, #3154fb 18%, #8de4e8 100%);
  background: -o-linear-gradient(left, #3154fb 18%, #8de4e8 100%);
  background: linear-gradient(90deg, #3154fb 18%, #8de4e8 100%);
}

.call-to-action-wraper {
  position: relative;
  padding: 100px 0;
}

/* call to action 4 */
.calltoaction-area-4.delighter {
  padding: 0 80px;
  -webkit-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
}
.calltoaction-area-4.delighter.started {
  overflow: hidden;
  padding: 20px 0px;
}
.calltoaction-area-4.delighter.started .calltoaction-info {
  margin: 0 auto 0 auto;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  background-color: #4843d2;
  max-width: 100%;
}
.calltoaction-area-4 .calltoaction-info {
  -webkit-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
  background-color: #7447ff;
  max-width: 100%;
  padding: 100px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-blend-mode: color-burn;
}

.call-to-action.action-style3 .content-title {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 37px;
}
.call-to-action.action-style3 .btn {
  padding: 0 36px;
  height: 65px;
  line-height: 65px;
}
.call-to-action.action-style3 .btn:hover {
  background-color: #7447ff;
}
.call-to-action.action-style3 .btn[class*=icon-] > i {
  line-height: 58px;
}

/*=================================================
          49. testimonials
===============================================*/
.review-area {
  background-repeat: no-repeat;
  background-position: top 100px center;
}
.review-area .main-title {
  margin-bottom: 0px;
}
.review-area.xs-section-padding {
  padding-bottom: 60px;
  padding-top: 80px;
}

.single-review-thumb > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  margin-left: auto;
  margin-right: auto;
}

.owl-item.current .single-review-thumb > img {
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
}

.review-slider-thumb {
  width: calc(100% - 300px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.review-slider-thumb .owl-stage-outer {
  padding: 28px 0;
}

.review-content.review-style2 .star-rating-wraper {
  margin-bottom: 12px;
}
.review-content.review-style2 > p {
  color: #1e1e1e;
  font-size: 1.125rem;
  font-family: "Playfair Display", serif;
  margin-bottom: 24px;
}
.review-content.review-style2 .reviewer-bio h5 {
  color: #1e1e1e;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
}
.review-content.review-style2 .reviewer-bio p {
  margin-bottom: 0px;
  color: #9a9a9a;
  letter-spacing: 1.5px;
}
.review-content hr {
  width: 250px;
  display: block;
  border-color: #e7e7e7;
  margin-top: 0px;
  margin-bottom: 30px;
}
.review-content.text-center hr {
  margin-left: auto;
  margin-right: auto;
}

/* testimonials */
.agency-testimonial-area2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bouble-slider-privew {
  padding-left: 30px;
}
.bouble-slider-privew .owl-nav {
  margin-top: 50px;
}
.bouble-slider-privew .owl-nav button:not(:last-child) {
  margin-right: 15px;
}
.bouble-slider-privew .owl-nav button.owl-next, .bouble-slider-privew .owl-nav button.owl-prev {
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  background-color: #FFFFFF;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 50%;
  color: #b5b6d1;
}
.bouble-slider-privew .owl-nav button.owl-next:hover, .bouble-slider-privew .owl-nav button.owl-prev:hover {
  color: #103dbe;
  -webkit-box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
}

.single-bouble-thumb {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  overflow: hidden;
  border: 10px solid transparent;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-bouble-thumb.thumb-1 {
  width: 120px;
  height: 120px;
}
.single-bouble-thumb.thumb-2 {
  width: 90px;
  height: 90px;
}
.single-bouble-thumb.thumb-3 {
  width: 66px;
  height: 66px;
}
.single-bouble-thumb.thumb-4 {
  width: 50px;
  height: 50px;
}

.bouble-slider-thumb .owl-item:nth-child(1) {
  -webkit-transform: translateX(345px) translateY(60px);
  -ms-transform: translateX(345px) translateY(60px);
  transform: translateX(345px) translateY(60px);
}
.bouble-slider-thumb .owl-item:nth-child(2) {
  -webkit-transform: translateX(44px) translateY(220px);
  -ms-transform: translateX(44px) translateY(220px);
  transform: translateX(44px) translateY(220px);
}
.bouble-slider-thumb .owl-item:nth-child(3) {
  -webkit-transform: translateX(-144px) translateY(60px);
  -ms-transform: translateX(-144px) translateY(60px);
  transform: translateX(-144px) translateY(60px);
}
.bouble-slider-thumb .owl-item:nth-child(4) {
  -webkit-transform: translateX(-300px) translateY(220px);
  -ms-transform: translateX(-300px) translateY(220px);
  transform: translateX(-300px) translateY(220px);
}
.bouble-slider-thumb .owl-item:nth-child(5) {
  -webkit-transform: translateX(-430px) translateY(114px);
  -ms-transform: translateX(-430px) translateY(114px);
  transform: translateX(-430px) translateY(114px);
}
.bouble-slider-thumb .current .single-bouble-thumb {
  border-color: #FFFFFF;
  -webkit-box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.12);
}

.review-content.review-style3 .review-content-header {
  margin-bottom: 24px;
}
.review-content.review-style3 > p {
  font-style: normal;
  font-size: 1rem;
}
.review-content.review-style3 .reviewer-bio h3 {
  font-size: 1.75rem;
  color: #103dbe;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.review-content.review-style3 .reviewer-bio p {
  color: #afafaf;
  font-size: 1rem;
}

/*=================================================
          50. get subscribe
===============================================*/
.get-subscribenow-content .section-title {
  margin-bottom: 15px;
}
.get-subscribenow-content p {
  color: #1e1e1e;
  margin-bottom: 32px;
}
.get-subscribenow-content .btn.icon-left > i {
  padding-right: 8px;
}

/*=================================================
          51. deserve it
===============================================*/
.deserve-it-area {
  padding-top: 70px;
  padding-bottom: 130px;
}

.deserve-summary-content {
  color: #FFFFFF;
  z-index: 3;
  position: relative;
}
.deserve-summary-content .section-title {
  color: #FFFFFF;
  font-size: 2.625rem;
  margin-bottom: 22px;
}
.deserve-summary-content p {
  margin-bottom: 28px;
}

.have-any-project-section.delighter .background-wave-shape {
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 0;
  -webkit-transition: opacity .5s ease, -webkit-transform .4s ease;
  transition: opacity .5s ease, -webkit-transform .4s ease;
  -o-transition: transform .4s ease, opacity .5s ease;
  transition: transform .4s ease, opacity .5s ease;
  transition: transform .4s ease, opacity .5s ease, -webkit-transform .4s ease;
}
.have-any-project-section.delighter .pillow-image {
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  opacity: 0;
  -webkit-transition: opacity .7s ease, -webkit-transform .6s ease;
  transition: opacity .7s ease, -webkit-transform .6s ease;
  -o-transition: transform .6s ease, opacity .7s ease;
  transition: transform .6s ease, opacity .7s ease;
  transition: transform .6s ease, opacity .7s ease, -webkit-transform .6s ease;
}
.have-any-project-section.delighter.started {
  overflow: visible;
}
.have-any-project-section.delighter.started .background-wave-shape {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  opacity: .03;
}
.have-any-project-section.delighter.started .pillow-image {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  opacity: 1;
}

/*=================================================
          52. we offer
===============================================*/
.we-offer-wraper {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  padding: 0 20px;
}
.we-offer-wraper [class*=col-]:not(:last-child) .single-we-offer::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  height: 100px;
  width: 2px;
  background-color: rgba(77, 185, 236, 0.1);
}

.single-we-offer {
  padding: 57px 50px;
  position: relative;
}

.we-offer-icon-wraper {
  padding-right: 25px;
}

.we-offer-icon {
  display: inline-block;
  background: #c249f2;
  background: -webkit-linear-gradient(25deg, #c249f2 0%, #6371fb 100%);
  background: -o-linear-gradient(25deg, #c249f2 0%, #6371fb 100%);
  background: linear-gradient(65deg, #c249f2 0%, #6371fb 100%);
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 2.5rem;
  border-radius: 50%;
  color: #FFFFFF;
}

.deserve-it-area + .we-offer-area {
  margin-top: -72px;
  position: relative;
  z-index: 5;
}
.deserve-it-area + .we-offer-area + .more-features-area {
  margin-top: -130px;
  padding-top: 210px;
}

/*=================================================
          53. more feature
===============================================*/
.single-more-feauture {
  padding: 0 30px;
  margin-bottom: 50px;
}
.single-more-feauture .more-feature-header {
  -webkit-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.07);
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 23px;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transition: -webkit-transform .6s ease-in-out;
  transition: -webkit-transform .6s ease-in-out;
  -o-transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
}
.single-more-feauture .more-feature-header > i {
  font-size: 2.1875rem;
  color: #ffbc5c;
}
.single-more-feauture.color-1 .more-feature-header > i {
  color: #1edee4;
}
.single-more-feauture.color-2 .more-feature-header > i {
  color: #255cff;
}
.single-more-feauture:hover .more-feature-header {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
}
.single-more-feauture.text-center .more-feature-header {
  margin-left: auto;
  margin-right: auto;
}
.single-more-feauture.text-right .more-feature-header {
  margin-left: auto;
}
.single-more-feauture .feature-title {
  font-size: 1.125rem;
  margin-bottom: 15px;
}

.agency-banner7 + .more-features-2-section {
  padding-top: 150px;
}

.more-features-2-section.xs-section-padding {
  padding-bottom: 70px;
}
.more-features-2-section .agency-section-title .main-title {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  line-height: 1.425;
}
.more-features-2-section .agency-section-title .main-title > span {
  font-family: inherit;
  color: #1edee4;
}

.more-feature-style2 .single-more-feauture {
  padding-left: 0;
  padding-right: 50px;
}
.more-feature-style2 .single-more-feauture:hover .more-feature-header {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.more-feature-style2 .single-more-feauture .more-feature-header {
  width: auto;
  height: auto;
  line-height: unset;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
  text-align: left;
  margin-bottom: 10px;
}
.more-feature-style2 .single-more-feauture .feature-title {
  font-size: 1.375rem;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  margin-bottom: 20px;
}

/*=================================================
          54. bussiness agenda
===============================================*/
.business-agenda-list {
  padding-left: 65px;
}

.single-business-agenda:not(:last-child) {
  margin-bottom: 50px;
}
.single-business-agenda .business-agenda-img {
  padding-right: 20px;
}
.single-business-agenda .agenda-title {
  font-size: 1.125rem;
  margin-bottom: 13px;
}

/*=================================================
          55. best quote
===============================================*/
.best-quote-area {
  background-color: #fd4a7b;
  position: relative;
}
.best-quote-area .quote-img {
  position: absolute;
  top: -29px;
  left: 0;
}

.best-quote-content .best-quote-title {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}
.best-quote-content p {
  color: #FFFFFF;
}

.dash-title {
  font-size: 1.125rem;
  font-weight: 500;
}
.dash-title::before {
  content: "";
  height: 2px;
  width: 70px;
  background-color: rgba(255, 255, 255, 0.4);
  display: inline-block;
  vertical-align: middle;
  margin-right: 11px;
}

.quote-slider .owl-nav {
  line-height: 1;
}
.quote-slider .owl-nav button.owl-next, .quote-slider .owl-nav button.owl-prev {
  color: #FFFFFF;
  font-size: 1.25rem;
}
.quote-slider .owl-nav button.owl-prev {
  margin-right: 15px;
}

/*=================================================
          56. about
===============================================*/
.about-agencifi-area {
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 100px;
}

.about-agency-title {
  font-size: 2.625rem;
  margin-bottom: 22px;
}
.about-agency-title span {
  color: #1868dd;
  font-family: inherit;
}

.about-agency-cotnent p {
  color: #000000;
  margin-bottom: 20px;
}
.about-agency-cotnent .social-list {
  margin-bottom: 20px;
}
.about-agency-cotnent .social-list li a {
  font-size: 1.125rem;
  width: 60px;
  height: 60px;
  line-height: 60px;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
}

/* about version 2 */
.about-v2-section.xs-section-padding {
  padding-bottom: 98px;
}

.about-skroll-images {
  position: relative;
  height: 100%;
  min-height: 500px;
}
.about-skroll-images > .about-skroll-image {
  position: absolute;
  top: 0;
  left: 0;
}
.about-skroll-images > .about-skroll-image:not(.image-4) {
  -webkit-box-shadow: 0px 15px 65px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 15px 65px 0px rgba(0, 0, 0, 0.15);
}
.about-skroll-images > .about-skroll-image.image-2 {
  left: 190px;
  top: 115px;
}
.about-skroll-images > .about-skroll-image.image-3 {
  top: 46px;
  left: inherit;
  right: 0;
}
.about-skroll-images > .about-skroll-image.image-4 {
  top: 355px;
}
.about-skroll-images .quality-info {
  position: absolute;
  top: 324px;
  left: 60px;
  z-index: 9;
}

.quality-info {
  -webkit-box-shadow: 0px 15px 65px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 15px 65px 0px rgba(0, 0, 0, 0.15);
  background-color: #7447ff;
  padding: 48px 115px 20px 30px;
}
.quality-info > h2 {
  font-size: 1.375rem;
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
  font-weight: 900;
  margin-bottom: 0px;
}
.quality-info > .separete-border {
  height: 2px;
  display: block;
  width: 20px;
  background-color: #FFFFFF;
  margin-bottom: 14px;
}

.about-summary-content2 {
  padding-right: 50px;
}
.about-summary-content2 .about-title {
  font-size: 2.25rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  line-height: 1.333;
  margin-bottom: 15px;
}
.about-summary-content2 .about-title > span {
  color: #522cea;
  font-family: inherit;
}
.about-summary-content2 > p {
  color: #a3a3a3;
  margin-bottom: 25px;
}
.about-summary-content2 > p > a {
  color: #7447ff;
  border-bottom: 1px solid #c9bdff;
}

/*=================================================
          57. horizontal timeline
===============================================*/
.hr-timeline-group {
  counter-reset: myNumber;
}
.hr-timeline-group [class^="col-"]:nth-of-type(2) .hr-single-timeline .hr-timeline-content-wraper, .hr-timeline-group [class^="col-"]:nth-of-type(3) .hr-single-timeline .hr-timeline-content-wraper {
  margin-left: auto;
  margin-right: auto;
}
.hr-timeline-group [class^="col-"]:nth-of-type(2) .hr-single-timeline .number-count, .hr-timeline-group [class^="col-"]:nth-of-type(3) .hr-single-timeline .number-count {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.hr-timeline-group [class^="col-"]:last-child .hr-single-timeline .hr-timeline-content-wraper {
  margin-left: auto;
}
.hr-timeline-group [class^="col-"]:last-child .hr-single-timeline .number-count {
  left: inherit;
  right: 0;
}
.hr-timeline-group [class^="col-"]:nth-of-type(3) .hr-single-timeline {
  margin-top: -60px;
}
.hr-timeline-group [class^="col-"]:nth-child(even) .hr-single-timeline {
  top: 60px;
}
.hr-timeline-group [class^="col-"]:first-child .hr-single-timeline {
  margin-top: -40px;
}

.hr-single-timeline {
  position: relative;
  z-index: 1;
}
.hr-single-timeline:hover .hr-timeline-content-wraper {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
}
.hr-single-timeline .number-count {
  position: absolute;
  bottom: calc(100% - 45px);
  left: 0;
  z-index: -1;
}
.hr-single-timeline.color-1 .number-count,
.hr-single-timeline.color-1 .hr-timeline-content > i {
  color: #4246e8;
}
.hr-single-timeline.color-2 .number-count,
.hr-single-timeline.color-2 .hr-timeline-content > i {
  color: #7ad9fd;
}
.hr-single-timeline.color-3 .number-count,
.hr-single-timeline.color-3 .hr-timeline-content > i {
  color: #ff5382;
}

.number-count {
  font-size: 7.1875rem;
  font-weight: 700;
  color: #f5a802;
  display: block;
  line-height: 1;
  opacity: .2;
}
.number-count::before {
  counter-increment: myNumber;
  content: "0" counter(myNumber);
}

.hr-timeline-content-wraper {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: -webkit-transform .6s ease-in-out;
  transition: -webkit-transform .6s ease-in-out;
  -o-transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.hr-timeline-content-wraper .hr-timeline-content {
  width: 100%;
  color: #a1a1a1;
}
.hr-timeline-content-wraper .hr-timeline-content > i {
  color: #f5a802;
  font-size: 2.5rem;
  display: block;
  margin-bottom: 5px;
}

.hr-timeline-section {
  position: relative;
}
.hr-timeline-section .timeline-wave {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}

/*=================================================
          58. get benifits
===============================================*/
.get-benifits-img {
  margin-right: 50px;
  margin-left: -100px;
}

.get-benifits-summary {
  padding-top: 40px;
}
.get-benifits-summary .get-benifit-title {
  font-size: 2.625rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 19px;
}
.get-benifits-summary .get-benifit-title span {
  font-family: inherit;
  color: #738ee4;
}
.get-benifits-summary > p {
  color: #000000;
  margin-bottom: 15px;
}
.get-benifits-summary > p span {
  color: #738ee4;
}
.get-benifits-summary .xs-list {
  margin-bottom: 30px;
}
.get-benifits-summary .xs-list > li:not(:last-child) {
  margin-bottom: 3px;
}
.get-benifits-summary .xs-list > li::before {
  color: #ff5382;
}
.get-benifits-summary .btn {
  height: 60px;
  line-height: 60px;
  padding: 0 38px;
  border-radius: 30px;
}
.get-benifits-summary .btn.icon-right > i, .get-benifits-summary .btn.icon-left > i {
  line-height: 60px;
}

/*=================================================
          59. awards
===============================================*/
.awards-fitler-group {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 100px;
}
.awards-fitler-group .agency-section-title {
  margin-bottom: 55px;
}

.single-award {
  position: relative;
}
.single-award:hover {
  z-index: 4;
}
.single-award:hover .award-content {
  background-color: #f14b59;
  -webkit-box-shadow: 0px 25px 50px 0px rgba(241, 75, 89, 0.8);
  box-shadow: 0px 25px 50px 0px rgba(241, 75, 89, 0.8);
}
.single-award:hover .award-content > img.image-hover {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.single-award:hover .award-content > img.imgae-default {
  opacity: 0;
  -webkit-transform: translate(-50%, -180%);
  -ms-transform: translate(-50%, -180%);
  transform: translate(-50%, -180%);
}
.single-award:hover .corner-border {
  -webkit-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
  opacity: 0;
}
.single-award .award-content {
  text-align: center;
  height: 270px;
  line-height: 270px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-award .award-content > img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.single-award .award-content > img.image-hover {
  opacity: 0;
  -webkit-transform: translate(-50%, 80%);
  -ms-transform: translate(-50%, 80%);
  transform: translate(-50%, 80%);
}
.single-award .corner-border {
  position: absolute;
  display: block;
  z-index: 2;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .4s ease-in-out, -webkit-transform .3s ease-in-out;
  transition: opacity .4s ease-in-out, -webkit-transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out, opacity .4s ease-in-out;
  transition: transform .3s ease-in-out, opacity .4s ease-in-out;
  transition: transform .3s ease-in-out, opacity .4s ease-in-out, -webkit-transform .3s ease-in-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.single-award .corner-border.border-1::before, .single-award .corner-border.border-1::after, .single-award .corner-border.border-2::before, .single-award .corner-border.border-2::after {
  position: absolute;
  content: "";
  color: #f7f7f7;
}
.single-award .corner-border.border-1::before {
  left: calc(100% - 50px);
  bottom: calc(100% - 50px);
  top: 0;
  right: 0;
  border-top: 1px solid currentColor;
  border-right: 1px solid currentColor;
}
.single-award .corner-border.border-1::after {
  right: calc(100% - 50px);
  bottom: calc(100% - 50px);
  top: 0;
  left: 0;
  border-top: 1px solid currentColor;
  border-left: 1px solid currentColor;
}
.single-award .corner-border.border-2::before {
  top: calc(100% - 50px);
  left: calc(100% - 50px);
  right: 0;
  bottom: 0;
  border-right: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
}
.single-award .corner-border.border-2::after {
  right: calc(100% - 50px);
  top: calc(100% - 50px);
  left: 0;
  bottom: 0;
  border-bottom: 1px solid currentColor;
  border-left: 1px solid currentColor;
}

.awards-card-group {
  width: calc(100% - 65px);
}
.awards-card-group > [class^="col-"]:nth-child(even) .single-award {
  margin-left: -1px;
}
.awards-card-group > [class^="col-"]:nth-child(even):not(:nth-child(2)) .single-award {
  margin-top: -1px;
}
.awards-card-group > [class^="col-"]:nth-child(odd) .single-award {
  margin-left: -1px;
}
.awards-card-group > [class^="col-"]:nth-child(odd):not(:nth-child(1)) .single-award {
  margin-top: -1px;
}

/*=================================================
          60. growth card
===============================================*/
.growth-card-section {
  position: relative;
}
.growth-card-section .growth-card-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.growth-card-section > .container {
  position: relative;
  z-index: 2;
}
.growth-card-section .simple-btn {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: -.4px;
}

/* info card */
.info-card {
  border-radius: 10px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 60px 35px;
}
.info-card:hover {
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}
.info-card:hover .info-card-header > img {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
}
.info-card .info-card-header {
  margin-bottom: 20px;
}
.info-card .info-card-header > img {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.info-card .info-card-body .card-title {
  color: #103dbe;
  font-size: 1.375rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  letter-spacing: -.2px;
}
.info-card .info-card-body p {
  color: #a4a4a4;
}

/*=================================================
          61. creative protfolio
===============================================*/
.header-transparent + .creative-portfolio-section,
.sticky-header + .creative-portfolio-section {
  margin-top: 111px;
}

.creative-portfolio-section {
  padding: 140px 0;
}

.portfolio-grid {
  margin-left: -15px;
  margin-right: -15px;
}

.portfolio-gallery {
  display: block;
  position: relative;
  margin: 15px;
  overflow: hidden;
}
.portfolio-gallery:hover .entry_caption .title, .portfolio-gallery:hover .entry_caption .cat {
  opacity: 1;
  visibility: visible;
  top: 0px;
}
.portfolio-gallery:hover .entry__overlay {
  opacity: 1;
}
.portfolio-gallery:hover > img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.portfolio-gallery > img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.portfolio-gallery .entry__overlay {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 5;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.portfolio-gallery .entry_caption {
  padding: 30px;
}
.portfolio-gallery .entry_caption .title, .portfolio-gallery .entry_caption .cat {
  font-size: 1.25rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  position: relative;
  margin-bottom: 0px;
  top: -20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.portfolio-gallery .entry_caption .cat {
  font-size: 0.875rem;
  top: 20px;
}
.portfolio-gallery .line {
  position: absolute;
  bottom: 30px;
  left: 30px;
  height: 2px;
  width: 20px;
  background-color: #000000;
}

.hidden {
  display: none;
}

.loadmore-btn {
  color: #1868dd;
  font-size: 1.375rem;
  letter-spacing: .3px;
}
.loadmore-btn > i {
  padding-right: 15px;
  font-size: 1.125rem;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.loadmore-btn:hover {
  color: #103dbe;
}
.loadmore-btn:hover > i {
  padding-right: 10px;
}

.portfolio-grid-item.portfolio-grid-item-w2 {
  max-width: 100%;
}

/*=================================================
          62. video testimonial
===============================================*/
.video-testimonial-wraper {
  padding-bottom: 190px;
}

.video-testimonial-area {
  background-color: #e8fcfc;
  padding-top: 90px;
  padding-bottom: 230px;
}
.video-testimonial-area + .testimonial-video-section {
  margin-top: -178px;
  position: relative;
  z-index: 2;
}

.client-best-comments .best-comment-title {
  font-family: "Roboto", sans-serif;
  font-size: 2.25rem;
  letter-spacing: .3px;
  font-weight: 500;
  line-height: 1.3888;
  margin-bottom: 15px;
}
.client-best-comments p {
  color: #000000;
  font-size: 1.125rem;
}

/*=================================================
          63. office info
===============================================*/
.office-location-section {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  padding: 280px 0;
}

.office-banner-content-outer {
  background-color: rgba(24, 104, 221, 0.9);
  padding: 73px 60px;
}
.office-banner-content-outer .office-banner-title {
  color: #FFFFFF;
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}
.office-banner-content-outer .btn {
  font-size: 1.125rem;
  font-weight: 400;
  height: 60px;
  line-height: 60px;
  padding: 0 36px;
}
.office-banner-content-outer .office-banner-content-inner {
  padding-right: 80px;
}

.office-locations-list {
  margin-top: 138px;
  margin-left: -160px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 80px 60px;
}
.office-locations-list .agency-section-title.style6 {
  margin-bottom: 14px;
}
.office-locations-list .agency-section-title.style6 .main-title {
  margin-bottom: 0;
}
.office-locations-list .xs-list > li > a {
  font-size: 1.125rem;
}
.office-locations-list .xs-list.round > li::before {
  background-color: #c4d2fe;
  margin-top: 10px;
}

/*=================================================
          64. feature step
===============================================*/
.feature-steps {
  padding-left: 36px;
  position: relative;
  z-index: 2;
}
.feature-steps .feature-step {
  -webkit-box-shadow: 0px 15px 65px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 15px 65px 0px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  width: 100%;
  max-width: 271px;
  padding: 30px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.feature-steps .feature-step:not(:last-child) {
  margin-bottom: 20px;
}
.feature-steps .feature-step:nth-child(3) {
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}
.feature-steps .feature-step .feature-step-content {
  float: left;
}
.feature-steps .feature-step .feature-step-content .feature-step-title {
  font-size: 1.125rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}
.feature-steps .feature-step .feature-step-content .feature-step-des {
  font-size: 0.75rem;
  color: #a3a3a3;
}
.feature-steps .feature-step .feature-step-img {
  float: right;
}

.step-by-steps-feature {
  position: relative;
}
.step-by-steps-feature .feature-step-bg {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 34px;
  margin-right: -144px;
  z-index: -1;
}

.rise-feature-section.delighter.started .feature-steps .feature-step:nth-child(1) {
  -webkit-transform: translateX(64px);
  -ms-transform: translateX(64px);
  transform: translateX(64px);
}
.rise-feature-section.delighter.started .feature-steps .feature-step:nth-child(2) {
  -webkit-transform: translateX(114px);
  -ms-transform: translateX(114px);
  transform: translateX(114px);
}
.rise-feature-section.delighter.started .feature-steps .feature-step:nth-child(3) {
  -webkit-transform: translateX(165px);
  -ms-transform: translateX(165px);
  transform: translateX(165px);
}
.rise-feature-section.xs-section-padding {
  padding-top: 98px;
  padding-bottom: 216px;
}

/*=================================================
          65. customer satisfiction
===============================================*/
.customer-satisfaction-wraper .satisfaction-title {
  padding-right: 40px;
  margin-bottom: 50px;
}

.satisfaction-title {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
}
.satisfaction-title > span {
  color: #7447ff;
  font-family: inherit;
}

.satisfiction-group {
  counter-reset: myNumber;
}

.customer-satisfaction-list {
  padding-right: 30px;
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}
.customer-satisfaction-list .number-count {
  position: absolute;
  top: 70px;
  left: calc(0% - 78px);
  z-index: -1;
  font-size: 5.625rem;
  color: #000000;
  opacity: .05;
}
.customer-satisfaction-list > i {
  font-size: 3.75rem;
  display: block;
  margin-bottom: 25px;
  color: #000000;
}
.customer-satisfaction-list .xs-title {
  font-family: "Roboto", sans-serif;
  font-size: 1.375rem;
  margin-bottom: 18px;
}
.customer-satisfaction-list p {
  margin-bottom: 0px;
}

.customer-satisfiction.xs-section-padding {
  padding-bottom: 60px;
}

/*=================================================
          66. vertical slider
===============================================*/
.client-says-area {
  position: relative;
  z-index: 1;
  background-color: #7447ff;
}
/* .client-says-area::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/client-slider-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  opacity: .1;
} */
.client-says-area > .container {
  position: relative;
}

.vertical-slider {
  height: 450px;
}
.vertical-slider .swiper-slide {
  overflow: hidden;
  padding: 0 30px;
}

.client-comments {
  padding-top: 99px;
  padding-bottom: 99px;
  padding-right: 40px;
}
.client-comments .client-massage {
  margin-bottom: 55px;
}
.client-comments .client-massage > p {
  font-size: 1rem;
  color: #FFFFFF;
  margin-bottom: 0px;
}

.client-info .client-avatar {
  position: relative;
  width: 120px;
  height: 120px;
  float: left;
}
.client-info .client-avatar > img {
  border-radius: 50%;
}
.client-info .client-avatar .client-sign {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 80px;
  height: 80px;
  line-height: 60px;
  background-color: #FFFFFF;
  border-radius: 50%;
  border: 10px solid #7447ff;
  text-align: center;
}
.client-info .client-bio {
  overflow: hidden;
  position: relative;
  top: 30px;
  padding-left: 30px;
}
.client-info .client-bio .client-name {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 500;
  margin-bottom: 2px;
}
.client-info .client-bio .designation {
  font-size: 0.8125rem;
  color: #c2b3ff;
}

.client-title-wraper {
  padding-top: 90px;
}
.client-title-wraper .client-title {
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
  font-size: 3rem;
  font-weight: 700;
}
.client-title-wraper .content-separete-border {
  background-color: #FFFFFF;
}

/*=================================================
          67. icon menu
===============================================*/
.xs-icon-menu {
  border-radius: 0px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
  box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
  border-top: 0px solid #000;
}
.xs-icon-menu .single-menu-item a {
  padding-left: 20px;
  padding-right: 30px;
  display: block;
  color: #141414;
  font-size: 0.9375rem;
}
.xs-icon-menu .single-menu-item a:hover {
  color: #1868dd;
}
.xs-icon-menu .single-menu-item a i {
  font-size: 0.9375rem;
  position: relative;
  top: 1px;
  padding-right: 18px;
}
.xs-icon-menu .single-menu-item:not(:last-child) a {
  padding-bottom: 12px;
  border-bottom: 1px solid #f7f7f7;
}
.xs-icon-menu .single-menu-item:not(:first-child) a {
  padding-top: 12px;
}
.xs-icon-menu .single-menu-item.active > a {
  color: #1868dd;
}

.xs-hidden-menus .xs-icon-menu, .xs-hidden-menus .home-menu-panel {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.megamenu-panel {
  padding: 35px 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
  box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
}
.megamenu-panel.home-menu-panel {
  padding: 20px;
}
.megamenu-panel .xs-icon-menu {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: inherit;
}
.megamenu-panel .xs-icon-menu .single-menu-item:not(:first-child) a {
  padding-top: 20px;
}
.megamenu-panel .xs-icon-menu .single-menu-item:not(:last-child) a {
  padding-bottom: 20px;
}

/*=================================================
          68. portfolio banner
===============================================*/
.banner-portfolio {
  min-height: 905px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
}
.banner-portfolio > .container > .row {
  height: 100%;
}
.banner-portfolio .agency-banner-content {
  position: relative;
}
.banner-portfolio .agency-banner-content .banner-sub-title {
  font-size: 1.375rem;
  letter-spacing: 0.2rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
}
.banner-portfolio .agency-banner-content .banner-title {
  font-family: 'signatra_demoregular';
  font-weight: 400;
  color: #333333;
  font-size: 6.375rem;
  line-height: 1;
  margin-bottom: 0;
}
.banner-portfolio .agency-banner-content .banner-desc {
  color: #000000;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 30px;
}
.banner-portfolio .agency-banner-content .separete-border {
  display: inline-block;
  height: 5px;
  width: 50px;
  background-color: #000000;
}
.banner-portfolio .agency-banner-content .banner-paint {
  mix-blend-mode: luminosity;
  opacity: .1;
  position: absolute;
  left: -165px;
  top: -40px;
}
.banner-portfolio .agency-banner-img {
  text-align: right;
  position: relative;
}
.banner-portfolio .agency-banner-img > img {
  width: 100%;
}
.banner-portfolio .agency-banner-img .banner-paint {
  position: absolute;
  left: -230px;
  top: 456px;
  mix-blend-mode: color-burn;
}
.banner-portfolio .social-sidebar {
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*=================================================
          69. portfolio aboout
===============================================*/
.portfolio-about {
  position: relative;
  z-index: 1;
}
.portfolio-about .shuffle-letter-title-wraper {
  top: -11px;
  left: -20px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  z-index: -1;
}
.portfolio-about .shuffle-letter-title-wraper .shuufle-letter-title {
  font-size: 5.625rem;
  color: #f3f3f3;
}
.portfolio-about .subtitle {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: .2rem;
}
.portfolio-about .title {
  font-size: 2.25rem;
  margin-bottom: 0px;
}

.portfolio-text > p:not(:last-child) {
  margin-bottom: 5px;
}

/*=================================================
          70. service boxed
===============================================*/
.service-boxed {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 60px 30px;
}
.service-boxed .service-img {
  margin-bottom: 32px;
}
.service-boxed .xs-title {
  font-size: 1.25rem;
  margin-bottom: 32px;
}
.service-boxed:hover, .service-boxed.active {
  -webkit-box-shadow: 29.698px 29.698px 60px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 29.698px 29.698px 60px 0px rgba(0, 0, 0, 0.2);
}

/*=================================================
          71. blog post
===============================================*/
.blog-block-post {
  padding: 50px;
  background-color: #FFFFFF;
}
.blog-block-post.radius {
  border-radius: 10px;
}
.blog-block-post.radius .entry-thumb {
  border-radius: 10px;
}
.blog-block-post .entry-thumb {
  position: relative;
  overflow: hidden;
  display: block;
}
.blog-block-post .entry-thumb > img {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-block-post > .row [class^="col-"]:last-child .post-body {
  padding-left: 60px;
}
.blog-block-post > .row [class^="col-"]:first-child .post-body {
  padding-right: 60px;
}
.blog-block-post .post-body {
  padding-top: 85px;
}
.blog-block-post .entry-title {
  font-size: 2.25rem;
  font-weight: 900;
  margin-bottom: 0px;
}
.blog-block-post .entry-title a {
  color: #000000;
}
.blog-block-post .entry-title a:hover {
  color: #1868dd;
}
.blog-block-post .entry-title + .post-meta-list {
  margin-bottom: 0;
  margin-top: 35px;
}
.blog-block-post .entry-thumb {
  -webkit-box-shadow: 29.698px 29.698px 60px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 29.698px 29.698px 60px 0px rgba(0, 0, 0, 0.2);
}
.blog-block-post .entry-thumb > img {
  width: 100%;
}
.blog-block-post .entry-header {
  margin-bottom: 30px;
}
.blog-block-post .entry-header .post-meta-list {
  margin-bottom: 15px;
}
.blog-block-post .post-footer > p {
  color: #8d8d8d;
  margin-bottom: 35px;
}
.blog-block-post:hover .entry-thumb > img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  opacity: .8;
}
.blog-block-post.box-shadow {
  -webkit-box-shadow: 9.899px 9.899px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 9.899px 9.899px 30px 0px rgba(0, 0, 0, 0.1);
}
.blog-block-post .post-footer {
  margin-bottom: 0px;
}

/* blog post card */
.post-card {
  background-color: #FFFFFF;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 9.899px 9.899px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 9.899px 9.899px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 40px;
  border-radius: 6px;
}
.post-card .entry-header .post-meta-list {
  margin-bottom: 17px;
}
.post-card .entry-header .entry-title {
  font-size: 1.125rem;
  line-height: 1.333;
}
.post-card .entry-header .entry-title a {
  color: #000000;
}
.post-card .entry-header .entry-title a:hover {
  color: #1868dd;
}
.post-card .entry-header .border-hr {
  margin-top: 25px;
  margin-bottom: 22px;
}
.post-card .post-body {
  color: #8d8d8d;
}
.post-card:hover {
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}

.post-meta-list.meta-style2 {
  color: #a3a3a3;
}
.post-meta-list.meta-style2 > span {
  display: inline-block;
}
.post-meta-list.meta-style2 > span a {
  color: #a3a3a3;
}
.post-meta-list.meta-style2 > span a:hover {
  color: #1868dd;
}
.post-meta-list.meta-style2 > span i {
  color: inherit;
}
.post-meta-list.meta-style2 > span:not(:last-child) {
  margin-right: 40px;
}

.post-author.author-style2 .author-img {
  display: inline-block;
  margin-right: 15px;
}
.post-author.author-style2 .author-img > img {
  width: 30px;
  height: 30px;
  -webkit-box-shadow: 4.95px 4.95px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 4.95px 4.95px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.post-author.author-style2 .author-name {
  color: #000000;
}
.post-author.author-style2 .author-name:hover {
  opacity: .8;
}

.border-hr {
  height: 3px;
  width: 30px;
  display: block;
  background-color: #e5e5e5;
}

/*=================================================
          72. portfolio testimonial
===============================================*/
.portfolio-testimonial-area {
  padding-top: 48px;
}
.portfolio-testimonial-area .agency-section-title.style7 .shuffle-letter-title-wraper {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  top: -85px;
}

.portfolio-testimonial-wraper .star-lists {
  margin-bottom: 30px;
}
.portfolio-testimonial-wraper .star-lists > li:not(:last-child) {
  margin-right: 2px;
}

.portfolio-testimonial-slider {
  -webkit-box-shadow: 29.698px 29.698px 60px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 29.698px 29.698px 60px 0px rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
  position: relative;
  z-index: 4;
  padding-left: 80px;
  padding-right: 50px;
  height: calc(602px - 95px);
}
.portfolio-testimonial-slider .owl-stage-outer {
  top: -95px;
}

.portfolio-testimonial-content {
  padding-top: 95px;
}

.star-lists > li {
  color: #fec42d;
  font-size: 0.9375rem;
}

.portfolio-testimonial-details {
  margin-bottom: 40px;
}
.portfolio-testimonial-details > p {
  font-size: 1.125rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.7);
}

.portfolio-testimonial-info > img {
  margin-bottom: 10px;
}
.portfolio-testimonial-info > p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9375rem;
}

.single-portfolio-testimonial {
  position: relative;
}
.single-portfolio-testimonial .quote-icon {
  position: absolute;
  left: 50%;
  bottom: 0px;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.portfolio-testimonial-person > img {
  margin-left: auto;
}

.vertical-dot .owl-dots {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.vertical-dot .owl-dots .owl-dot {
  display: block;
}
.vertical-dot .owl-dots .owl-dot > span {
  background-color: rgba(0, 0, 0, 0.1);
}
.vertical-dot .owl-dots .owl-dot > span::before {
  background: #1351f4;
  background: -webkit-gradient(linear, left bottom, left top, from(#1351f4), to(#0727eb));
  background: -webkit-linear-gradient(bottom, #1351f4 0%, #0727eb 100%);
  background: -o-linear-gradient(bottom, #1351f4 0%, #0727eb 100%);
  background: linear-gradient(0deg, #1351f4 0%, #0727eb 100%);
  border: 0px;
  width: 16px;
  height: 16px;
  z-index: -1;
  top: -4px;
  left: -4px;
}
.vertical-dot .owl-dots .owl-dot.active > span {
  background-color: #FFFFFF;
}
.vertical-dot .owl-dots .owl-dot:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 12px;
}

/*=================================================== page element ===============================*/
/*=================================================
          1. btn element
===============================================*/
.btn:not([class*=btn-outline-]) {
  border: 0px;
  color: #FFFFFF;
  background-color: #000000;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
}

.btn {
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: .5px;
  border-radius: 1.71875rem;
  padding: 0 50px;
  height: 54px;
  line-height: 54px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  white-space: normal;
}
.btn.btn-secondary {
  background-color: #4285f4;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
  padding: 0 65px;
}
.btn.btn-secondary i {
  padding-left: 11px;
}
.btn.btn-secondary:hover {
  background-color: #0d5fe6;
}
.btn.btn-outline-primary {
  border-color: #e8e9eb;
  color: #1bd1ea;
}
.btn.btn-outline-primary i {
  padding-left: 5px;
  position: relative;
  top: 2px;
}
.btn.btn-primary {
  background-color: #1bd1ea;
}
.btn.btn-primary:hover {
  background-color: #109fb2;
}
.btn.style2 {
  background-color: #22c8e5;
}
.btn.style2:hover {
  background-color: #1599b0;
}
.btn.style2 > i {
  font-size: 1.125rem;
}
.btn.icon-right > i {
  float: right;
  line-height: 52px;
  padding-left: 19px;
}
.btn.icon-left > i {
  float: left;
  line-height: 52px;
  padding-right: 19px;
}
.btn.btn-outline-secondary {
  border-color: #22c8e5;
  color: #FFFFFF;
  border-width: 2px;
}
.btn.btn-outline-secondary:hover {
  background-color: #22c8e5;
}
.btn.style3 {
  background-color: #e36e91;
}
.btn.style3:hover {
  background-color: #d72e60;
}
.btn.style4 {
  background-color: #738ee4;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
}
.btn.style4:hover {
  background-color: #335ad8;
}
.btn.style5 {
  background-color: #1e348c;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
}
.btn.style5:hover {
  background-color: #152462;
}
.btn.style6 {
  background-color: rgba(255, 255, 255, 0.12);
  -webkit-box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.btn.style6:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000000;
}
.btn.style7 {
  -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
  background-color: #f5b961;
}
.btn.style7:hover {
  background-color: #f2a431;
}
.btn.style8 {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);
  color: #522cea;
  font-size: 0.875rem;
  font-weight: 500;
}
.btn.style8 > i {
  font-size: 1.375rem;
}
.btn.style8.icon-left > i {
  padding-right: 9px;
}
.btn.style8.icon-right > i {
  padding-left: 9px;
}
.btn.style8:hover {
  background-color: #522cea;
  color: #FFFFFF;
}
.btn.square {
  border-radius: 0px;
}

.btn-gradient {
  background-image: -webkit-linear-gradient(357deg, #0369d1 0%, #00ecbc 100%);
  background-image: -o-linear-gradient(357deg, #0369d1 0%, #00ecbc 100%);
  background-image: linear-gradient(93deg, #0369d1 0%, #00ecbc 100%);
}

.btn-gradient2 {
  background: -webkit-linear-gradient(351deg, #0369d1 0%, #00ecbc 60%);
  background: -o-linear-gradient(351deg, #0369d1 0%, #00ecbc 60%);
  background: linear-gradient(99deg, #0369d1 0%, #00ecbc 60%);
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  border-radius: 22.5px;
  padding: 0 20px;
  height: 45px;
  line-height: 45px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-size: 101% auto;
}
.btn-gradient2:hover {
  background-size: 200% auto;
  background-position: left center;
}
.btn-gradient2 i {
  padding-right: 7px;
}
.btn-gradient2 i + i {
  padding-left: 7px;
  padding-right: 0px;
}

.btn-gradient3 {
  background: #b224ef;
  background: -webkit-linear-gradient(331deg, #b224ef 11%, #5055fa 55%);
  background: -o-linear-gradient(331deg, #b224ef 11%, #5055fa 55%);
  background: linear-gradient(119deg, #b224ef 11%, #5055fa 55%);
  background-size: 101% auto;
}
.btn-gradient3:hover {
  background-size: 200% auto;
  background-position: left center;
}

.btn-gradient4 {
  background: #ff8f1e;
  background: -webkit-gradient(linear, left top, right top, from(#ff8f1e), color-stop(50%, #ff8f1e), to(#fe690b));
  background: -webkit-linear-gradient(left, #ff8f1e 0%, #ff8f1e 50%, #fe690b 100%);
  background: -o-linear-gradient(left, #ff8f1e 0%, #ff8f1e 50%, #fe690b 100%);
  background: linear-gradient(90deg, #ff8f1e 0%, #ff8f1e 50%, #fe690b 100%);
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
  background-size: 101% auto;
}
.btn-gradient4:hover {
  background-size: 200% auto;
  background-position: left center;
}

.underline-btn {
  position: relative;
  font-weight: 500;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 1;
  letter-spacing: .5px;
}
.underline-btn i {
  padding-left: 5px;
  position: relative;
  top: 1px;
}
.underline-btn:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: -6px;
  height: 8px;
  background-color: #ffe377;
  width: calc(100% - 15px);
  z-index: -1;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.underline-btn:hover {
  color: #000000;
}
.underline-btn:hover::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}

.btn-wraper .btn:not(:last-child) {
  margin-right: 18px;
}

/*=================================================
          2. section title element
===============================================*/
/* agency section title */
.agency-section-title {
  margin-bottom: 23px;
  position: relative;
  z-index: 1;
}
.agency-section-title:hover .border-animation > .first {
  -webkit-animation: move-1 1.8s infinite linear;
  animation: move-1 1.8s infinite linear;
  width: 80px;
}
.agency-section-title:hover .border-animation > .second {
  width: 0px;
  -webkit-animation: move-2 1.8s infinite linear;
  animation: move-2 1.8s infinite linear;
}
.agency-section-title .sub-title {
  font-size: 0.875rem;
  color: #1bd1ea;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.agency-section-title .main-title {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 0px;
}
.agency-section-title .main-title em {
  font-family: "Playfair Display", serif;
}
.agency-section-title .main-title.big {
  font-size: 5.625rem;
}
.agency-section-title .main-title.medium {
  font-size: 4rem;
}
.agency-section-title.text-center {
  margin-bottom: 80px;
}
.agency-section-title.text-center .watermark-title {
  top: -69px;
}
.agency-section-title.text-center .line {
  margin: 0 auto;
}
.agency-section-title.text-center.style5 {
  margin-bottom: 55px;
}
.agency-section-title.style2 {
  margin-bottom: 70px;
}
.agency-section-title.style2 .sub-title {
  color: #4285f4;
  font-size: 0.76rem;
}
.agency-section-title.style2 .main-title {
  font-size: 1.875rem;
  margin-bottom: 25px;
}
.agency-section-title.style2 .main-title em {
  color: #4285f4;
  font-weight: 900;
}
.agency-section-title.style2 p {
  padding-right: 65px;
}
.agency-section-title.style3 .main-title, .agency-section-title.style5 .main-title {
  color: #21069b;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
}
.agency-section-title.style4 .main-title {
  margin-bottom: 20px;
}
.agency-section-title.style4 p {
  margin-bottom: 15px;
}
.agency-section-title.style5 .main-title {
  margin-bottom: 20px;
}
.agency-section-title.style5 p {
  color: #000000;
  font-size: 1rem;
}
.agency-section-title p {
  color: #777777;
  font-size: 0.9375rem;
  margin-bottom: 0px;
}
.agency-section-title .line {
  display: block;
  width: 110px;
  height: 1px;
  background-color: #000000;
}
.agency-section-title.section-title-style2 .main-title {
  margin-bottom: 10px;
}
.agency-section-title.section-title-style2 p {
  font-size: 0.9375rem;
  color: #878787;
}
.agency-section-title .title-icon {
  margin-bottom: 12px;
}
.agency-section-title.style6 {
  margin-bottom: 40px;
}
.agency-section-title.style6 .sub-title {
  font-size: 1.375rem;
  color: #a3a3a3;
  font-weight: 400;
}
.agency-section-title.style6 .main-title {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  margin-bottom: 10px;
}
.agency-section-title.style6 .main-title span {
  color: #1edee4;
}
.agency-section-title.style7 {
  position: relative;
  z-index: 1;
}
.agency-section-title.style7 .sub-title {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: .2rem;
}
.agency-section-title.style7 .shuffle-letter-title-wraper {
  top: 70%;
}
.agency-section-title.style7 .shuufle-letter-title {
  font-size: 6.375rem;
  color: rgba(0, 0, 0, 0.07);
  z-index: -1;
}

/* gray background */
.gray-bg .agency-section-title .watermark-title {
  color: #f5f5f5;
}

/* section title style 3 */
.section-title-style3 {
  margin-bottom: 60px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}
.section-title-style3.row {
  margin-left: 0px;
  margin-right: 0px;
}
.section-title-style3::before {
  position: absolute;
  content: "";
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -1px;
  height: 1px;
  width: 110px;
  background-color: #000000;
}
.section-title-style3 .agency-section-title {
  margin-bottom: 20px;
}
.section-title-style3 .agency-section-title .main-title {
  margin-bottom: 20px;
}
.section-title-style3 .agency-section-title .main-title em {
  background: -webkit-linear-gradient(328deg, #0369d1 4%, #0369d1 25%, #00ecbc 97%);
  background: -o-linear-gradient(328deg, #0369d1 4%, #0369d1 25%, #00ecbc 97%);
  background: linear-gradient(122deg, #0369d1 4%, #0369d1 25%, #00ecbc 97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0369d1;
}

/* minimal section title */
.minimal-section-title {
  margin-bottom: 130px;
}
.minimal-section-title .sub-title, .minimal-section-title .main-title {
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
}
.minimal-section-title .sub-title {
  margin-bottom: 15px;
}
.minimal-section-title .main-title {
  font-size: 2.25rem;
  line-height: 1.389;
  letter-spacing: 2px;
  margin-bottom: 0px;
}

[data-scrollax-parent="true"] > .container > .row + .row {
  z-index: 2;
  position: relative;
}

/*=================================================
          3. list element
===============================================*/
.list-inline > li {
  display: inline-block;
}
.list-inline > li:not(:last-child) {
  margin-right: 10px;
}

.xs-list.round li {
  position: relative;
}
.xs-list.round li::after {
  display: block;
  clear: both;
  content: "";
}
.xs-list.round li::before {
  content: "";
  display: block;
  float: left;
  background-color: #1868dd;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 7px;
  margin-right: 10px;
}
.xs-list.check > li::before {
  content: "\ea75";
  font-family: "iconfont";
  font-size: 1rem;
  padding-right: 15px;
}

.breadcumbs > li {
  position: relative;
}
.breadcumbs > li > a {
  color: #ffffff;
}
.breadcumbs > li:not(:last-child) {
  margin-right: 0px;
}
.breadcumbs > li:not(:last-child)::after {
  content: "/";
  padding-left: 4px;
}

/* case info list */
.case-info-list > li {
  display: inline-block;
  text-align: center;
  color: #FFFFFF;
  font-family: "Work Sans", sans-serif;
  letter-spacing: -.1px;
}
.case-info-list > li:not(:last-child) {
  margin-right: 100px;
}
.case-info-list > li > i {
  display: block;
  font-size: 6.25rem;
  margin-bottom: 20px;
}
.case-info-list > li span {
  font-size: 1.25rem;
}

/* list group */
.list-group > li:not(:last-child) {
  margin-bottom: 10px;
}
.list-group > li strong {
  float: left;
  width: 43%;
  color: #000000;
  font-weight: 700;
}

/* order list */
.order-list {
  padding-left: 0px;
}
.order-list li {
  counter-increment: step-counter;
  color: #000000;
}
.order-list li:not(:last-child) {
  margin-bottom: 20px;
}
.order-list li::before {
  content: counter(step-counter);
  display: inline-block;
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  margin-right: 24px;
}
.order-list.style-border li {
  color: #777777;
  text-decoration: underline;
}
.order-list.style-border li::before {
  content: counter(step-counter) ".";
  width: auto;
  height: auto;
  line-height: normal;
  margin-right: 14px;
  border: 0px solid #000;
  color: #999999;
  margin-right: 5px;
}
.order-list.style-border li:not(:last-child) {
  margin-bottom: 8px;
}

/* star rating list */
.star-rating-list li:not(:last-child) {
  margin-right: 5px;
}
.star-rating-list li a {
  color: #fec42d;
}

/*=================================================
          4. video pop up element
===============================================*/
.xs-video-popup {
  display: inline-block;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: #000000;
  color: #FFFFFF;
  text-align: center;
  -webkit-box-shadow: 0px 15px 21px 0px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 15px 21px 0px rgba(0, 0, 0, 0.32);
  font-size: 1.125rem;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.xs-video-popup:hover {
  color: #FFFFFF;
}
.xs-video-popup i {
  margin-right: -4px;
}
.xs-video-popup.style-block {
  height: 60px;
  line-height: 60px;
  width: auto;
  -webkit-box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  font-weight: 500;
  padding: 0 40px;
}
.xs-video-popup.style-block > i {
  position: relative;
  top: 2px;
  padding-right: 10px;
  font-size: 1.375rem;
}

.video-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

/*=================================================
          5. insta feed element
===============================================*/
.insta-feed-wraper {
  width: calc(100% - 65px);
}

.insta-feed::after {
  display: block;
  clear: both;
  content: "";
}
.insta-feed li {
  width: calc(33.333333% - 8px);
  float: left;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
}
.insta-feed li:not(:last-child) {
  margin-right: 8px;
}
.insta-feed li img {
  border-radius: 3px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.insta-feed li a:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

/*=================================================
          6. newsletter element
===============================================*/
.newsletter-form {
  position: relative;
}
.newsletter-form input:not([type="submit"]) {
  background-color: white;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
  height: 45px;
  width: 100%;
  border-radius: 22.5px;
  padding-left: 30px;
  border: 0px;
}
.newsletter-form input:not([type="submit"])::-moz-placeholder {
  color: #b6b6b6;
  font-size: 0.875rem;
}
.newsletter-form input:not([type="submit"])::-webkit-input-placeholder {
  color: #b6b6b6;
  font-size: 0.875rem;
}
.newsletter-form input:not([type="submit"]):-ms-input-placeholder {
  color: #b6b6b6;
  font-size: 0.875rem;
}
.newsletter-form input:not([type="submit"])::-ms-input-placeholder {
  color: #b6b6b6;
  font-size: 0.875rem;
}
.newsletter-form input:not([type="submit"])::placeholder {
  color: #b6b6b6;
  font-size: 0.875rem;
}
.newsletter-form .send-button {
  background-color: #1bd1ea;
  position: absolute;
  width: 51px;
  height: 45px;
  color: #FFFFFF;
  font-size: 1.125rem;
  right: -1px;
  top: 0;
  border: 0px;
  border-radius: 0 22.5px 22.5px 0;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.newsletter-form .send-button:hover {
  background-color: #1868dd;
}
.newsletter-form label {
  margin-bottom: 0px;
  position: absolute;
  bottom: -30px;
  left: 0;
}

.get-subscribe {
  position: relative;
}
.get-subscribe label {
  margin-bottom: 0px;
  position: absolute;
  bottom: -30px;
  left: 0;
}
.get-subscribe .form-control {
  height: 54px;
  border: 2px solid #e7e7e7;
  width: calc(100% - 190px);
  border-radius: 27px;
  padding-left: 35px;
  margin-bottom: 15px;
}
.get-subscribe .form-control.error {
  border-color: #ff4d4d;
}
.get-subscribe .form-control:focus {
  border-color: #1868dd;
}

.subscribe-form .form-group {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
  box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
  margin-bottom: 0px;
}
.subscribe-form .form-control,
.subscribe-form .form-group {
  border-radius: 25px;
}
.subscribe-form label {
  position: absolute;
  bottom: -24px;
  left: 0px;
  margin-bottom: 0;
  line-height: 1;
}
.subscribe-form .form-control {
  border: 1px solid #f5f5f5;
  height: 50px;
  padding-left: 25px;
}
.subscribe-form .form-control.error {
  border-color: #ff4d4d;
}
.subscribe-form .sub-btn {
  background-color: transparent;
  border: 0px;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 25px;
  cursor: pointer;
  color: #1768dd;
  height: 100%;
}
.subscribe-form .sub-btn:focus {
  outline: none;
}

.subscribe-from-style2, .subscribe-form.subscribe-from-style3 {
  position: relative;
}
.subscribe-from-style2 .form-group, .subscribe-form.subscribe-from-style3 .form-group,
.subscribe-from-style2 .form-control,
.subscribe-form.subscribe-from-style3 .form-control {
  border-radius: 27px;
}
.subscribe-from-style2 .form-control, .subscribe-form.subscribe-from-style3 .form-control {
  border-color: #1868dd;
  height: 54px;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
}
.subscribe-from-style2 .form-control.error, .subscribe-form.subscribe-from-style3 .form-control.error {
  border-color: #ff4d4d;
}
.subscribe-from-style2 .form-control::-moz-placeholder, .subscribe-form.subscribe-from-style3 .form-control::-moz-placeholder {
  color: #cacaca;
}
.subscribe-from-style2 .form-control::-webkit-input-placeholder, .subscribe-form.subscribe-from-style3 .form-control::-webkit-input-placeholder {
  color: #cacaca;
}
.subscribe-from-style2 .form-control:-ms-input-placeholder, .subscribe-form.subscribe-from-style3 .form-control:-ms-input-placeholder {
  color: #cacaca;
}
.subscribe-from-style2 .form-control::-ms-input-placeholder, .subscribe-form.subscribe-from-style3 .form-control::-ms-input-placeholder {
  color: #cacaca;
}
.subscribe-from-style2 .form-control::placeholder, .subscribe-form.subscribe-from-style3 .form-control::placeholder {
  color: #cacaca;
}
.subscribe-from-style2 .sub-btn, .subscribe-form.subscribe-from-style3 .sub-btn {
  padding: 0px;
  width: 40px;
  height: 40px;
  line-height: 41px;
  text-align: center;
  background-color: #1868dd;
  color: #FFFFFF;
  font-size: 1rem;
  right: 7px;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.subscribe-from-style2 .sub-btn:hover, .subscribe-form.subscribe-from-style3 .sub-btn:hover {
  background-color: #103dbe;
}
.subscribe-from-style2 .sub-btn i, .subscribe-form.subscribe-from-style3 .sub-btn i {
  margin-left: 2px;
}

.subscribe-form.subscribe-from-style3 .form-group {
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
}
.subscribe-form.subscribe-from-style3 .form-control {
  border-color: #78d0e8;
}
.subscribe-form.subscribe-from-style3 .form-control:focus {
  border-color: #37b9dd;
}
.subscribe-form.subscribe-from-style3 .sub-btn {
  background-color: #78d0e8;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.subscribe-form .subscirbe-info {
  font-size: 0.9375rem;
  color: #000000;
}

.subscribe-style-border .form-group {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
}
.subscribe-style-border .form-group .form-control {
  border: 0px;
  border-radius: 0px;
  border-bottom: 2px solid #000;
  padding-left: 20px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.subscribe-style-border .form-group .form-control:focus {
  border-color: #1868dd;
}
.subscribe-style-border .form-group .form-control.error:focus {
  border-color: #ff3333;
}
.subscribe-style-border .form-group .form-control::-moz-placeholder {
  color: #cacaca;
  font-size: 0.8125rem;
  letter-spacing: 1.5px;
}
.subscribe-style-border .form-group .form-control::-webkit-input-placeholder {
  color: #cacaca;
  font-size: 0.8125rem;
  letter-spacing: 1.5px;
}
.subscribe-style-border .form-group .form-control:-ms-input-placeholder {
  color: #cacaca;
  font-size: 0.8125rem;
  letter-spacing: 1.5px;
}
.subscribe-style-border .form-group .form-control::-ms-input-placeholder {
  color: #cacaca;
  font-size: 0.8125rem;
  letter-spacing: 1.5px;
}
.subscribe-style-border .form-group .form-control::placeholder {
  color: #cacaca;
  font-size: 0.8125rem;
  letter-spacing: 1.5px;
}
.subscribe-style-border .form-group .sub-btn {
  padding-right: 20px;
  font-size: 1.5625rem;
  color: #000000;
}

/* subscribe form */
.subscribe-from-style4 .form-group, .subscribe-from-style4 .form-control {
  border-radius: 0px;
}
.subscribe-from-style4 .form-group {
  -webkit-box-shadow: 0px 15px 65px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 15px 65px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.subscribe-from-style4 .form-control {
  padding-left: 30px;
  border: 1px solid #7447ff;
}
.subscribe-from-style4 .form-control:focus {
  border-color: #1868dd;
}
.subscribe-from-style4 .form-control::-moz-placeholder {
  color: #e4e4e4;
  font-size: 0.9375rem;
}
.subscribe-from-style4 .form-control::-webkit-input-placeholder {
  color: #e4e4e4;
  font-size: 0.9375rem;
}
.subscribe-from-style4 .form-control:-ms-input-placeholder {
  color: #e4e4e4;
  font-size: 0.9375rem;
}
.subscribe-from-style4 .form-control::-ms-input-placeholder {
  color: #e4e4e4;
  font-size: 0.9375rem;
}
.subscribe-from-style4 .form-control::placeholder {
  color: #e4e4e4;
  font-size: 0.9375rem;
}
.subscribe-from-style4 .sub-btn {
  padding: 0 28px;
  font-size: 1.25rem;
  color: #7447ff;
}
.subscribe-from-style4 .sub-btn::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 24px;
  width: 1px;
  left: 0;
  background-color: #e3daff;
}

/*=================================================
          7. pagination
===============================================*/
/* xs pagination */
.pagination li {
  display: inline-block;
}
.pagination li:not(:last-child) {
  margin-right: 20px;
}
.pagination li a {
  width: 48px;
  height: 48px;
  line-height: 48px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border: 1px solid #e7e7e7;
  padding: 0;
  border-radius: 100%;
  text-align: center;
  font-size: 0.875rem;
  color: #999999;
  font-weight: 500;
}
.pagination li a:hover, .pagination li.active a {
  background-color: #1868dd;
  color: #FFFFFF;
  border-color: #1868dd;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.07);
}
.pagination li > span {
  letter-spacing: 7px;
  position: relative;
  top: 7px;
}
.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  border-radius: 100%;
}
.pagination .page-item:last-child .page-link {
  font-size: 0.75rem;
}
.pagination .page-item.disabled a,
.pagination li a {
  border-color: #f1f1f1;
}

/*=================================================
          8. select
===============================================*/
.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 16px;
  color: #7b7b7b;
  width: 220px;
  height: 44px;
  float: right;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  padding: 8px 15px;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  height: 44px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  text-align: left;
}
.select-styled:after {
  content: "\f107";
  position: absolute;
  top: 8px;
  right: 10px;
  font-family: "FontAwesome";
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.select-styled:hover {
  background-color: rgba(0, 0, 0, 0);
}
.select-styled:active, .select-styled.active {
  background-color: rgba(0, 0, 0, 0);
}
.select-styled:active:after, .select-styled.active:after {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  color: #1868dd;
}
.select-styled.active {
  color: #1868dd;
}

.select-options {
  display: none;
  position: absolute;
  top: calc(100% + 20px);
  left: 0%;
  min-width: 220px;
  z-index: 999;
}
.select-options::before {
  position: absolute;
  content: "";
  top: -8px;
  left: 50%;
  width: 16px;
  height: 16px;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  z-index: -1;
}
.select-options li {
  padding: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: #FFFFFF;
  text-align: left;
}
.select-options li:hover {
  background: #1352af;
}
.select-options li[rel="hide"] {
  display: none;
}

.select-options,
.select-options::before {
  background-color: #1868dd;
}

/*=================================================
          9. search
===============================================*/
.xs-serach {
  position: relative;
}
.xs-serach input:not([type="submit"]) {
  padding: 0 30px;
  background-color: #f7f7f7;
  width: 100%;
  height: 54px;
  border-radius: 5px;
  border: 0px;
  border: 1px solid transparent;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.xs-serach input:not([type="submit"])::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #d2d2d2;
  font-size: 0.9375rem;
}
.xs-serach input:not([type="submit"])::-moz-placeholder {
  /* Firefox 19+ */
  color: #d2d2d2;
  font-size: 0.9375rem;
}
.xs-serach input:not([type="submit"]):-ms-input-placeholder {
  /* IE 10+ */
  color: #d2d2d2;
  font-size: 0.9375rem;
}
.xs-serach input:not([type="submit"]):-moz-placeholder {
  /* Firefox 18- */
  color: #d2d2d2;
  font-size: 0.9375rem;
}
.xs-serach input:not([type="submit"]):focus {
  border-color: #1868dd;
}
.xs-serach .search-btn {
  width: 62px;
  border: 0px;
  font-size: 1.125rem;
  color: #1868dd;
  background-color: transparent;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.xs-serach.style2 input:not([type=submit]) {
  background-color: transparent;
  border-color: #e7e7e7;
  height: 44px;
  padding: 0 20px;
}
.xs-serach.style2 input:not([type=submit]):focus {
  border-color: #1868dd;
}
.xs-serach.style2 input:not([type=submit])::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777777;
  font-size: 0.875rem;
}
.xs-serach.style2 input:not([type=submit])::-moz-placeholder {
  /* Firefox 19+ */
  color: #777777;
  font-size: 0.875rem;
}
.xs-serach.style2 input:not([type=submit]):-ms-input-placeholder {
  /* IE 10+ */
  color: #777777;
  font-size: 0.875rem;
}
.xs-serach.style2 input:not([type=submit]):-moz-placeholder {
  /* Firefox 18- */
  color: #777777;
  font-size: 0.875rem;
}
.xs-serach.style2 .search-btn {
  color: #777777;
  font-size: 0.875rem;
}

/*=================================================
          10. product filter content
===============================================*/
.product-filter-area {
  margin-bottom: 30px;
}

.product-filter-wraper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-filter-wraper .woocommerce-ordering {
  margin-right: 50px;
  margin-bottom: 0;
}

.woocommerce-ordering {
  position: relative;
  z-index: 10;
}
.woocommerce-ordering .shop-filter-title {
  color: #777777;
  font-size: 0.875rem;
  position: relative;
  top: 9px;
  padding-right: 50px;
}

.tab-filter .nav-tabs .nav-item .nav-link {
  border: 0px;
  color: #777777;
  font-size: 1.125rem;
  padding: 10px;
}
.tab-filter .nav-tabs .nav-item .nav-link.active {
  color: #1868dd;
}

/*=================================================
          11. ribbon
===============================================*/
/* .product-ribbon {
  background-image: url(../images/products/ribbon-background.png);
  color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 44px;
  height: 44px;
  text-align: center;
} */
.product-ribbon .ribbon-title {
  line-height: 37px;
  font-size: 0.75rem;
  font-weight: 700;
}

/*=================================================
          12. offer promotion banner
===============================================*/
.offer-promotion-banner a {
  position: relative;
  overflow: hidden;
  display: block;
}
.offer-promotion-banner a:hover > img {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}
.offer-promotion-banner a > img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0, 0, 0.2, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: transform 0.6s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.6s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.6s cubic-bezier(0, 0, 0.2, 1);
}

/*=================================================
          13. custom number
===============================================*/
.custom_number {
  position: relative;
  height: 49px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 auto;
  float: right;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
}
.custom_number .sub,
.custom_number .add {
  float: right;
  cursor: pointer;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
  color: #777777;
  font-size: 0.75rem;
  padding: 5px 14px;
}
.custom_number .sub {
  position: absolute;
  bottom: 0;
  right: 0;
}
.custom_number input[type="number"] {
  height: 100%;
  width: auto;
  background-color: #FFFFFF;
  text-align: center;
  pointer-events: none;
  width: 62px;
  color: #000000;
  border: 0px;
  border-right: 1px solid #f5f5f5;
}

.custom-number-wraper {
  display: inline-block;
}
.custom-number-wraper .title {
  color: #000000;
  font-weight: 500;
  font-size: 0.9375rem;
  display: inline-block;
  padding-right: 15px;
  margin-top: 11px;
}

/*=================================================
          14. floating buttons
===============================================*/
.round-info-icon {
  display: block;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  font-size: 0.875rem;
  color: #000000;
  text-align: center;
}
.round-info-icon:hover {
  background-color: #000000;
  color: #FFFFFF;
}

.btn-floating.active {
  background-color: #000000;
  color: #FFFFFF;
}

.floating-icons-list {
  position: absolute;
  bottom: 100%;
  right: 0;
  text-align: center;
}

.floating-icons-list.open > li:nth-child(1) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.1s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.1s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.1s;
}
.floating-icons-list.hidden > li:nth-child(1) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
}

.floating-icons-list.open > li:nth-child(2) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}
.floating-icons-list.hidden > li:nth-child(2) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.4s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.4s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.4s;
}

.floating-icons-list.open > li:nth-child(3) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
}
.floating-icons-list.hidden > li:nth-child(3) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
}

.floating-icons-list.open > li:nth-child(4) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.4s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.4s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.4s;
}
.floating-icons-list.hidden > li:nth-child(4) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}

.floating-icons-list.open > li:nth-child(5) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
}
.floating-icons-list.hidden > li:nth-child(5) {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.1s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.1s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.1s;
}

.floating-icons-list > li {
  margin-right: 0;
  margin-bottom: 20px;
  opacity: 0;
  -webkit-transform: scale(0.4) translateY(40px) translateX(0px);
  -ms-transform: scale(0.4) translateY(40px) translateX(0px);
  transform: scale(0.4) translateY(40px) translateX(0px);
  visibility: hidden;
}
.floating-icons-list.open > li {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1) translateY(0px) translateX(0px);
  -ms-transform: scale(1) translateY(0px) translateX(0px);
  transform: scale(1) translateY(0px) translateX(0px);
}

/*=================================================
          15. woocommerce star rating
===============================================*/
.woocommerce.woocommerce-product-rating .star-rating {
  margin: 0;
  margin-bottom: 30px;
  float: none;
  width: auto;
  overflow: visible;
  font-family: inherit;
}
.woocommerce .star-rating {
  font-size: 0.875rem;
}
.woocommerce .star-rating::before {
  content: '\f005\f005\f005\f005\f006';
  color: #efc71f;
  font-family: "FontAwesome";
  padding-right: 15px;
  letter-spacing: 3px;
  position: static;
}
.woocommerce .star-rating span::before {
  display: none;
}
.woocommerce .star-rating .woocommerce-review-link {
  color: #777777;
}
.woocommerce .star-rating .woocommerce-review-link:hover {
  color: #1868dd;
}
.woocommerce .star-rating .woocommerce-review-link .count {
  overflow: visible;
  float: none;
  position: static;
  padding-top: 0;
}

/*=================================================
          16. help tips
===============================================*/
.help-tip {
  text-align: center;
  background-color: #1868dd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 26px;
  cursor: default;
  display: inline-block;
  z-index: 9;
  position: relative;
}
.help-tip::before {
  content: '?';
  font-weight: bold;
  color: #fff;
}
.help-tip:hover .help-tip-text {
  display: block;
  -webkit-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  -webkit-animation: tipFadeIn 0.3s ease-in-out;
  animation: tipFadeIn 0.3s ease-in-out;
}
.help-tip .help-tip-text {
  display: none;
  text-align: left;
  background-color: #1E2021;
  padding: 20px;
  min-width: 300px;
  position: absolute;
  border-radius: 3px;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  right: -4px;
  color: #FFF;
  margin-bottom: 0;
  margin-top: 8px;
  pointer-events: none;
  font-weight: 400;
}
.help-tip .help-tip-text::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #1E2021;
  right: 10px;
  top: -12px;
}
.help-tip .help-tip-text::after {
  width: 100%;
  height: 40px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

@-webkit-keyframes tipFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  100% {
    opacity: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes tipFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  100% {
    opacity: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/*=================================================
          17. woo reviews
===============================================*/
.rate-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.rate-list li b {
  font-weight: 400;
  color: #aaaaaa;
  font-size: 0.75rem;
  padding-left: 5px;
}
.rate-list li:last-child {
  margin-bottom: 0;
}
.rate-list li .rate-title {
  width: 15%;
  line-height: 1;
  font-size: 0.75rem;
  margin-top: 1px;
}
.rate-list li .rate-graph {
  height: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  display: block;
  width: 65%;
  position: relative;
  overflow: hidden;
}
.rate-list li .rate-graph .rate-graph-bar {
  background-color: #fed700;
  border-radius: 5px;
  display: block;
  height: 100%;
}
.rate-list li .star-rating {
  width: 20%;
  margin-bottom: 0;
  text-align: right;
  margin-top: -2px;
  padding-right: 0;
  padding-left: 15px;
}
.rate-list li .star-rating::before {
  color: #fed700;
}
.rate-list li .star-rating[data-value="5"]::before {
  content: '\f005\f005\f005\f005\f005';
}
.rate-list li .star-rating[data-value="4"]::before {
  content: '\f005\f005\f005\f005\f006';
}
.rate-list li .star-rating[data-value="3"]::before {
  content: '\f005\f005\f005\f006\f006';
}
.rate-list li .star-rating[data-value="2"]::before {
  content: '\f005\f005\f006\f006\f006';
}
.rate-list li .star-rating[data-value="1"]::before {
  content: '\f005\f006\f006\f006\f006';
}

.rate-score .star-rating {
  font-size: 1.875rem;
  float: left;
}
.rate-score .rating-score-des {
  font-size: 1.25rem;
  float: left;
  margin-bottom: 0;
}
.rate-score .rating-score-des em {
  color: #ffa801;
}
.rate-score .help-tip {
  float: right;
}

.woocommerce .rate-score .star-rating {
  margin-bottom: 10px;
}

.woocommerce-Reviews {
  padding-top: 50px;
}
.woocommerce-Reviews .woocommerce-Reviews-title {
  font-size: 1.5rem;
  margin-bottom: 40px;
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-weight: 500;
}
.woocommerce-Reviews .comment_container .comment-text {
  float: right;
}
.woocommerce-Reviews .star-rating {
  font-family: inherit;
}

.woocommerce #reviews #comments ol.commentlist li img.avatar {
  width: 42px;
  height: 42px;
}
.woocommerce #reviews #comments ol.commentlist li .comment-text {
  margin: 0;
  padding: 30px;
  width: calc(100% - 70px);
}
.woocommerce .comment-form-rating {
  display: inline-block;
  line-height: 1;
}
.woocommerce .comment-form-rating .star-rating {
  margin: 0;
  float: right;
  width: auto;
  overflow: visible;
  font-family: inherit;
  padding-left: 15px;
}
.woocommerce .woocommerce-Reviews .comment-form {
  padding-top: 50px;
}
.woocommerce .woocommerce-Reviews .comment-form input:not([type="submit"]),
.woocommerce .woocommerce-Reviews .comment-form textarea {
  width: 100%;
  display: block;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}
.woocommerce .woocommerce-Reviews .comment-form textarea {
  height: 100px;
  padding: 15px;
  resize: none;
}
.woocommerce #respond input#submit {
  font-size: 0.75rem;
  padding: 12px 25px;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #1868dd;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/*=================================================
          18. tabs
===============================================*/
/* bootstrap tab default style remove */
.nav-tabs {
  border-bottom: 0px;
}
.nav-tabs .nav-item .nav-link {
  padding: 0px;
  border: 0px;
}

.main-tab {
  margin-bottom: 40px;
  border-bottom: 1px solid #f7f7f7;
}
.main-tab .nav-item:not(:last-child) {
  margin-right: 55px;
}
.main-tab .nav-item .nav-link {
  padding-bottom: 23px;
  border-bottom: 1px solid transparent;
  color: #cacaca;
  font-size: 1.125rem;
  font-weight: 500;
}
.main-tab .nav-item .nav-link.active {
  border-color: currentColor;
  color: #000000;
}

/*=================================================
          19. about slider
===============================================*/
.about-slider-wraper {
  position: relative;
}
.about-slider-wraper::before {
  position: absolute;
  content: "";
  background: #b224ef;
  background: -webkit-linear-gradient(331deg, #b224ef 31%, #5055fa 84%);
  background: -o-linear-gradient(331deg, #b224ef 31%, #5055fa 84%);
  background: linear-gradient(119deg, #b224ef 31%, #5055fa 84%);
  height: 100%;
  width: calc(100% - 227px);
  left: 0;
  right: 0;
}
.about-slider-wraper .about-slider {
  margin-left: 60px;
  top: -80px;
  background-color: white;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 80px;
  width: calc(100% - 60px);
}
.about-slider-wraper .owl-dots {
  position: absolute;
  bottom: -53px;
  right: 70px;
}
.about-slider-wraper .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.3);
}
.about-slider-wraper .owl-dots .owl-dot span::before {
  border: 0px;
  background: #b224ef;
  background: -webkit-linear-gradient(331deg, #b224ef 20%, #5055fa 84%);
  background: -o-linear-gradient(331deg, #b224ef 20%, #5055fa 84%);
  background: linear-gradient(119deg, #b224ef 20%, #5055fa 84%);
  background-size: 140% auto;
}
.about-slider-wraper .owl-carousel .owl-item > .single-slider > img {
  width: 100%;
}

/*=================================================
          20. pie chart
===============================================*/
.piechats-wraper > .single-piechart {
  display: inline-block;
  float: left;
}

.single-piechart:not(:last-child) {
  margin-right: 45px;
}
.single-piechart .chart {
  position: relative;
}
.single-piechart .chart .chart-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  text-align: center;
}
.single-piechart .gradient-title {
  font-size: 1.75rem;
  font-weight: 700;
}
.single-piechart p {
  text-align: center;
  color: #000000;
}

/*=================================================
          21. scroll to bottom
===============================================*/
.scrollto-button-wraper .scrollto-button {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: #FFFFFF;
  text-align: center;
  color: #000000;
  font-size: 1.125rem;
  display: block;
  -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
}
.scrollto-button-wraper .round-btn {
  border-radius: 50%;
}

/*=================================================
          22. content over image
===============================================*/
.content-over-img-wraper .content-over-img {
  position: absolute;
  bottom: -50px;
  z-index: 99;
}
.content-over-img-wraper .content-over-img.img-1 {
  left: 0;
}
.content-over-img-wraper .content-over-img.img-2 {
  right: 0;
}

.delighter.calltoaction-area-2 {
  overflow: hidden;
}
.delighter .content-over-img-wraper .content-over-img {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
}
.delighter .content-over-img-wraper .content-over-img.img-1 {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.delighter .content-over-img-wraper .content-over-img.img-2 {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}
.delighter.started.calltoaction-area-2 {
  overflow: visible;
}
.delighter.started .content-over-img-wraper .content-over-img {
  opacity: 1;
  visibility: visible;
}
.delighter.started .content-over-img-wraper .content-over-img.img-1, .delighter.started .content-over-img-wraper .content-over-img.img-2 {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

/*=================================================
          23. vertical tabs
===============================================*/
.vertical-tabs .nav-item {
  width: 100%;
  margin-bottom: 0px;
}
.vertical-tabs .nav-item:not(:last-child) {
  margin-bottom: 15px;
}
.vertical-tabs .nav-item .nav-link {
  font-size: 1.375rem;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.vertical-tabs .nav-item .nav-link:before {
  content: "\e940";
  font-family: "iconfont";
  font-size: 0.9375rem;
  display: inline-block;
  -webkit-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  transition: opacity .3s ease;
  opacity: 0;
  padding-right: 9px;
}
.vertical-tabs .nav-item .nav-link.active, .vertical-tabs .nav-item .nav-link:hover {
  color: #f14b59;
  padding-left: 19px;
}
.vertical-tabs .nav-item .nav-link.active::before, .vertical-tabs .nav-item .nav-link:hover::before {
  opacity: 1;
}

/*=================================================
          24. material style input
===============================================*/
.input-material .form-group {
  position: relative;
  margin-bottom: 37px;
}
.input-material .bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
}
.input-material .bar::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: #21069b;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.input-material .placeholder-title {
  position: absolute;
  left: 0;
  pointer-events: none;
  top: 50%;
  color: #919191;
  display: inline-block;
  padding-left: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.input-material input:not([type="submit"]),
.input-material textarea {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  padding-left: 15px;
  margin-bottom: 0px;
}
.input-material input:not([type="submit"]):focus + .placeholder-title, .input-material input:not([type="submit"]).active + .placeholder-title,
.input-material textarea:focus + .placeholder-title,
.input-material textarea.active + .placeholder-title {
  -webkit-transform: translateY(-130%);
  -ms-transform: translateY(-130%);
  transform: translateY(-130%);
  padding-left: 0px;
  font-size: 0.875rem;
}
.input-material input:not([type="submit"]):focus ~ .bar::before, .input-material input:not([type="submit"]).active ~ .bar::before,
.input-material textarea:focus ~ .bar::before,
.input-material textarea.active ~ .bar::before {
  width: 100%;
}
.input-material input:not([type="submit"]).invaild ~ .bar::before,
.input-material textarea.invaild ~ .bar::before {
  background-color: #ff4d4d;
}
.input-material input:not([type="submit"]).invaild + .placeholder-title,
.input-material textarea.invaild + .placeholder-title {
  color: #ff4d4d;
}
.input-material textarea {
  height: 60px;
}
.input-material textarea + .placeholder-title {
  top: 0;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}
.input-material textarea:focus + .placeholder-title, .input-material textarea.active + .placeholder-title {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.input-material textarea:focus {
  background-color: transparent;
}
.input-material.material-style2 .form-group {
  margin-bottom: 50px;
}
.input-material.material-style2 input:not([type="submit"]) {
  color: #FFFFFF;
  padding-left: 30px;
}
.input-material.material-style2 .placeholder-title {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9375rem;
  padding-left: 30px;
}
.input-material.material-style2 .bar {
  background-color: rgba(255, 255, 255, 0.07);
  height: 2px;
}
.input-material.material-style2 .bar::before {
  background-color: #FFFFFF;
}
.input-material.material-style2 textarea {
  height: 145px;
  color: #FFFFFF;
}

.portfolio-contact-area.dark-bg {
  background-color: #333333;
}

/*=================================================
          25. humburger
===============================================*/
.humburger {
  display: block;
  height: 23px;
  cursor: pointer;
}
.humburger:hover .humburger-icons > span:first-child::before, .humburger:hover .humburger-icons > span:last-child::before {
  width: 100%;
  left: 0;
}
.humburger .humburger-icons {
  width: 30px;
  z-index: 99;
  position: relative;
}
.humburger .humburger-icons > span {
  display: block;
  height: 3px;
  background-color: #000a7a;
  position: relative;
}
.humburger .humburger-icons > span:not(:last-child) {
  margin-bottom: 7px;
}
.humburger .humburger-icons > span:first-child {
  width: 20px;
}
.humburger .humburger-icons > span:first-child::before {
  position: absolute;
  content: "";
  left: calc(100% + 5px);
  top: 0;
  height: 100%;
  width: 5px;
  background-color: #000a7a;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.humburger .humburger-icons > span:last-child {
  width: 10px;
}
.humburger .humburger-icons > span:last-child::before {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
  content: "";
  left: calc(100% + 5px);
  top: 0;
  height: 100%;
  width: 15px;
  background-color: #000a7a;
}
.humburger.style2 {
  height: 60px;
  border-radius: 30px;
  background-color: #ffbc5c;
  -webkit-box-shadow: 0px 19px 30px 0px rgba(255, 188, 92, 0.48);
  box-shadow: 0px 19px 30px 0px rgba(255, 188, 92, 0.48);
  width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.humburger.style2 > .humburger-icons {
  width: 23px;
  margin: 0 auto;
}
.humburger.style2 > .humburger-icons > span {
  background-color: #FFFFFF;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
}
.humburger.style2 > .humburger-icons > span::before {
  background-color: #FFFFFF;
}
.humburger.style2 > .humburger-icons > span:first-child {
  left: -4px;
  width: 15.34px;
}
.humburger.style2 > .humburger-icons > span:first-child::before {
  left: calc(100% + 3px);
}
.humburger.style2 > .humburger-icons > span:last-child {
  left: -7px;
  width: 7.65px;
}
.humburger.style2 > .humburger-icons > span:last-child::before {
  left: calc(100% + 5px);
  width: 10px;
}
.humburger.style2:hover > .humburger-icons > span::before {
  left: 0;
}
.humburger.style3 .humburger-icons > span {
  background-color: #FFFFFF;
}
.humburger.style3 .humburger-icons > span::before {
  background-color: #FFFFFF;
}

/*=================================================
          26. video testimonial
===============================================*/
.testimonial-video {
  position: relative;
  z-index: 1;
}
.testimonial-video img {
  border-radius: 15px;
}
.testimonial-video::before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -58px;
  width: calc(100% - 190px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  color: rgba(0, 0, 0, 0.3);
  border: 67.5px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 135px solid;
  -webkit-filter: blur(50.5px);
  filter: blur(50.5px);
  z-index: -1;
}
.testimonial-video .xs-video-popup {
  -webkit-box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  color: #255cff;
  font-size: 2.25rem;
  width: 120px;
  height: 120px;
  line-height: 130px;
}
.testimonial-video .xs-video-popup > i {
  margin-right: 0px;
}
.testimonial-video .xs-video-popup:hover {
  background-color: #255cff;
  color: #FFFFFF;
}
.testimonial-video .stack-effect::after, .testimonial-video .stack-effect::before {
  border-radius: 15px;
}

.stack-effect {
  position: relative;
}
.stack-effect::after, .stack-effect::before {
  position: absolute;
  content: "";
  bottom: -60px;
  left: 80px;
  right: 80px;
  top: 60px;
  background-color: #255cff;
  z-index: -1;
  opacity: .1;
}
.stack-effect::before {
  bottom: -30px;
  left: 40px;
  right: 40px;
  top: 30px;
}

/*=================================================
          27. shuffle title
===============================================*/
.shuffle-title {
  position: relative;
}

.shuffle-letter-title-wraper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

.shuufle-letter-title {
  font-size: 11.5625rem;
  margin-bottom: 0;
  color: #f8f8f8;
}

.gray-bg .shuufle-letter-title {
  color: #f0f0f0;
}

/*=================================================
          28. offcanvas menu
===============================================*/
.nav-trigger {
  display: block;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background-color: #000000;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
  cursor: pointer;
  z-index: 5;
}
.nav-trigger .nav-icon {
  position: absolute;
  top: 50%;
  right: 0;
  height: 2px;
  width: 22px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-color: #FFFFFF;
}
.nav-trigger .nav-icon::before, .nav-trigger .nav-icon::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  background-color: inherit;
}
.nav-trigger .nav-icon::before {
  -webkit-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  transform: translateY(-6px);
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}
.nav-trigger .nav-icon::after {
  -webkit-transform: translateY(6px);
  -ms-transform: translateY(6px);
  transform: translateY(6px);
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  transform-origin: right bottom;
}
.nav-trigger svg {
  position: absolute;
  top: 0;
  left: 0;
  fill: transparent;
  stroke: #333333;
  stroke-width: 1px;
}
.nav-trigger svg circle {
  -webkit-transition: stroke-dashoffset 1s 0s;
  -o-transition: stroke-dashoffset 1s 0s;
  transition: stroke-dashoffset 1s 0s;
  stroke-dasharray: 157 157;
  stroke-dashoffset: 157;
}
.nav-trigger:hover .nav-icon::before {
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px);
}
.nav-trigger:hover .nav-icon::after {
  -webkit-transform: translateY(8px);
  -ms-transform: translateY(8px);
  transform: translateY(8px);
}
.nav-trigger.style2 {
  width: auto;
  height: auto;
  display: inline-block;
  border-radius: 0;
  text-indent: unset;
  overflow: visible;
  background-color: transparent;
}
.nav-trigger.style2 .nav-title {
  font-size: 0.9375rem;
  font-weight: 700;
  color: #000000;
  display: block;
  -webkit-transform: translateX(-45px);
  -ms-transform: translateX(-45px);
  transform: translateX(-45px);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 1;
  visibility: visible;
}
.nav-trigger.style2 .nav-icon {
  background-color: #000000;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.submenu {
  display: none;
}

.offcanvas-menu li ul {
  display: none;
}

.off-canvas-menu-area .menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 150vh;
  width: 100vw;
  background: white;
  background: -webkit-gradient(linear, left top, right top, from(white), color-stop(50%, white), color-stop(50%, #f7f9fb), to(#f7f9fb));
  background: -webkit-linear-gradient(left, white 0%, white 50%, #f7f9fb 50%, #f7f9fb 100%);
  background: -o-linear-gradient(left, white 0%, white 50%, #f7f9fb 50%, #f7f9fb 100%);
  background: linear-gradient(90deg, white 0%, white 50%, #f7f9fb 50%, #f7f9fb 100%);
  z-index: -1;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform .8s ease .0s;
  transition: -webkit-transform .8s ease .0s;
  -o-transition: transform .8s ease .0s;
  transition: transform .8s ease .0s;
  transition: transform .8s ease .0s, -webkit-transform .8s ease .0s;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}
.off-canvas-menu-area.nav-is-open .menu-overlay {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition: -webkit-transform .8s ease .2s;
  transition: -webkit-transform .8s ease .2s;
  -o-transition: transform .8s ease .2s;
  transition: transform .8s ease .2s;
  transition: transform .8s ease .2s, -webkit-transform .8s ease .2s;
}
.off-canvas-menu-area.nav-is-open .offcanvas-nav-logo {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  visibility: hidden;
  -webkit-transition: all 1s ease 1s;
  -o-transition: all 1s ease 1s;
  transition: all 1s ease 1s;
}
.off-canvas-menu-area .offcanvas-nav-logo {
  position: relative;
  z-index: 10000;
  padding: 30px;
  -webkit-transition: all .5s ease .5s;
  -o-transition: all .5s ease .5s;
  transition: all .5s ease .5s;
}

.offcanvas-menu li {
  position: relative;
  overflow: hidden;
  line-height: 1;
}
.offcanvas-menu li a {
  display: inline-block;
  color: #333333;
  font-weight: 700;
  font-size: 2.25rem;
  -webkit-transform: translateY(47px);
  -ms-transform: translateY(47px);
  transform: translateY(47px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease .4s .0s;
  -o-transition: all ease .4s .0s;
  transition: all ease .4s .0s;
  position: relative;
}
.offcanvas-menu li a.tigger::after {
  position: absolute;
  content: "\e93f";
  font-family: "iconfont";
  font-size: 0.9375rem;
  right: -22px;
  top: 10px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.offcanvas-menu li a.tigger.active {
  color: #fd3358;
}
.offcanvas-menu li a.tigger.active::before {
  background-color: #fd3358;
}
.offcanvas-menu li a.tigger.active::after {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.offcanvas-menu > li:not(:last-child) {
  margin-bottom: 25px;
}
.offcanvas-menu > li > a {
  padding-left: 18px;
}
.offcanvas-menu > li > a::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 3px;
  width: 10px;
  background-color: #d7d7d7;
}
.offcanvas-menu .inner-submenu {
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 7px;
}
.offcanvas-menu .inner-submenu li a.tigger::after {
  top: 3px;
  font-size: 0.8375rem;
}
.offcanvas-menu .inner-submenu > li:not(:last-child) {
  margin-bottom: 12px;
}
.offcanvas-menu .inner-submenu > li > a {
  font-size: 1.125rem;
  font-weight: 400;
}
.offcanvas-menu.single-page-menu > li > a {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.offcanvas-menu.single-page-menu > li > a::before {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.offcanvas-menu.single-page-menu > li > a:hover {
  color: #fd3358;
}
.offcanvas-menu.single-page-menu > li > a:hover::before {
  background-color: #fd3358;
}

.offcanvas-menu-wraper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  overflow: auto;
}
.offcanvas-menu-wraper .menu-container {
  width: 50%;
  display: table;
  height: 100%;
  float: left;
  padding-left: 150px;
}
.offcanvas-menu-wraper .menu-container .offcanvas-menu {
  display: table-cell;
  vertical-align: middle;
}
.offcanvas-menu-wraper.active {
  visibility: visible;
}
.offcanvas-menu-wraper.active .offcanvas-menu li a {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: opacity ease 1.3s 0.5s, -webkit-transform ease 1.3s 0.5s;
  transition: opacity ease 1.3s 0.5s, -webkit-transform ease 1.3s 0.5s;
  -o-transition: transform ease 1.3s 0.5s, opacity ease 1.3s 0.5s;
  transition: transform ease 1.3s 0.5s, opacity ease 1.3s 0.5s;
  transition: transform ease 1.3s 0.5s, opacity ease 1.3s 0.5s, -webkit-transform ease 1.3s 0.5s;
  opacity: 1;
  visibility: visible;
}
.offcanvas-menu-wraper.active .offcanvas-content-wraper .offcanvas-title {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  -webkit-transition: all ease 2s 0.5s;
  -o-transition: all ease 2s 0.5s;
  transition: all ease 2s 0.5s;
}
.offcanvas-menu-wraper.active .offcanvas-content-wraper .offcanvas-list-widget li p {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all ease 2.5s 0.5s;
  -o-transition: all ease 2.5s 0.5s;
  transition: all ease 2.5s 0.5s;
  opacity: 1;
  visibility: visible;
}
.offcanvas-menu-wraper.active .offcanvas-content-wraper .social-list > li {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all ease 2.5s 0.5s;
  -o-transition: all ease 2.5s 0.5s;
  transition: all ease 2.5s 0.5s;
  opacity: 1;
  visibility: visible;
}

.offcanvas-content-wraper {
  width: 50%;
  display: table;
  height: 100%;
  float: right;
  padding-left: 100px;
}
.offcanvas-content-wraper .offcanvas-content {
  display: table-cell;
  vertical-align: middle;
}
.offcanvas-content-wraper .offcanvas-title {
  -webkit-transform: translateY(47px);
  -ms-transform: translateY(47px);
  transform: translateY(47px);
  opacity: 0;
  -webkit-transition: all ease .4s .0s;
  -o-transition: all ease .4s .0s;
  transition: all ease .4s .0s;
}
.offcanvas-content-wraper .offcanvas-list-widget {
  margin-bottom: 80px;
}
.offcanvas-content-wraper .offcanvas-list-widget li {
  overflow: hidden;
  position: relative;
}
.offcanvas-content-wraper .offcanvas-list-widget li p {
  -webkit-transform: translateY(47px);
  -ms-transform: translateY(47px);
  transform: translateY(47px);
  -webkit-transition: all ease .4s .0s;
  -o-transition: all ease .4s .0s;
  transition: all ease .4s .0s;
  opacity: 0;
  visibility: hidden;
}
.offcanvas-content-wraper .social-list > li {
  -webkit-transform: translateY(47px);
  -ms-transform: translateY(47px);
  transform: translateY(47px);
  -webkit-transition: all ease .4s .0s;
  -o-transition: all ease .4s .0s;
  transition: all ease .4s .0s;
  opacity: 0;
  visibility: hidden;
}
.offcanvas-content-wraper .social-list > li > a {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.offcanvas-title {
  color: #fd3358;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}

.offcanvas-list-widget li {
  line-height: 1.6;
}
.offcanvas-list-widget li:not(:last-child) {
  margin-bottom: 20px;
}
.offcanvas-list-widget li p {
  font-size: 1.125rem;
  color: #fd3358;
  font-weight: 500;
}
.offcanvas-list-widget li p i {
  padding-right: 15px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 5px;
}
.offcanvas-list-widget li p a {
  color: #555556;
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.offcanvas-menu-logo {
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
}
.offcanvas-menu-logo .offcanvas-logo {
  display: inline-block;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 0;
  visibility: hidden;
}

.off-canvas-menu-area.nav-is-open .offcanvas-menu-logo .offcanvas-logo {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 1s ease 1s;
  -o-transition: all 1s ease 1s;
  transition: all 1s ease 1s;
}

.offcanvas-nav-brand {
  display: inline-block;
}

.off-canvas-menu-area {
  width: 100%;
  height: 75px;
}
.off-canvas-menu-area .offcanvas-nav-logo {
  display: inline-block;
}
.off-canvas-menu-area .offcanvas-nav-brand {
  padding: 12px 0;
}
.off-canvas-menu-area .fullscreen_menu_tigger {
  margin: 24px 0;
  position: relative;
  text-align: right;
  z-index: 99;
}
.off-canvas-menu-area .fullscreen_menu_tigger.style2 {
  margin: 7px 0;
}
.off-canvas-menu-area .offcanvas-nav-brand {
  position: relative;
  z-index: 99;
}
.off-canvas-menu-area.munu-style-border {
  border-bottom: 1px solid #f5f5f5;
}
.off-canvas-menu-area .humburger {
  float: right;
  z-index: 99;
  position: relative;
}

.fullscreen_menu_tigger.open.style3 .humburger-icons > span {
  background-color: #522cea;
}
.fullscreen_menu_tigger.open.style3 .humburger-icons > span::before {
  background-color: inherit;
}
.fullscreen_menu_tigger.open .nav-trigger.style2 {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.fullscreen_menu_tigger.open .nav-trigger.style2 .nav-icon {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fullscreen_menu_tigger.open .nav-trigger.style2 .nav-icon::before, .fullscreen_menu_tigger.open .nav-trigger.style2 .nav-icon::after {
  width: 50%;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}
.fullscreen_menu_tigger.open .nav-trigger.style2 .nav-icon::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.fullscreen_menu_tigger.open .nav-trigger.style2 .nav-icon::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.fullscreen_menu_tigger.open .nav-trigger.style2 .nav-title {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
}

/*=================================================
          29. progress bars
===============================================*/
.single-skill-bar:not(:last-child) {
  margin-bottom: 25px;
}
.single-skill-bar.color-1 .skill-bar .skill-track {
  background-color: #36a2eb;
}
.single-skill-bar.color-2 .skill-bar .skill-track {
  background-color: #ffce56;
}
.single-skill-bar.color-3 .skill-bar .skill-track {
  background-color: #4bc0c0;
}
.single-skill-bar.color-4 .skill-bar .skill-track {
  background-color: #ff6384;
}

.skill-bar {
  height: 3px;
  background-color: #e7e7e7;
}
.skill-bar .skill-track {
  width: 0%;
  background-color: #333333;
  display: block;
  height: 100%;
  overflow: visible !important;
  position: relative;
}
.skill-bar .skill-track .number-percentage-wraper {
  position: absolute;
  right: 0;
  top: -33px;
  z-index: 1;
}

.skill-bar-content {
  margin-bottom: 7px;
}
.skill-bar-content .skill-title,
.skill-bar-content .number-percentage-wraper {
  font-weight: 700;
  color: #737373;
}
.skill-bar-content .number-percentage-wraper {
  float: right;
}

.number-percentage-wraper {
  color: #000000;
}

/*=================================================
          30. progress bars
===============================================*/
.drop-caps::first-letter {
  font-size: 3rem;
  line-height: 30px;
  color: #000000;
  float: left;
  padding-right: 8px;
}

/*=================================================
          31. long arrow
===============================================*/
.long-arrow {
  display: inline-block;
  width: 95px;
  height: 3px;
  background-color: #000000;
  position: relative;
  top: -5px;
  left: 25px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.long-arrow::before {
  position: absolute;
  content: "";
  color: #000000;
  border-top: solid currentColor;
  border-right: solid currentColor;
  border-bottom: solid transparent;
  border-left: solid transparent;
  border-width: 6px;
  right: -6px;
  top: -4px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.long-arrow.arrow-anim {
  background-color: transparent;
  width: 42px;
}
.long-arrow.arrow-anim::after {
  position: absolute;
  content: "";
  top: .5px;
  left: 0;
  background-color: #000000;
  width: 100%;
  height: 3px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
}

.long-arrow-btn {
  font-weight: 700;
  letter-spacing: .1rem;
}
.long-arrow-btn:hover .long-arrow {
  background-color: #1868dd;
  -webkit-animation: arrowanim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
  animation: arrowanim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
}
.long-arrow-btn:hover .long-arrow:before {
  color: #1868dd;
}
.long-arrow-btn:hover .arrow-anim {
  background-color: transparent;
}
.long-arrow-btn:hover .arrow-anim::after {
  background-color: #1868dd;
}
.long-arrow-btn.version-white {
  color: #FFFFFF;
}
.long-arrow-btn.version-white .long-arrow {
  background-color: #FFFFFF;
}
.long-arrow-btn.version-white .long-arrow::before {
  color: #FFFFFF;
}
.long-arrow-btn.version-white:hover {
  color: #1868dd;
}
.long-arrow-btn.version-white:hover .long-arrow {
  background-color: #1868dd;
}
.long-arrow-btn.version-white:hover .long-arrow:before {
  color: #1868dd;
}

button.long-arrow-btn {
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

@-webkit-keyframes arrowanim {
  0% {
    left: 25px;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 25px;
  }
}

@keyframes arrowanim {
  0% {
    left: 25px;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 25px;
  }
}
@-webkit-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
}
@keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
}
/*=================================================
          32. back to top
===============================================*/
.backtotop-wraper {
  position: absolute;
  bottom: 110px;
  right: 40px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  z-index: 1001;
}

.backtotop {
  color: rgba(255, 255, 255, 0.7);
}
.backtotop .long-arrow::before {
  color: rgba(255, 255, 255, 0.7);
}
.backtotop .long-arrow::after {
  background-color: rgba(255, 255, 255, 0.7);
}

/*=================================================
          29. inheritance element
===============================================*/
h1,
h2,
h3,
h4 {
  font-family: "Work Sans", sans-serif;
  letter-spacing: -.25px;
}

h1,
.banner-title {
  font-size: 3.75rem;
  font-weight: 600;
  margin-bottom: 45px;
}

h2,
.section-title {
  font-size: 2.25rem;
  font-weight: 700;
  color: #000000;
}
h2 span,
h2 em,
.section-title span,
.section-title em {
  font-family: "Playfair Display", serif;
}

h4,
.xs-title {
  font-size: 1.375rem;
  font-weight: 600;
}
h4.small,
.xs-title.small {
  font-size: 1.125rem;
}

h4,
.xs-title,
a {
  color: #000000;
}

h3,
.content-title {
  color: #000000;
  font-size: 1.75rem;
  font-weight: 700;
}
h3 em,
.content-title em {
  font-family: "Playfair Display", serif;
}

.entry-title a {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.entry-title a:hover {
  color: #1bd1ea;
}

.watermark-title {
  font-family: "Work Sans", sans-serif;
  display: block;
  font-size: 12.5rem;
  color: #f8f8f8;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  line-height: 1;
  z-index: -1;
}

/* section padding */
.xs-section-padding {
  padding: 100px 0;
}

.xs-section-padding-bottom {
  padding-bottom: 100px;
}

.xs-section-padding-top {
  padding-top: 100px;
}

.section-padding-small {
  padding: 60px 0;
}

.section-padding-medium {
  padding: 80px 0;
}

.xs-mb-0 {
  margin-bottom: 0px;
}

.xs-pb-0 {
  padding-bottom: 0px;
}

.xs-mb-1 {
  margin-bottom: 10px;
}

.xs-pb-1 {
  padding-bottom: 10px;
}

.xs-mb-2 {
  margin-bottom: 20px;
}

.xs-pb-2 {
  padding-bottom: 20px;
}

.xs-mb-3 {
  margin-bottom: 30px;
}

.xs-pb-3 {
  padding-bottom: 30px;
}

.xs-mb-4 {
  margin-bottom: 40px;
}

.xs-pb-4 {
  padding-bottom: 40px;
}

.xs-mb-5 {
  margin-bottom: 50px;
}

.xs-pb-5 {
  padding-bottom: 50px;
}

.xs-mb-6 {
  margin-bottom: 60px;
}

.xs-pb-6 {
  padding-bottom: 60px;
}

.xs-mb-7 {
  margin-bottom: 70px;
}

.xs-pb-7 {
  padding-bottom: 70px;
}

.xs-mb-8 {
  margin-bottom: 80px;
}

.xs-pb-8 {
  padding-bottom: 80px;
}

.xs-mb-9 {
  margin-bottom: 90px;
}

.xs-pb-9 {
  padding-bottom: 90px;
}

.xs-mb-10 {
  margin-bottom: 100px;
}

.xs-pb-10 {
  padding-bottom: 100px;
}

/* margin 0 */
.xs-margin-0 {
  margin: 0;
}

/* xs padding */
.xs-padding-0 {
  padding: 0;
}

/* overlay list */
.xs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .5;
  z-index: 0;
}

/* color */
.black-color {
  color: #000000;
}

/* background color */
.black-bg {
  background-color: #000000;
}

/* color */
.white-color {
  color: #FFFFFF;
}

/* background color */
.white-bg {
  background-color: #FFFFFF;
}

/* color */
.primary-color {
  color: #1868dd;
}

/* background color */
.primary-bg {
  background-color: #1868dd;
}

/* color */
.secondary-color {
  color: #103dbe;
}

/* background color */
.secondary-bg {
  background-color: #103dbe;
}

/* color */
.gray-color {
  color: #fafafa;
}

/* background color */
.gray-bg {
  background-color: #fafafa;
}

/* remove from control focus box shadow */
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* owl image preset */
.owl-carousel .owl-item img {
  width: auto;
}

/* tab pane animation */
.tab-pane.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

/* remove iframe box shadow and background */
.mfp-iframe-scaler iframe {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

a {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* simple button */
.simple-btn {
  font-weight: 700;
  display: inline-block;
}
.simple-btn i {
  padding-left: 8px;
  position: relative;
  top: 2px;
  -webkit-transition: padding-left .4s ease;
  -o-transition: padding-left .4s ease;
  transition: padding-left .4s ease;
}
.simple-btn:hover i {
  padding-left: 4px;
}
.simple-btn.style2 {
  color: #103dbe;
}

/* watermark icon */
.watermark-icon {
  position: absolute;
  top: 0;
  left: 0;
}

p:last-child {
  margin-bottom: 0px;
}

/* input type number spinner turn of chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input type number spinner turn of firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* gradient icon */
.gradient-icon {
  font-size: 2.25rem;
  background: -webkit-linear-gradient(331deg, #b224ef 19%, #5055fa 84%);
  background: -o-linear-gradient(331deg, #b224ef 19%, #5055fa 84%);
  background: linear-gradient(119deg, #b224ef 19%, #5055fa 84%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: #b224ef;
}

/* gradient title */
.gradient-title {
  background: -webkit-linear-gradient(331deg, #b224ef 19%, #5055fa 84%);
  background: -o-linear-gradient(331deg, #b224ef 19%, #5055fa 84%);
  background: linear-gradient(119deg, #b224ef 19%, #5055fa 84%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: #b224ef;
}

/* gradient background */
.gradient-bg {
  background: #b224ef;
  background: -webkit-linear-gradient(333deg, #b224ef 40%, #5055fa 100%);
  background: -o-linear-gradient(333deg, #b224ef 40%, #5055fa 100%);
  background: linear-gradient(117deg, #b224ef 40%, #5055fa 100%);
}

/* gradient background */
.gradient-bg-area {
  background: #0b53ff;
  background: -webkit-gradient(linear, left top, right top, from(#0b53ff), to(#4ae5e9));
  background: -webkit-linear-gradient(left, #0b53ff 0%, #4ae5e9 100%);
  background: -o-linear-gradient(left, #0b53ff 0%, #4ae5e9 100%);
  background: linear-gradient(90deg, #0b53ff 0%, #4ae5e9 100%);
  position: relative;
  z-index: 1;
}
.gradient-bg-area .background-wave-shape {
  position: absolute;
  top: 0;
  right: 0;
  opacity: .03;
  z-index: -1;
}
.gradient-bg-area .pillow-image {
  position: absolute;
  top: -156px;
  right: 87px;
}

.main-content-wraper {
  background-color: #f0f3fc;
}

/* underline title */
.title-underline {
  position: relative;
  z-index: 1;
  display: inline-block;
}
.title-underline::before {
  position: absolute;
  content: "";
  bottom: calc(0% + 9px);
  left: 0;
  display: inline-block;
  height: 20px;
  width: 100%;
  background-color: #ffebdf;
  z-index: -1;
}

/* multipile dots */
.multipile-dots {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #d9d9d9;
  -webkit-box-shadow: 15px 0px 0px 0 #d9d9d9, 30px 0 0 0 #d9d9d9;
  box-shadow: 15px 0px 0px 0 #d9d9d9, 30px 0 0 0 #d9d9d9;
}

/* border animation */
.border-animation {
  position: relative;
  overflow: hidden;
  color: #000000;
}
.border-animation > span {
  display: inline-block;
  border-radius: 2.5px;
  position: relative;
  border-top: 5px solid;
  -webkit-transition: width 1.5s linear;
  -o-transition: width 1.5s linear;
  transition: width 1.5s linear;
}
.border-animation > span:not(:last-child) {
  margin-right: 20px;
}
.border-animation > .first {
  width: 50px;
}
.border-animation > .second {
  width: 20px;
}
.border-animation.style2 {
  color: #cccccc;
}

@-webkit-keyframes move-1 {
  0% {
    width: 80px;
  }
  100% {
    width: 0px;
    margin-right: 0px;
  }
}

@keyframes move-1 {
  0% {
    width: 80px;
  }
  100% {
    width: 0px;
    margin-right: 0px;
  }
}
@-webkit-keyframes move-2 {
  0% {
    width: 0px;
  }
  100% {
    width: 90px;
  }
}
@keyframes move-2 {
  0% {
    width: 0px;
  }
  100% {
    width: 90px;
  }
}
.content-separete-border {
  display: block;
  height: 3px;
  width: 40px;
  background-color: #522cea;
}

/* vertical pagination */
.vertical-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.vertical-pagination .swiper-pagination-bullet {
  height: 10px;
  width: 5px;
  border-radius: 2.5px;
  background-color: #FFFFFF;
  opacity: .2;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.vertical-pagination .swiper-pagination-bullet:not(:last-child) {
  margin-bottom: 10px;
}
.vertical-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  height: 30px;
  opacity: 1;
}

/* multiple border */
.border-multiple > span {
  display: inline-block;
}
.border-multiple > span:not(:last-child) {
  margin-right: 8px;
}
.border-multiple > span.first {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ff5382;
}
.border-multiple > span.second, .border-multiple > span.third {
  width: 25px;
  height: 7px;
  background-color: #ff5382;
  border-radius: 3.5px;
}

.text-center .border-multiple {
  margin: 0 auto;
}

.glow-btn {
  position: relative;
}
.glow-btn::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: -1;
  -webkit-animation: pulse 2s infinite cubic-bezier(0.4, 0, 1, 1) both;
  animation: pulse 2s infinite cubic-bezier(0.4, 0, 1, 1) both;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
  }
  70% {
    -webkit-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
  }
  70% {
    -webkit-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.tp-parallax-wrap.position-class {
  left: 0 !important;
}

.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-container.vertical-slider {
  height: 450px;
}


