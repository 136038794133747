/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 25, 2018 */

@font-face {
    font-family: 'signatra_demoregular';
    src: url('./signatra-webfont.woff2') format('woff2'),
         url('./signatra-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'signatra_demoregular';
    src: url('./signatra-webfont.woff2') format('woff2'),
         url('./signatra-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.btn {
    font-weight: 700;
    font-size: .875rem;
    letter-spacing: .5px;
    border-radius: 1.71875rem;
    padding: 0px 50px;
    height: 54px;
    line-height: 54px;
    transition: all .4s ease;
    white-space: normal;
    margin: 0 15px 15px 15px;
}